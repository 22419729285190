import { useUpdateConnectionLine } from '@api/mutations'
import { useConnectionAccounts } from '@api/queries'
import { ConnectionLine } from '@models'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import ConnectionLineFormModal, {
  ConnectionLineFormData
} from './ConnectionLineFormModal'

interface Props {
  open: boolean
  line?: ConnectionLine
  onClose(): void
}

const EditConnectionLineModal: FC<Props> = ({ open, line, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchAccounts = useConnectionAccounts(connectionId, open)
  const updateLineMut = useUpdateConnectionLine(connectionId)

  const defaultValues = {
    accountId: line?.accountId,
    accountName: line?.accountName,
    type: line?.type,
    memo: line?.memo,
    selector: {
      type: line?.selector.type,
      params: line?.selector.params ?? []
    }
  }

  const editConnectionLineForm = useForm<ConnectionLineFormData>({ defaultValues })

  useEffect(() => {
    if (open && line) editConnectionLineForm.reset({ ...defaultValues })
  }, [open, line])

  const onSubmit: SubmitHandler<ConnectionLineFormData> = data => {
    const account = fetchAccounts.data?.find(acc => acc.accountId === data.accountId)

    updateLineMut.mutate(
      {
        lineId: line?._id ?? '',
        data: { ...data, accountName: account?.fullName }
      },
      { onSuccess: onClose }
    )
  }

  return (
    <ConnectionLineFormModal
      open={open}
      form={editConnectionLineForm}
      title="Edit Line"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default EditConnectionLineModal
