import { useCreateConnection } from '@api/mutations'
import { useCompanies, useConnections, useResellers } from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, Input, Select } from '@common/inputs/form'
import { ConnectionEntityType, ConnectionType, QBConnection } from '@models'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

type ConnectionForm = Pick<
  QBConnection,
  'companyId' | 'companyName' | 'connectionType' | 'contactEmail'
>

interface Props {
  open: boolean
  entityType: ConnectionEntityType
  onClose(): void
}

const CreateConnectionModal: FC<Props> = ({ open, entityType, onClose }) => {
  const createConnectionMut = useCreateConnection()

  const fetchConnections = useConnections(entityType)

  /** filters out companies or resellers that are already connected */
  const filterOptions = (data: any[]) => {
    const usedOptions = fetchConnections.data?.map(c => c.companyId) ?? []

    return data.filter(d => !usedOptions.includes(d.id))
  }

  const fetchCompanies = useCompanies(
    open &&
      fetchConnections.isSuccess &&
      entityType === ConnectionEntityType.Company,
    filterOptions
  )
  const fetchResellers = useResellers(
    open &&
      fetchConnections.isSuccess &&
      entityType === ConnectionEntityType.Reseller,
    filterOptions
  )

  const createConnectionForm = useForm<ConnectionForm>({
    defaultValues: {
      companyId: '',
      companyName: '',
      connectionType: undefined,
      contactEmail: ''
    }
  })

  const onSubmit: SubmitHandler<ConnectionForm> = data => {
    let company

    if (entityType === ConnectionEntityType.Company)
      company = fetchCompanies.data?.find(comp => comp.id === data.companyId)
    else if (entityType === ConnectionEntityType.Reseller)
      company = fetchResellers.data?.find(res => res.id === data.companyId)

    createConnectionMut.mutate(
      { ...data, entityType, companyName: company?.name ?? '' },
      { onSuccess: onClose }
    )
  }

  return (
    <FormDialog<ConnectionForm>
      form={createConnectionForm}
      open={open}
      title="Create Connection"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      {entityType === ConnectionEntityType.Company && (
        <Autocomplete
          label="Company"
          name="companyId"
          optionLabelKey="name"
          query={fetchCompanies}
        />
      )}

      {entityType === ConnectionEntityType.Reseller && (
        <Autocomplete
          label="Reseller"
          name="companyId"
          optionLabelKey="name"
          query={fetchResellers}
        />
      )}

      <Select
        label="Type"
        name="connectionType"
        options={[
          { label: 'Online', value: ConnectionType.Online },
          { label: 'Desktop', value: ConnectionType.Desktop }
        ]}
      />
      <Input label="Contact Email" name="contactEmail" />
    </FormDialog>
  )
}
export default CreateConnectionModal
