import { AccessCompany, ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useAccessCompany = (
  companyId: string | undefined
): UseQueryResult<AccessCompany, ApiError> =>
  useQuery(
    ['access', 'companies', companyId],
    () => axios.get(`/access/companies/${companyId}`).then(res => res.data),
    { enabled: !!companyId }
  )

export default useAccessCompany
