import { ApiError, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
type Payload = Pick<
  QBConnection,
  'companyId' | 'companyName' | 'connectionType' | 'contactEmail' | 'entityType'
>

const useCreateConnection = (): UseMutationResult<
  QBConnection,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<QBConnection>('/quickbooks/connections', payload)
        .then(res => res.data),
    {
      mutationKey: 'create quickbooks connection',
      onSuccess: res => {
        queryClient.setQueryData<QBConnection[] | undefined>(
          ['quickbooks', 'connections'],
          data => data && [...data, res]
        )
        queryClient.setQueryData<QBConnection>(
          ['quickbooks', 'connections', res.id],
          res
        )
        enqueueSnackbar(`Connection created`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create connection', { variant: 'error' })
      }
    }
  )
}
export default useCreateConnection
