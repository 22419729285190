import { Container } from '@mui/material'
import { FC } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import PayrollsInvoiced from './paths/payrolls-invoiced/PayrollsInvoiced'
import DashboardsPage from './paths/root/DashboardsPage'

const Dashboards: FC = () => {
  const { path } = useRouteMatch()

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path={`/dashboards/payrolls-invoiced`}>
          <PayrollsInvoiced />
        </Route>

        <Route path={path}>
          <DashboardsPage />
        </Route>
      </Switch>
    </Container>
  )
}

export default Dashboards
