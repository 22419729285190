import { DateRange } from '@common/inputs/basic'
import { DateRangeType } from '@models'
import { BarChart, ShowChart } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  dates: DateRangeType
  graphType: 'bar' | 'line'
  setDates(dates: DateRangeType): void
  setGraphType(type: 'bar' | 'line'): void
}

const PayrollsInvoicedHeader: FC<Props> = ({
  dates,
  graphType,
  setDates,
  setGraphType
}) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
      <Typography variant="h1" flexGrow={1}>
        Payrolls Invoiced
      </Typography>

      <DateRange dates={dates} setDates={setDates} variant="outlined" size="small" />
      <IconButton
        onClick={() => setGraphType(graphType === 'bar' ? 'line' : 'bar')}
        title="Toggle graph type"
        color="secondary"
      >
        {graphType === 'bar' ? <BarChart /> : <ShowChart />}
      </IconButton>
    </Box>
  )
}

export default PayrollsInvoicedHeader
