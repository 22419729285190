import { ApiError, Report } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  reportId: string
}

const useDeleteReport = (): UseMutationResult<Report, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ reportId }) =>
      axios.delete<Report>(`/reports/${reportId}`).then(res => res.data),
    {
      mutationKey: 'delete report',
      onSuccess: res => {
        queryClient.setQueryData<Report[] | undefined>(
          ['reports'],
          data => data && data.filter(d => d.id !== res.id)
        )
        enqueueSnackbar('Report deleted', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to delete report', { variant: 'error' })
      }
    }
  )
}

export default useDeleteReport
