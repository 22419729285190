import { ApiError, QuerySelect, Report } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useReports = (
  select?: QuerySelect<Report[]>
): UseQueryResult<Report[], ApiError> =>
  useQuery(
    ['reports'],
    () => axios.get<Report[]>('/reports').then(res => res.data),
    { select }
  )

export default useReports
