import { Group, User } from '@models'
import { Grid } from '@mui/material'
import { Dispatch, FC } from 'react'
import { Action, State } from '../GroupMembersTab'
import MoveButtons from './MoveButtons'
import UserList from './UserList'

interface TransferListProps {
  group?: Group
  users?: User[]
  state: State
  dispatch: Dispatch<Action>
}

const TransferList: FC<TransferListProps> = ({ users, dispatch, state }) => {
  const mapListItems = (id: string) => ({
    user: users?.find(u => u.id === id),
    checked: state.checked.includes(id)
  })

  const left = state.left.map(mapListItems)
  const right = state.right.map(mapListItems)

  return (
    <>
      <Grid item data-testid="left-list">
        <UserList
          title="Users"
          items={left}
          toggle={id => dispatch({ type: 'toggle_checkbox', payload: { id } })}
          toggleAll={() => dispatch({ type: 'toggle_all_left' })}
        />
      </Grid>

      <Grid item>
        <MoveButtons state={state} dispatch={dispatch} />
      </Grid>

      <Grid item data-testid="right-list">
        <UserList
          title="Members"
          items={right}
          toggle={id => dispatch({ type: 'toggle_checkbox', payload: { id } })}
          toggleAll={() => dispatch({ type: 'toggle_all_right' })}
        />
      </Grid>
    </>
  )
}

export default TransferList
