import { ApiError, User } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type UserSettings = User['settings']
type Payload = User['settings']

const useUpdateUserFavorites = (): UseMutationResult<
  UserSettings,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .patch<UserSettings>(`/current_user/settings/reports`, payload)
        .then(res => res.data),
    {
      mutationKey: 'update user favorites',
      onSuccess: settings => {
        queryClient.setQueryData<User>(
          ['current_user'],
          user => new User({ ...user, settings })
        )
        queryClient.invalidateQueries(['reports'])
      },
      onError: () => {
        enqueueSnackbar('Failed to add favorite', { variant: 'error' })
      }
    }
  )
}

export default useUpdateUserFavorites
