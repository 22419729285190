import { InputProps } from '@models'
import {
  Box,
  Checkbox as MatCheckbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Stack
} from '@mui/material'
import { FC, forwardRef } from 'react'
import { useController } from 'react-hook-form'

interface SelectorParameter {
  label: string
  value: string
}

interface SelectorCheckboxProps {
  label: string
  value: boolean
  onChange(): void
}
const SelectorParamCheckbox: FC<SelectorCheckboxProps> = ({
  label,
  value,
  onChange
}) => (
  <FormControlLabel
    label={label}
    control={<MatCheckbox checked={value} onChange={onChange} color="primary" />}
  />
)

interface Props extends InputProps {
  parameters: SelectorParameter[]
}

const MappingLineSelectorParams = forwardRef<HTMLDivElement, Props>(
  ({ control, name, parameters }, ref) => {
    const { field } = useController({ control, name })

    const toggleParameter = (parameter: SelectorParameter) => {
      const params = [...field.value]
      const value = String(parameter.value)

      field.onChange(
        params.includes(value) ? params.filter(p => p !== value) : [...params, value]
      )
    }

    return (
      <div ref={ref}>
        <FormLabel>Parameters</FormLabel>
        <Box sx={{ mx: 5, mt: 1 }}>
          <Stack divider={<Divider light />}>
            {parameters?.map(param => (
              <SelectorParamCheckbox
                key={param.value}
                label={param.label}
                value={field.value?.includes(param.value.toString())}
                onChange={() => toggleParameter(param)}
              />
            ))}
          </Stack>
        </Box>
      </div>
    )
  }
)

export default MappingLineSelectorParams
