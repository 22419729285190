import { useCreateUser } from '@api/mutations'
import { useCompanies } from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, Input } from '@common/inputs/form'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

interface UserForm {
  name: string
  email: string
  companyId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateUserModal: FC<Props> = ({ open, onClose }) => {
  const createUserForm = useForm<UserForm>({
    defaultValues: {
      name: '',
      email: '',
      companyId: ''
    }
  })

  const fetchCompanies = useCompanies(open)
  const createUserMut = useCreateUser()

  return (
    <FormDialog
      form={createUserForm}
      open={open}
      title="Create User"
      onClose={onClose}
      onSubmit={data => createUserMut.mutate(data, { onSuccess: onClose })}
    >
      <Input name="name" />
      <Input name="email" />
      <Autocomplete
        label="Company"
        name="companyId"
        optionLabelKey="name"
        query={fetchCompanies}
      />
    </FormDialog>
  )
}
export default CreateUserModal
