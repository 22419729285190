import {
  Box,
  Divider,
  Fade,
  LinearProgress,
  Modal as MatModal,
  Paper,
  Typography
} from '@mui/material'
import { FC, ReactElement } from 'react'

interface Props {
  open: boolean
  title: string | ReactElement
  loading?: boolean
  width?: number
  onClose(): void
}

const Modal: FC<Props> = ({
  open,
  title,
  loading = false,
  width = 750,
  children,
  onClose
}): ReactElement => (
  <MatModal open={open} onClose={onClose} aria-labelledby="modal-title">
    <Fade in={open}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width
        }}
      >
        <Box
          id="modal-title"
          sx={{
            pt: 2,
            px: 3,
            pb: 1
          }}
        >
          {typeof title === 'string' ? (
            <Typography variant="h2">{title}</Typography>
          ) : (
            title
          )}
        </Box>

        <Box id="modal-divider" height={16}>
          <Divider variant="middle" />
          {loading && <LinearProgress />}
        </Box>

        <Box
          id="modal-content"
          sx={{
            pt: 1,
            px: 4,
            pb: 2
          }}
        >
          {children}
        </Box>
      </Paper>
    </Fade>
  </MatModal>
)

export default Modal
