import { useUpdateConnectionMappingLine } from '@api/mutations'
import {
  useCompanyTaxPayerEntities,
  useConnectionClasses,
  useConnectionItems,
  useConnectionMapping
} from '@api/queries'
import { ConnectionItemLine } from '@models'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import MappingLineFormModal, { MappingLineFormData } from './MappingLineFormModal'

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  open: boolean
  line?: ConnectionItemLine
  onClose(): void
}

const EditMappingLineModal: FC<Props> = ({ open, line, onClose }) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const updateLineMut = useUpdateConnectionMappingLine(connectionId, mappingId)

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchItems = useConnectionItems(connectionId, open)
  const fetchClasses = useConnectionClasses(connectionId, open)
  const fetchTaxPayerEntities = useCompanyTaxPayerEntities(
    fetchMapping.data?.companyId,
    open && fetchMapping.isSuccess
  )

  const defaultValues = {
    itemId: line?.itemId,
    description: line?.description,
    rate: line?.rate,
    classId: line?.classId,
    taxPayerEntityId: line?.taxPayerEntityId,
    selector: {
      type: line?.selector.type,
      params: line?.selector.params ?? []
    }
  }

  const editMappingLineForm = useForm<MappingLineFormData>({ defaultValues })

  useEffect(() => {
    if (open && line) editMappingLineForm.reset({ ...defaultValues })
  }, [open, line])

  const onSubmit: SubmitHandler<MappingLineFormData> = data => {
    const itemDoc = fetchItems.data?.find(item => item.itemId === data.itemId)
    const classDoc = fetchClasses.data?.find(cls => cls.classId === data.classId)
    const tpeDoc = fetchTaxPayerEntities.data?.find(
      tpe => tpe.taxPayerEntityId.toString() === data.taxPayerEntityId.toString()
    )

    updateLineMut.mutate(
      {
        data: {
          ...data,
          itemName: itemDoc?.fullName,
          className: classDoc?.fullName,
          taxPayerEntityId: data.taxPayerEntityId.toString(),
          taxPayerEntityName: tpeDoc?.name
        },
        lineId: line?._id ?? ''
      },
      {
        onSuccess: () => {
          onClose()
          editMappingLineForm.reset()
        }
      }
    )
  }

  return (
    <MappingLineFormModal
      open={open}
      form={editMappingLineForm}
      title="Edit Line"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default EditMappingLineModal
