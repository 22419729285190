import { useCurrentUser } from '@api/queries'
import { useDarkMode, useMobileMode } from '@hooks'
import {
  ChevronLeft,
  DarkModeOutlined,
  ExitToApp,
  LightModeOutlined,
  Menu as MenuIcon,
  Person
} from '@mui/icons-material'
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import axios from 'axios'
import { FC, MouseEvent, useState } from 'react'

interface AppBarProps extends MuiAppBarProps {
  open: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
  // ...(open && {
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen
  //   })
  // })
}))

interface Props {
  open: boolean
  setOpen(open: boolean): void
}

const Header: FC<Props> = ({ open, setOpen }) => {
  const fetchCurrentUser = useCurrentUser()

  const { isDarkMode, toggle: toggleDarkMode } = useDarkMode()
  const mobileMode = useMobileMode()

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  const openMenu = (e: MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(e.currentTarget)

  const signOut = () => {
    localStorage.clear()
    axios.defaults.headers.common['Authorization'] = ''
    setMenuAnchor(null)

    window.location.reload()
  }

  return (
    <AppBar position="fixed" color="default" open={open}>
      <Toolbar>
        {fetchCurrentUser.isSuccess && (
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            title="Open sidebar"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{ mr: 2 }}
          >
            {open ? <ChevronLeft /> : <MenuIcon />}
          </IconButton>
        )}

        <Typography variant="h3" noWrap flexGrow={1}>
          Cloud9 Internal
        </Typography>

        {fetchCurrentUser.isSuccess && (
          <Box>
            {mobileMode ? (
              <IconButton color="inherit" onClick={openMenu} id="user-menu-icon">
                <Person />
              </IconButton>
            ) : (
              <Button
                color="inherit"
                onClick={openMenu}
                variant="text"
                id="user-menu"
              >
                {fetchCurrentUser.data.name}
              </Button>
            )}

            <Menu
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={() => setMenuAnchor(null)}
            >
              <MenuItem onClick={() => toggleDarkMode()}>
                <ListItemIcon>
                  {isDarkMode ? <LightModeOutlined /> : <DarkModeOutlined />}
                </ListItemIcon>
                <ListItemText primary={isDarkMode ? 'Light Mode' : 'Dark Mode'} />
              </MenuItem>

              <MenuItem onClick={signOut}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
