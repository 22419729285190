import { ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

type TaxPayerEntity = {
  id: number
  companyId: number
  taxPayerEntityId: string
  name: string
  type: number
}

const useCompanyTaxPayerEntities = (
  companyId: string | undefined,
  enabled?: boolean
): UseQueryResult<TaxPayerEntity[], ApiError> => {
  return useQuery(
    ['company', companyId, 'tax-payer-entities'],
    () =>
      axios
        .get<TaxPayerEntity[]>(`/companies/${companyId}/tax-payer-entities`)
        .then(res => res.data),
    {
      enabled: !!companyId && enabled,
      select: data =>
        data.map(tpe => ({
          ...tpe,
          taxPayerEntityId: tpe.taxPayerEntityId.toString()
        }))
    }
  )
}
export default useCompanyTaxPayerEntities
