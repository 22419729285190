import { useUpdateGroup } from '@api/mutations'
import { useGroup } from '@api/queries'
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

const GroupFulfillmentRights: FC = () => {
  const { groupId } = useParams<{ groupId: string }>()

  const fetchGroup = useGroup(groupId)
  const updateGroupMut = useUpdateGroup(groupId)

  const [fulfillment, setFulfillment] = useState(
    fetchGroup.data?.fulfillment ?? false
  )
  const [delivery, setDelivery] = useState(fetchGroup.data?.delivery ?? false)

  const onSubmit = () => {
    updateGroupMut.mutate({ fulfillment, delivery })
  }

  const resetFields = () => {
    setFulfillment(fetchGroup.data?.fulfillment ?? false)
    setDelivery(fetchGroup.data?.delivery ?? false)
  }

  const isDisabled = (): boolean => {
    if (updateGroupMut.isLoading || fetchGroup.isFetching || !fetchGroup.data)
      return true

    return (
      fulfillment === fetchGroup.data?.fulfillment &&
      delivery === fetchGroup.data?.delivery
    )
  }

  return (
    <>
      <Box height={500}>
        <List role="list">
          <ListItem
            role="listitem"
            button
            tabIndex={-1}
            onClick={() => setFulfillment(!fulfillment)}
          >
            <ListItemIcon>
              <Checkbox checked={fulfillment} disableRipple color="primary" />
            </ListItemIcon>
            <ListItemText primary={<Typography>Fulfillments</Typography>} />
          </ListItem>
          <ListItem
            role="listitem"
            button
            tabIndex={-1}
            onClick={() => setDelivery(!delivery)}
          >
            <ListItemIcon>
              <Checkbox checked={delivery} disableRipple color="primary" />
            </ListItemIcon>
            <ListItemText primary={<Typography>Delivery</Typography>} />
          </ListItem>
        </List>
      </Box>

      <Box
        sx={{
          mt: 2,
          '& button': {
            mr: 2
          }
        }}
      >
        <Button onClick={resetFields} disabled={isDisabled()} color="inherit">
          Cancel
        </Button>

        <Button onClick={onSubmit} disabled={isDisabled()} color="secondary">
          Save
        </Button>
      </Box>
    </>
  )
}

export default GroupFulfillmentRights
