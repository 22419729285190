import { useCreateMultiExport, useDeleteExport } from '@api/mutations'
import { useConnectionExportPayrolls } from '@api/queries'
import { Link } from '@common'
import { DataTable } from '@common/table'
import { ConnectionExportPayroll, DateRangeType } from '@models'
import { Delete, Science } from '@mui/icons-material'
import { Box, Button, Checkbox } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { confirmFirst } from '@utils'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  dates: DateRangeType
  selectForTest(payroll: ConnectionExportPayroll): void
}

const QBExportsTable: FC<Props> = ({ dates, selectForTest }) => {
  const { connectionId } = useParams<{ connectionId?: string }>()
  const [selected, setSelected] = useState<string[]>([])

  const fetchPayrolls = useConnectionExportPayrolls(connectionId ?? '', dates)

  const createMultiExportMut = useCreateMultiExport(connectionId ?? '')

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
        renderCell: ({ row }) => (
          <Link
            label={row.companyName}
            path={`/quickbooks/connections/${connectionId}/mappings/${row.mappingId}`}
          />
        )
      },
      { field: 'payrollNumber', headerName: 'Payroll ID', flex: 1 },
      { field: 'checkDate', headerName: 'Check Date', flex: 1, type: 'date' },
      {
        field: 'export',
        headerName: 'Export',
        width: 120,
        align: 'center',
        headerAlign: 'center',
        disableColumnMenu: true,
        disableExport: true,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Box>
            <strong>Export</strong>
            <Checkbox
              disabled={!fetchPayrolls.isSuccess}
              checked={selected.length === fetchPayrolls.data?.length}
              indeterminate={
                selected.length > 0 &&
                selected.length < (fetchPayrolls.data?.length ?? 0)
              }
              onClick={toggleAllRows}
              inputProps={{
                title: 'Toggle all'
              }}
            />
          </Box>
        ),
        renderCell: ({ row }) => (
          <Checkbox
            checked={selected.includes('' + row.id)}
            onClick={() => toggleCheckbox('' + row.id)}
          />
        )
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={`test-${id}`}
            icon={<Science color="secondary" />}
            label="Test"
            onClick={() => selectForTest(row as ConnectionExportPayroll)}
            showInMenu
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            icon={<Delete color="error" />}
            label="Delete"
            onClick={() => onDelete(id + '')}
            showInMenu
          />
        ]
      }
    ],
    [fetchPayrolls.status, selected]
  )
  const deleteExportMut = useDeleteExport(connectionId ?? '')

  const onDelete = (payrollId: string) =>
    confirmFirst('Would you like to delete this export?', () =>
      deleteExportMut.mutate({ payrollId })
    )

  const toggleCheckbox = (id: string) => {
    if (selected.includes(id)) setSelected(selected.filter(s => s !== id))
    else setSelected([...selected, id])
  }

  const toggleAllRows = () => {
    if (selected.length === fetchPayrolls.data?.length) setSelected([])
    else setSelected(fetchPayrolls.data?.map(p => '' + p.id) ?? [])
  }

  const exportPayrolls = () => {
    const payrolls = selected.map(id => {
      const p = fetchPayrolls.data?.find(p => '' + p.id === id)
      return {
        payrollId: id,
        payrollNumber: p?.payrollNumber ?? '',
        companyId: p ? p.companyId + '' : ''
      }
    })

    createMultiExportMut.mutate({ payrolls })
  }

  return (
    <DataTable
      query={fetchPayrolls}
      columns={columns}
      loading={createMultiExportMut.isLoading}
      customButton={
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ ml: 4 }}
          onClick={() => exportPayrolls()}
          disabled={createMultiExportMut.isLoading || !selected.length}
        >
          Export
        </Button>
      }
    />
  )
}

export default QBExportsTable
