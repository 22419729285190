import { ApiError, ConnectionAccount, QBConnection } from '@models'
import axios from 'axios'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

const useConnectionAccounts = (
  connectionId: string | undefined,
  enabled?: boolean,
  includeAll?: boolean
): UseQueryResult<ConnectionAccount[], ApiError> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['quickbooks', 'connections', connectionId, 'accounts'],
    () =>
      axios
        .get<ConnectionAccount[]>(`/quickbooks/connections/${connectionId}/accounts`)
        .then(res => res.data),
    {
      enabled: !!connectionId && enabled,
      initialData: () =>
        queryClient.getQueryData<QBConnection>([
          'quickbooks',
          'connections',
          connectionId
        ])?.accounts,
      select: data => {
        const connection = queryClient.getQueryData<QBConnection>([
          'quickbooks',
          'connections',
          connectionId
        ])
        const preferred = connection?.preferences.accounts

        if (includeAll || !preferred) return data
        else return data.filter(d => preferred.includes(d.accountId))
      }
    }
  )
}
export default useConnectionAccounts
