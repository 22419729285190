import { useConnections } from '@api/queries'
import { Autocomplete, DateRange } from '@common/inputs/basic'
import { ConnectionEntityType, DateRangeType } from '@models'
import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useHistory, useParams } from 'react-router-dom'

interface Props {
  dates: DateRangeType
  setDates(dates: DateRangeType): void
}

const QBExportsHeader: FC<Props> = ({ dates, setDates }) => {
  const { connectionId } = useParams<{ connectionId?: string }>()
  const history = useHistory()

  const fetchConnections = useConnections(ConnectionEntityType.Reseller)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="h1" flexGrow={1}>
        Exports
      </Typography>

      <DateRange dates={dates} setDates={setDates} variant="outlined" size="small" />

      <Autocomplete
        label="Connection"
        name="connection-select"
        query={fetchConnections}
        value={connectionId ?? ''}
        onChange={id => history.push(`/quickbooks/exports/${id}`)}
        optionLabelKey="companyName"
        autoFocus={!connectionId}
        error={!connectionId}
        fullWidth={false}
        variant="outlined"
        size="small"
        noMargin
        disableClearable
        sx={{ minWidth: 210, ml: 5 }}
      />
    </Box>
  )
}

export default QBExportsHeader
