import { ApiError, ConnectionLine } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  lineId: string
  data: Partial<ConnectionLine>
}

const useUpdateConnectionLine = (
  connectionId: string
): UseMutationResult<ConnectionLine, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ lineId, data }) =>
      axios
        .patch<ConnectionLine>(
          `/quickbooks/connections/${connectionId}/lines/${lineId}`,
          data
        )
        .then(res => res.data),
    {
      mutationKey: 'update connection line',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionLine[] | undefined>(
          ['quickbooks', 'connections', connectionId, 'lines'],
          data => data && [...data, res]
        )
        enqueueSnackbar('Line updated', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update line', { variant: 'error' })
      }
    }
  )
}

export default useUpdateConnectionLine
