import { useDeleteReport, useUpdateReport } from '@api/mutations'
import { Report } from '@models'
import { confirmFirst } from '@utils'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import ReportFormModal, { ReportFormData } from './ReportFormModal'

interface Props {
  open: boolean
  report?: Report
  tags: string[]
  onClose(): void
}

const EditReportModal: FC<Props> = ({ open, report, tags, onClose }) => {
  const editReportForm = useForm<ReportFormData>({
    defaultValues: new ReportFormData(report)
  })

  const updateReportMut = useUpdateReport(report?.id ?? '')
  const deleteReportMut = useDeleteReport()

  useEffect(() => {
    if (open && report) editReportForm.reset(new ReportFormData(report))
  }, [open, report])

  const onDelete = () =>
    confirmFirst('Would you like to delete this report?', () =>
      deleteReportMut.mutate({ reportId: report?.id ?? '' }, { onSuccess: onClose })
    )

  const onSubmit: SubmitHandler<ReportFormData> = data => {
    delete data.formSettings
    updateReportMut.mutate(data, { onSuccess: onClose })
  }

  return (
    <ReportFormModal
      open={open}
      form={editReportForm}
      tags={tags}
      title="Edit Report"
      onClose={onClose}
      onDelete={onDelete}
      onSubmit={onSubmit}
    />
  )
}

export default EditReportModal
