import { FormDialog } from '@common'
import { Input, Multiselect } from '@common/inputs/form'
import {
  AccessCompanyReport,
  AccessCompanyReportParameters,
  AccessCompanyReportQuery
} from '@models'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import CompanyReportParamsChecklist from '../inputs/CompanyReportParamsChecklist'
import CompanyReportQueryInputs from '../inputs/CompanyReportQueryInputs'

export class CompanyReportFormData {
  name: string
  tags: string[]
  parameters: AccessCompanyReportParameters
  queries: AccessCompanyReportQuery[]

  constructor(data?: CompanyReportFormData | AccessCompanyReport) {
    this.name = data?.name || ''
    this.tags = data?.tags || []
    this.parameters = data?.parameters || { dateRange: false, payrollId: false }
    this.queries = data?.queries || [{ label: '', query: '' }]
  }
}

interface Props {
  open: boolean
  form: UseFormReturn<CompanyReportFormData>
  tags: string[]
  title: string
  onClose(): void
  onDelete?(): void
  onSubmit(data: CompanyReportFormData): void
}

const CompanyReportModal: FC<Props> = ({
  open,
  form,
  tags,
  title,
  onClose,
  onDelete,
  onSubmit
}) => {
  return (
    <FormDialog
      form={form}
      open={open}
      title={title}
      secondaryActions={
        onDelete && [{ title: 'Delete', color: 'error', onClick: onDelete }]
      }
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Input name="name" />
      <Multiselect label="Tags" name="tags" options={tags} freeSolo />

      <CompanyReportQueryInputs name="queries" />

      <CompanyReportParamsChecklist name="parameters" />
    </FormDialog>
  )
}

export default CompanyReportModal
