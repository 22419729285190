import { useSendQuickbooksInvite } from '@api/mutations'
import { FormDialog } from '@common'
import { Input } from '@common/inputs/form'
import { QBConnection } from '@models'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

type InviteForm = Pick<QBConnection, 'contactEmail'>

interface Props {
  connection?: QBConnection
  onClose(): void
}

const SendInviteModal: FC<Props> = ({ connection, onClose }) => {
  const sendInviteMut = useSendQuickbooksInvite(connection?.id)

  const defaultValues = { contactEmail: connection?.contactEmail }

  const sendInviteForm = useForm<InviteForm>({ defaultValues })

  useEffect(() => {
    if (connection) sendInviteForm.reset({ ...defaultValues })
  }, [connection])

  return (
    <FormDialog<InviteForm>
      form={sendInviteForm}
      open={!!connection}
      title="Send Invite"
      onClose={onClose}
      onSubmit={data => sendInviteMut.mutate(data, { onSuccess: onClose })}
    >
      <Input label="Contact Email" name="contactEmail" />
    </FormDialog>
  )
}
export default SendInviteModal
