import { ApiError, ConnectionMapping } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionMappings = (
  connectionId: string | undefined
): UseQueryResult<ConnectionMapping[], ApiError> =>
  useQuery(
    ['quickbooks', 'connections', connectionId, 'mappings'],
    () =>
      axios
        .get<ConnectionMapping[]>(`/quickbooks/connections/${connectionId}/mappings`)
        .then(res => res.data),
    {
      enabled: !!connectionId,
      select: mappings => mappings.map(map => new ConnectionMapping(map))
    }
  )

export default useConnectionMappings
