import { User } from '@models'
import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { FC } from 'react'

interface Item {
  user?: User
  checked: boolean
}

interface Props {
  items: Item[]
  title: string
  toggle(id: string): void
  toggleAll(): void
}

const UserList: FC<Props> = ({ items, title, toggle, toggleAll }) => {
  const checkedItems = items.filter(item => item.checked)

  return (
    <Card
      sx={{
        width: 350,
        height: 450,
        overflow: 'auto',
        '& .MuiListItem-root': {
          p: 1
        }
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1.5 }}
        avatar={
          <Checkbox
            onClick={toggleAll}
            checked={items.length !== 0 && checkedItems.length === items.length}
            indeterminate={
              checkedItems.length > 0 && checkedItems.length < items.length
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'select all items' }}
          />
        }
        title={<Typography variant="h6">{title}</Typography>}
        subheader={`${checkedItems.length}/${items.length} selected`}
      />

      <Divider />

      <List dense role="list">
        {items &&
          items
            .sort((first, second) =>
              !first.user || !second.user
                ? 0
                : first.user.name.toLowerCase() > second.user.name.toLowerCase()
                ? 1
                : -1
            )
            .map(({ user, checked }) =>
              user ? (
                <ListItem
                  key={user.id}
                  role="listitem"
                  button
                  onClick={() => toggle(user.id)}
                  divider
                  tabIndex={-1}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': `transfer-list-item-${user.id}`
                      }}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`transfer-list-item-${user.id}`}
                    primary={
                      <Typography variant="subtitle2">{user.name}</Typography>
                    }
                  />
                </ListItem>
              ) : undefined
            )}
        <ListItem />
      </List>
    </Card>
  )
}
export default UserList
