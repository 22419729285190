import { AccessCompanyReport, ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Pick<AccessCompanyReport, 'name' | 'tags' | 'queries' | 'parameters'>

const useUpdateCompanyReport = (
  reportId: string
): UseMutationResult<AccessCompanyReport, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .patch(`/access/companies/reports/${reportId}`, payload)
        .then(res => res.data),
    {
      mutationKey: 'update company report',
      onSuccess: res => {
        queryClient.setQueryData<AccessCompanyReport[] | undefined>(
          ['access', 'companies', 'reports'],
          data => data?.map(d => (d.id === res.id ? res : d))
        )
        enqueueSnackbar(`Report updated`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update report', { variant: 'error' })
      }
    }
  )
}

export default useUpdateCompanyReport
