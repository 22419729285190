import { useDownloadReport } from '@api/mutations'
import {
  useCompanies,
  useCurrentUser,
  usePayrolls,
  useResellers,
  useTaxPayerEntities
} from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, DateRange } from '@common/inputs/form'
import { Report } from '@models'
import { Box, Typography } from '@mui/material'
import { formatDate, toCsvSheet } from '@utils'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import XLSX from 'xlsx'
import QuarterInput from '../inputs/QuarterInputs'

interface DownloadFormData {
  startDate?: Date | string
  endDate?: Date | string
  quarter?: number
  year?: Date | number
  resellerId?: string
  taxPayerEntityId?: string
  companyId?: string
  payrollId?: string
}

interface Props {
  open: boolean
  report?: Report
  onClose(): void
}

const DownloadReportModal: FC<Props> = ({ open, report, onClose }) => {
  const { data: user } = useCurrentUser()

  const formFields: Report['options']['select'] = {
    dateRange: !!report?.options.select.dateRange,
    quarterly: !!report?.options.select.quarterly,
    resellerId: !!report?.options.select.resellerId,
    taxPayerEntityId: !!report?.options.select.taxPayerEntityId,
    companyId: !!report?.options.select.companyId,
    payrollId: !!report?.options.select.payrollId
  }

  const defaultValues = open
    ? {
        ...(formFields.dateRange && {
          startDate: new Date(),
          endDate: new Date()
        }),
        ...(formFields.quarterly && { year: new Date(), quarter: 1 }),
        ...(formFields.resellerId && { resellerId: '' }),
        ...(formFields.taxPayerEntityId && { taxPayerEntityId: '' }),
        ...(formFields.companyId && { companyId: '' }),
        ...(formFields.payrollId && { payrollId: '' })
      }
    : {}

  const downloadForm = useForm<DownloadFormData>({ defaultValues })
  const [watchCompanyId, watchResellerId] = downloadForm.watch([
    'companyId',
    'resellerId'
  ])
  const companyId = report?.options.inherit.companyId
    ? user?.companyId
    : watchCompanyId

  const downloadReportMut = useDownloadReport(report?.id ?? '')

  const fetchResellers = useResellers(open && formFields.resellerId)
  const fetchTaxPayerEntities = useTaxPayerEntities(
    watchResellerId,
    open && !!watchResellerId && formFields.taxPayerEntityId
  )
  const fetchCompanies = useCompanies(open && formFields.companyId)
  const fetchPayrolls = usePayrolls(companyId, open && formFields.payrollId)

  useEffect(() => {
    if (open && report) downloadForm.reset({ ...defaultValues })
  }, [open, report])

  const onSubmit: SubmitHandler<DownloadFormData> = submitData => {
    let data = { ...submitData }

    if (data.startDate && data.endDate)
      data = {
        ...data,
        startDate: formatDate(new Date(data.startDate), 'yyyy-MM-dd'),
        endDate: formatDate(new Date(data.endDate), 'yyyy-MM-dd')
      }
    if (data.year) data.year = new Date(data.year).getFullYear()

    downloadReportMut.mutate(data, {
      onSuccess: res => {
        const workbook = XLSX.utils.book_new()

        for (const response of res) {
          XLSX.utils.book_append_sheet(
            workbook,
            toCsvSheet(response.results),
            response.label
          )
        }

        const name = report?.name.replace(/[[|\]:/\\?*]/g, '')
        XLSX.writeFile(
          workbook,
          `${name}-${formatDate(new Date(), 'MM_dd_yy')}.xlsx`
        )

        onClose()
      }
    })
  }

  return (
    <FormDialog<DownloadFormData>
      form={downloadForm}
      open={open}
      title={report?.name || ''}
      onClose={() => onClose()}
      onSubmit={onSubmit}
    >
      {formFields.dateRange && <DateRange name="dateRange" />}

      {formFields.quarterly && <QuarterInput name="quarter" />}

      {formFields.resellerId && (
        <Autocomplete
          label="Reseller"
          name="resellerId"
          optionLabelKey="name"
          query={fetchResellers}
        />
      )}

      {formFields.taxPayerEntityId && (
        <Autocomplete
          label="Tax Payer Entity"
          name="taxPayerEntityId"
          optionLabelKey="name"
          query={fetchTaxPayerEntities}
        />
      )}

      {formFields.companyId && (
        <Autocomplete
          label="Company"
          name="companyId"
          optionLabelKey="name"
          query={fetchCompanies}
        />
      )}

      {formFields.payrollId && (
        <Autocomplete
          label="Payroll"
          name="payrollId"
          query={fetchPayrolls}
          sort={{ key: 'checkDate', direction: 'desc' }}
          getOptionLabel={option =>
            `${option.companyPayrollId} - ${formatDate(
              new Date(option.checkDate),
              'PP'
            )}`
          }
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Typography variant="body1" mr={1}>
                {option.companyPayrollId}
              </Typography>
              -
              <Typography variant="body2" ml={1}>
                {formatDate(new Date(option.checkDate), 'P')}
              </Typography>
            </Box>
          )}
        />
      )}
    </FormDialog>
  )
}

export default DownloadReportModal
