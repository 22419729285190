import { useUpdateGroup } from '@api/mutations'
import { useGroup, useUsers } from '@api/queries'
import { Button, Grid } from '@mui/material'
import { FC, useEffect, useMemo, useReducer } from 'react'
import { useIsFetching, useIsMutating } from 'react-query'
import { useParams } from 'react-router-dom'
import TransferList from './components/TransferList'

export type Action =
  | { type: 'init'; payload: { left: string[]; right: string[] } }
  | { type: 'toggle_checkbox'; payload: { id: string } }
  | { type: 'toggle_all_left' }
  | { type: 'toggle_all_right' }
  | { type: 'move_all_left_to_right' }
  | { type: 'move_checked_left_to_right' }
  | { type: 'move_all_right_to_left' }
  | { type: 'move_checked_right_to_left' }

type Reducer = (state: State, action: Action) => State

export class State {
  checked: string[]
  left: string[]
  right: string[]
  init: { left: string[]; right: string[] }

  constructor(props: Omit<State, 'checkedLeft' | 'checkedRight' | 'filter'>) {
    this.checked = props.checked
    this.left = props.left
    this.right = props.right
    this.init = props.init
  }

  get checkedLeft(): string[] {
    return this.left.filter(val => this.checked.indexOf(val) !== -1)
  }

  get checkedRight(): string[] {
    return this.right.filter(val => this.checked.indexOf(val) !== -1)
  }
}

const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return new State({
        checked: [],
        init: action.payload,
        ...action.payload
      })
    case 'toggle_checkbox':
      return new State({
        ...state,
        checked: state.checked.includes(action.payload.id)
          ? state.checked.filter(val => val !== action.payload.id)
          : [...state.checked, action.payload.id]
      })
    case 'toggle_all_left':
      return new State({
        ...state,
        checked: state.left.every(val => state.checked.includes(val))
          ? [...state.checked.filter(val => !state.left.includes(val))]
          : [
              ...state.checked,
              ...state.left.filter(val => !state.checked.includes(val))
            ]
      })
    case 'toggle_all_right':
      return new State({
        ...state,
        checked: state.right.every(val => state.checked.includes(val))
          ? [...state.checked.filter(val => !state.right.includes(val))]
          : [
              ...state.checked,
              ...state.right.filter(val => !state.checked.includes(val))
            ]
      })
    case 'move_all_left_to_right':
      return new State({
        ...state,
        checked: state.checkedRight,
        left: [],
        right: state.right.concat(state.left)
      })
    case 'move_checked_left_to_right':
      return new State({
        ...state,
        checked: state.checkedRight,
        left: state.left.filter(val => state.checkedLeft.indexOf(val) === -1),
        right: state.right.concat(state.checkedLeft)
      })
    case 'move_all_right_to_left':
      return new State({
        ...state,
        checked: state.checkedLeft,
        left: state.left.concat(state.right),
        right: []
      })
    case 'move_checked_right_to_left':
      return new State({
        ...state,
        checked: state.checkedLeft,
        left: state.left.concat(state.checkedRight),
        right: state.right.filter(val => state.checkedRight.indexOf(val) === -1)
      })
  }
}

const GroupMembersTab: FC = () => {
  const { groupId } = useParams<{ groupId: string }>()

  const fetchGroup = useGroup(groupId)
  const fetchUsers = useUsers()
  const updateGroupMut = useUpdateGroup(groupId)

  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  const [state, dispatch] = useReducer(
    reducer,
    new State({
      checked: [],
      left: [],
      right: [],
      init: { left: [], right: [] }
    })
  )

  const initUsers = () => {
    if (!fetchGroup.data || !fetchUsers.data) return

    const left: string[] = []
    const right: string[] = []

    fetchUsers.data.forEach(user => {
      fetchGroup.data.users.includes(user.id)
        ? right.push(user.id)
        : left.push(user.id)
    })

    dispatch({ type: 'init', payload: { left, right } })
  }

  useEffect(() => {
    initUsers()
  }, [fetchUsers.data, fetchGroup.data])

  const isDisabled = useMemo((): boolean => {
    if (isFetching || isMutating) return true

    if (!state.init || (!state.left.length && !state.right.length)) return true

    for (const leftUser of state.init.left) {
      if (!state.left.includes(leftUser)) return false
    }
    for (const rightUser of state.init.right) {
      if (!state.right.includes(rightUser)) return false
    }

    return true
  }, [state, isFetching, isMutating])

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="space-around"
      alignItems="center"
      pt={4}
    >
      <TransferList
        state={state}
        dispatch={dispatch}
        group={fetchGroup.data}
        users={fetchUsers.data}
      />

      <Grid item xs={12} ml={2} mt={8}>
        <Button onClick={() => initUsers()} disabled={isDisabled} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={() => updateGroupMut.mutate({ users: state.right })}
          disabled={isDisabled}
          color="secondary"
          sx={{ ml: 2 }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default GroupMembersTab
