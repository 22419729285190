import { useConnectionDetails, useConnectionMapping } from '@api/queries'
import { Breadcrumb, TabsPanel } from '@common'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import MappingExportsTab from './tabs/exports/MappingExportsTab'
import MappingItemsTab from './tabs/mapping/MappingItemsTab'

interface UrlProps {
  connectionId: string
  mappingId: string
}

const ConnectionMappingItemsPage: FC = () => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const fetchConnection = useConnectionDetails(connectionId)
  const fetchMapping = useConnectionMapping(connectionId, mappingId)

  const breadcrumbs = useMemo(
    () => [
      { label: 'QuickBooks', path: '/quickbooks' },
      { label: 'Connections', path: '/quickbooks/connections' },
      {
        label: fetchConnection.data?.companyName ?? '...',
        path: `/quickbooks/connections/${fetchConnection.data?.id}`
      },
      { label: fetchMapping.data?.companyName ?? '...' }
    ],
    [fetchConnection.data, fetchMapping.data]
  )

  return (
    <div>
      <Breadcrumb crumbs={breadcrumbs} />

      <TabsPanel
        tabs={[
          { name: 'mapping', component: MappingItemsTab },
          { name: 'exports', component: MappingExportsTab }
        ]}
      />
    </div>
  )
}
export default ConnectionMappingItemsPage
