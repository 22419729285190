import { useCurrentUser } from '@api/queries'
import {
  Assignment,
  Cable,
  Group,
  Outbox,
  PermMedia,
  Person,
  Store
} from '@mui/icons-material'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SwipeableDrawer
} from '@mui/material'
import { Box } from '@mui/system'
import { FC, ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SidebarProps } from '../Sidebar'

interface SidebarItemProps {
  hide?: boolean
  name: string
  path: string
  icon: ReactElement
}
const SidebarItem: FC<SidebarItemProps> = ({ hide, name, path, icon: Icon }) => {
  const { pathname } = useLocation()
  if (hide) return null

  const active = pathname.includes(path)

  return (
    <ListItem
      button
      title={name}
      selected={active}
      component={Link}
      to={path}
      sx={{ pl: 2, color: active ? 'common.white' : 'block' }}
    >
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  )
}

const MobileSidebar: FC<SidebarProps> = ({ open, setOpen }) => {
  const iOS =
    typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)

  const { data: user } = useCurrentUser()

  if (!user) return null

  return (
    <SwipeableDrawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      ModalProps={{ keepMounted: true }}
      sx={{ zIndex: 'modal' }}
    >
      <Box
        sx={{
          width: 250,
          ['& .MuiListSubheader-root']: {
            bgcolor: 'inherit'
          }
        }}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <List
          component="nav"
          aria-labelledby="main-sidebar-list-subheader"
          subheader={
            <ListSubheader id="main-sidebar-list-subheader">Main</ListSubheader>
          }
        >
          <SidebarItem
            name="Reports"
            path={user.isCompany ? '/company/reports' : '/reports'}
            icon={<PermMedia />}
          />

          <SidebarItem
            name="Fulfillments"
            path="/fulfillments"
            icon={<Assignment />}
            hide={!user.rights.fulfillment && !user.isAdmin}
          />
        </List>

        {(user.isAdmin || user.rights.qbConnectionManagement) && (
          <List
            component="nav"
            aria-labelledby="quickbooks-sidebar-list-subheader"
            subheader={
              <ListSubheader id="quickbooks-sidebar-list-subheader">
                QuickBooks
              </ListSubheader>
            }
          >
            <SidebarItem
              name="Connections"
              path="/quickbooks/connections"
              icon={<Cable />}
            />

            <SidebarItem
              name="Exports"
              path="/quickbooks/exports"
              icon={<Outbox />}
            />
          </List>
        )}

        {user.isAdmin && (
          <List
            component="nav"
            aria-labelledby="access-sidebar-list-subheader"
            subheader={
              <ListSubheader id="access-sidebar-list-subheader">
                Access
              </ListSubheader>
            }
          >
            <SidebarItem name="Users" path="/access/users" icon={<Person />} />

            <SidebarItem name="Groups" path="/access/groups" icon={<Group />} />

            <SidebarItem
              name="Companies"
              path="/access/companies"
              icon={<Store />}
            />
          </List>
        )}
      </Box>
    </SwipeableDrawer>
  )
}

export default MobileSidebar
