import { useConnectionExport } from '@api/queries'
import { DataPreviewer, Modal } from '@common'
import { ConnectionExport } from '@models'
import { Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { formatDate } from '@utils'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

const tableColumns: GridColumns = [
  { field: 'ItemID', headerName: 'ID', flex: 1, hide: true },
  { field: 'ItemName', headerName: 'Item Name', flex: 1.5 },
  { field: 'Description', headerName: 'Description', flex: 1 },
  { field: 'Rate', headerName: 'Rate', flex: 1, type: 'number' },
  { field: 'Quantity', headerName: 'Quantity', flex: 1, type: 'number' }
]

interface RequestContent {
  Customer: { ID: string; FullName: string }
  Class: { ID: string; FullName: string }
  Date: string | Date
  ItemLines: Array<{
    Item: { ID: string; FullName: string }
    Rate: number
    Description: string
    Quantity: number
  }>
}

interface Props {
  exportId?: string
  onClose(): void
}
const MappingExportViewerModal: FC<Props> = ({ exportId, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchExport = useConnectionExport(connectionId, exportId)

  return (
    <Modal open={!!exportId} title="Response" onClose={onClose}>
      <DataPreviewer
        query={fetchExport}
        tableColumns={tableColumns}
        fileName={`Export`}
        getRows={(data: ConnectionExport) => {
          const response: RequestContent = data?.request

          return (
            response?.ItemLines.map((line, index) => ({
              id: index,
              ItemName: line.Item.FullName,
              ItemID: line.Item.ID,
              Rate: line.Rate,
              Description: line.Description,
              Quantity: line.Quantity
            })) ?? []
          )
        }}
        header={
          <Typography gutterBottom>
            Date:{' '}
            {fetchExport.data?.createdAt &&
              formatDate(new Date(fetchExport.data.createdAt), 'Pp')}
          </Typography>
        }
      />
    </Modal>
  )
}

export default MappingExportViewerModal
