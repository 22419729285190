import { ApiError, User } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult } from 'react-query'

interface Response {
  user: User
  token: string
}

interface Payload {
  email: string
  password: string
}

const useSignIn = (): UseMutationResult<Response, ApiError, Payload> => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios.post<Response>('/auth/sign-in', payload).then(res => res.data),
    {
      mutationKey: 'sign in',
      onSuccess: data => {
        localStorage.setItem('token', data.token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
        window.location.reload()
      },
      onError: () => {
        enqueueSnackbar('Email or password is incorrect', { variant: 'error' })
      }
    }
  )
}
export default useSignIn
