import { useDeleteCompanyReport } from '@api/mutations'
import { useAccessCompanyReports } from '@api/queries'
import { Link } from '@common'
import { DataTable } from '@common/table'
import { AccessCompanyReport } from '@models'
import { Delete, Description, Edit } from '@mui/icons-material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { confirmFirst } from '@utils'
import { FC, useMemo } from 'react'
import { Selected } from '../CompaniesAccessReportsTab'

interface Props {
  setSelected(params: Selected): void
}

const CompanyReportsTable: FC<Props> = ({ setSelected }) => {
  const fetchCompanyReports = useAccessCompanyReports()

  const deleteCompanyReportMut = useDeleteCompanyReport()

  const onDelete = (reportId: string) =>
    confirmFirst('Would you like to delete this report?', () =>
      deleteCompanyReportMut.mutate({ reportId })
    )

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'name', headerName: 'Report', flex: 1.5 },
      {
        field: 'downloads',
        headerName: 'Downloads',
        flex: 1,
        type: 'number',
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row }) => row.downloads.length,
        renderCell: ({ value, row }) => (
          <Link
            label={value}
            onClick={() => setSelected({ type: 'log', report: row })}
          />
        )
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={`edit-${id}`}
            icon={<Edit />}
            label="Edit report"
            onClick={() =>
              setSelected({ type: 'edit', report: row as AccessCompanyReport })
            }
            showInMenu
          />,
          <GridActionsCellItem
            key={`downloads-${id}`}
            icon={<Description />}
            label="Downloads"
            onClick={() =>
              setSelected({ type: 'log', report: row as AccessCompanyReport })
            }
            showInMenu
          />,
          <GridActionsCellItem
            key={`delete-${id}`}
            icon={<Delete color="error" />}
            label="Delete report  "
            onClick={() => onDelete(id + '')}
            showInMenu
          />
        ]
      }
    ],
    [fetchCompanyReports.status]
  )

  return (
    <DataTable
      query={fetchCompanyReports}
      columns={columns}
      defaultSort="name"
      errorMessage="Failed to fetch reports"
      addNewRow={() => setSelected({ report: undefined, type: 'create' })}
      height={625}
    />
  )
}

export default CompanyReportsTable
