import { Typography } from '@mui/material'
import { FC, useState } from 'react'
import ConnectionMappingsTable from './ConnectionMappingsTable'
import CreateConnectionMappingModal from './modals/CreateConnectionMappingModal'
import DuplicateConnectionMappingModal from './modals/DuplicateConnectionMappingModal'

const ResellerMappingsPage: FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [duplicateMappingId, setDuplicateMappingId] = useState('')

  const closeModal = () => setCreateModalOpen(false)

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Mappings
      </Typography>

      <ConnectionMappingsTable
        openDuplicateModal={id => setDuplicateMappingId(id)}
        openCreateModal={() => setCreateModalOpen(true)}
      />

      <CreateConnectionMappingModal open={createModalOpen} onClose={closeModal} />
      <DuplicateConnectionMappingModal
        mappingId={duplicateMappingId}
        onClose={() => setDuplicateMappingId('')}
      />
    </>
  )
}

export default ResellerMappingsPage
