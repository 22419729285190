import { Paper, SxProps } from '@mui/material'
import {
  DataGrid,
  GridColumns,
  GridColumnVisibilityModel,
  GridCsvExportOptions,
  GridDensity,
  GridFilterModel,
  GridRowParams,
  GridSortModel
} from '@mui/x-data-grid'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { DataTableToolbar, ToolbarProps } from './components/DataTableToolbar'
import {
  ErrorOverlay,
  LoadingOverlay,
  NoResultsOverlay
} from './components/TableOverlays'

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

interface TableProps {
  query: UseQueryResult<any> // main query object
  columns: GridColumns
  columnVisibilityModel?: GridColumnVisibilityModel
  csvOptions?: GridCsvExportOptions
  customButton?: ReactElement
  defaultSort?: string
  density?: GridDensity
  disableSortIcons?: boolean
  errorMessage?: string
  filterModel?: GridFilterModel
  headers?: boolean
  height?: number
  idKey?: string
  loading?: boolean
  rowHeight?: number
  searchBar?: boolean
  selection?: boolean
  sortModel?: GridSortModel
  toolbar?: ToolbarProps['options']
  sx?: SxProps
  addNewRow?(): void
  getRows?(data: any): any[]
  onRowClick?(param: GridRowParams): void
}

const DataTable: FC<TableProps> = ({
  query,
  columns,
  columnVisibilityModel,
  csvOptions,
  customButton,
  defaultSort,
  density = 'standard',
  disableSortIcons = false,
  errorMessage,
  filterModel,
  headers = true,
  height = 750,
  idKey = 'id',
  loading,
  rowHeight = 50,
  searchBar = true,
  selection = false,
  sortModel,
  toolbar,
  sx,
  addNewRow,
  getRows,
  onRowClick
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [rows, setRows] = useState<any[]>([])

  useEffect(() => {
    if (query.isSuccess) {
      searchData(searchVal)
    }
  }, [query.isSuccess, query.data])

  const searchData = (search: string) => {
    setSearchVal(search)
    const searchRegex = new RegExp(escapeRegExp(search), 'i')
    const data = getRows ? getRows(query.data) : query.data ?? []

    const filteredRows = data
      ?.filter((row: any) =>
        columns.some(col => {
          const val = col.valueGetter
            ? col.valueGetter({ row } as any)
            : row[col.field]

          return !val || typeof val === 'object'
            ? false
            : searchRegex.test(val.toString())
        })
      )
      .sort(sortData)

    setRows(filteredRows ?? [])
  }

  const sortData = (a: any, b: any): -1 | 0 | 1 => {
    if (!defaultSort) return 0

    const first = a[defaultSort]
    const second = b[defaultSort]

    if (typeof first === 'string')
      return first.toLowerCase() > second.toLowerCase() ? 1 : -1
    else return first > second ? 1 : -1
  }

  const toolbarProps: ToolbarProps = {
    options: {
      column: true,
      filter: true,
      density: true,
      exportable: false,
      ...toolbar
    },
    csvOptions,
    customButton,
    search: searchBar
      ? {
          value: searchVal,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
            searchData(event.target.value),
          clearSearch: () => searchData('')
        }
      : undefined,
    addNewRow
  }

  return (
    <div style={{ height, width: '100%' }} data-testid="data-table">
      <div style={{ display: 'flex', height: '100%' }}>
        <Paper sx={{ flexGrow: 1, mt: 1.5 }}>
          <DataGrid
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            components={{
              Toolbar: DataTableToolbar,
              ErrorOverlay,
              LoadingOverlay,
              NoResultsOverlay,
              ...(disableSortIcons && {
                ColumnSortedDescendingIcon: () => null,
                ColumnSortedAscendingIcon: () => null
              })
            }}
            componentsProps={{
              toolbar: toolbarProps,
              errorOverlay: { message: errorMessage }
            }}
            density={density}
            disableDensitySelector={!toolbarProps.options.density}
            disableColumnMenu={headers ? false : true}
            disableColumnSelector={!toolbarProps.options.column}
            disableSelectionOnClick={!selection}
            error={query.isError || undefined}
            getRowId={r => r[idKey]}
            headerHeight={headers ? 56 : 0}
            loading={query.isFetching || loading}
            onRowClick={onRowClick}
            pagination
            rows={rows}
            rowHeight={rowHeight}
            sortModel={sortModel}
            filterModel={filterModel}
            sx={sx}
          />
        </Paper>
      </div>
    </div>
  )
}

export default DataTable
