import { InputProps } from '@models'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { useController } from 'react-hook-form'

interface Props extends InputProps {
  autocomplete?: string
}

const PasswordInput: FC<Props> = ({
  control,
  name,
  label = 'Password',
  autocomplete,
  variant
}) => {
  const [visible, setVisible] = useState(false)

  const {
    field: { ref, ...field },
    fieldState
  } = useController({
    control: control,
    name: name,
    defaultValue: '',
    rules: { required: 'Field required' }
  })

  return (
    <TextField
      {...field}
      autoComplete={autocomplete || name}
      error={!!fieldState.error}
      fullWidth
      helperText={fieldState.error?.message || ' '}
      inputRef={ref}
      id={name}
      label={label}
      type={visible ? 'text' : 'password'}
      value={field.value || ''}
      variant={variant}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setVisible(!visible)} size="large">
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordInput
