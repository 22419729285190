import { InputProps } from '@models'
import {
  Autocomplete as MatAutocomplete,
  Chip,
  ChipProps,
  TextField
} from '@mui/material'
import { FC } from 'react'

interface MultiselectProps extends InputProps {
  value: string[]
  options?: string[]
  label: string
  fullWidth?: boolean
  freeSolo?: boolean
  chipVariant?: ChipProps['variant']
  onChange(val: string[]): void
}

const Multiselect: FC<MultiselectProps> = ({
  name,
  value,
  options = [],
  label,
  fullWidth = true,
  freeSolo = false,
  size,
  variant,
  chipVariant,
  onChange
}) => {
  return (
    <MatAutocomplete
      id={name}
      openOnFocus
      multiple
      freeSolo={freeSolo}
      limitTags={6}
      filterSelectedOptions
      disableCloseOnSelect
      autoComplete
      autoHighlight
      fullWidth={fullWidth}
      options={options}
      size={size}
      value={value}
      onChange={(e, value: string[]) => onChange(value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...props } = getTagProps({ index })
          return (
            <Chip
              key={key}
              label={option}
              size={size}
              variant={chipVariant}
              color="info"
              {...props}
            />
          )
        })
      }
      renderInput={params => (
        <TextField {...params} label={label} variant={variant} />
      )}
    />
  )
}

export default Multiselect
