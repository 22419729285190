import { useConnectionDetails, useConnectionExport } from '@api/queries'
import { DataPreviewer, Modal } from '@common'
import { ConnectionEntityType, ConnectionExport, ConnectionLineType } from '@models'
import { Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { formatDate } from '@utils'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

const companyTableColumns: GridColumns = [
  { field: 'AccountID', headerName: 'ID', flex: 1, hide: true },
  { field: 'AccountName', headerName: 'Account', flex: 2.5 },
  { field: 'Memo', headerName: 'Memo', flex: 1.5 },
  {
    field: 'Type',
    headerName: 'Type',
    flex: 1,
    type: 'singleSelect',
    valueOptions: [ConnectionLineType.Credit, ConnectionLineType.Debit],
    valueFormatter: ({ value }) =>
      (value === ConnectionLineType.Credit && 'Credit') ||
      (value === ConnectionLineType.Debit && 'Debit')
  },
  { field: 'Amount', headerName: 'Amount', flex: 1, type: 'number' }
]

const resellerTableColumns: GridColumns = [
  {
    field: 'ClassID',
    headerName: 'Class ID',
    flex: 1,
    hide: true
  },
  {
    field: 'ClassName',
    headerName: 'Class',
    flex: 1.5,
    hide: true
  },
  {
    field: 'ItemID',
    headerName: 'Item ID',
    flex: 1,
    hide: true
  },
  {
    field: 'ItemName',
    headerName: 'Item',
    flex: 1.5
  },
  {
    field: 'Quantity',
    headerName: 'Quantity',
    flex: 1,
    type: 'number'
  },
  {
    field: 'Amount',
    headerName: 'Amount',
    flex: 1,
    type: 'number'
  },
  {
    field: 'Rate',
    headerName: 'Rate',
    flex: 1,
    type: 'number'
  }
]

interface RequestContent {
  Date: string | Date
  // company
  Lines?: Array<{
    Account: { ID: string; FullName: string }
    Type: ConnectionLineType
    Memo: string
    Amount: number
  }>
  //reseller
  ItemLines?: Array<{
    Item: { ID: string; FullName: string }
    Rate: number
    Description: string
    Quantity: number
  }>
}

interface Props {
  exportId?: string
  onClose(): void
}

const ConnectionExportViewerModal: FC<Props> = ({ exportId, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchConnection = useConnectionDetails(connectionId)
  const fetchExport = useConnectionExport(connectionId, exportId)

  const columns = useMemo(() => {
    if (!fetchConnection.isSuccess) return []

    if (fetchConnection.data.entityType === ConnectionEntityType.Company)
      return companyTableColumns
    if (fetchConnection.data.entityType === ConnectionEntityType.Reseller)
      return resellerTableColumns

    return []
  }, [fetchConnection.data])

  return (
    <Modal open={!!exportId} title="Response" onClose={onClose}>
      <DataPreviewer
        query={fetchExport}
        tableColumns={columns}
        fileName={`Export`}
        getRows={(data: ConnectionExport) => {
          const response: RequestContent = data?.request

          if (fetchConnection.data?.entityType === ConnectionEntityType.Company) {
            return (
              response?.Lines?.map((line, index) => ({
                id: index,
                AccountName: line.Account.FullName,
                AccountID: line.Account.ID,
                Type: line.Type,
                Memo: line.Memo,
                Amount: line.Amount
              })) ?? []
            )
          }

          if (fetchConnection.data?.entityType === ConnectionEntityType.Reseller) {
            return (
              response?.ItemLines?.map((line, index) => ({
                id: index,
                ItemName: line.Item.FullName,
                ItemID: line.Item.ID,
                Rate: line.Rate,
                Description: line.Description,
                Quantity: line.Quantity
              })) ?? []
            )
          }

          return []
        }}
        header={
          <Typography gutterBottom>
            Date:{' '}
            {fetchExport.data?.createdAt &&
              formatDate(new Date(fetchExport.data.createdAt), 'Pp')}
          </Typography>
        }
      />
    </Modal>
  )
}

export default ConnectionExportViewerModal
