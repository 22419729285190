import { ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

interface AutofySettings {
  appId: string
  userId: string
  agentToken: string
  channel: 'qbo' | 'qbd'
}

const useAutofySettings = (
  token: string,
  enabled: boolean
): UseQueryResult<AutofySettings, ApiError> =>
  useQuery(
    ['autofy_settings', token],
    () =>
      axios
        .get<AutofySettings>(`/quickbooks/connections/invites/${token}`)
        .then(res => res.data),
    { enabled }
  )

export default useAutofySettings
