import { ApiError, Fulfillment, FulfillmentStatus } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  id: string
  data: {
    status: FulfillmentStatus
    note?: string
  }
}

const useUpdateFulfillment = (): UseMutationResult<
  Fulfillment,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ id, data }) =>
      axios.patch<Fulfillment>(`/fulfillments/${id}`, data).then(res => res.data),
    {
      mutationKey: 'update fulfillment',
      onSuccess: res => {
        queryClient.setQueriesData<Fulfillment[] | undefined>(
          { queryKey: ['fulfillments'], active: true },
          data => data?.map(d => (d.id === res.id ? { ...d, ...res } : d))
        )
      },
      onError: () => {
        enqueueSnackbar('Failed to update', { variant: 'error' })
      }
    }
  )
}

export default useUpdateFulfillment
