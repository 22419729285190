import { ApiError, ConnectionPreferences, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Partial<ConnectionPreferences>

const useUpdateConnectionPreferences = (
  connectionId: string
): UseMutationResult<ConnectionPreferences, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async data =>
      axios
        .patch<ConnectionPreferences>(
          `/quickbooks/connections/${connectionId}/preferences`,
          data
        )
        .then(res => res.data),
    {
      mutationKey: 'update connection preference',
      onSuccess: () => {
        queryClient.invalidateQueries([
          'quickbooks',
          'connections',
          connectionId,
          'details'
        ])
        enqueueSnackbar('Preferences updated', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update preferences', { variant: 'error' })
      }
    }
  )
}

export default useUpdateConnectionPreferences
