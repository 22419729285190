import { useCurrentUser } from '@api/queries'
import { useMobileMode } from '@hooks'
import { Box, CssBaseline } from '@mui/material'
import { FC, useState } from 'react'
import Header from './Header'
import Loading from './Loading'
import Router from './Router'
import { DrawerHeader } from './Sidebar/components/DesktopSidebar'
import Sidebar from './Sidebar/Sidebar'

const Main: FC = () => {
  const fetchCurrentUser = useCurrentUser()
  const mobileMode = useMobileMode()

  const [sidebarOpen, setSidebarOpen] = useState(!mobileMode)

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline enableColorScheme />

      <Header open={sidebarOpen} setOpen={setSidebarOpen} />

      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1
        }}
      >
        <DrawerHeader />

        {fetchCurrentUser.isLoading ? (
          <Loading />
        ) : (
          <Router user={fetchCurrentUser.data} />
        )}
      </Box>
    </Box>
  )
}

export default Main
