import { Box, Typography } from '@mui/material'
import { FC, useState } from 'react'
import AccessCompaniesTable from './components/AccessCompaniesTable'
import CreateAccessCompanyModal from './components/CreateAccessCompanyModal'

const CompanyAccessCompaniesTab: FC = () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Companies
      </Typography>

      <AccessCompaniesTable openForm={() => setModalOpen(true)} />

      <CreateAccessCompanyModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  )
}

export default CompanyAccessCompaniesTab
