import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult } from 'react-query'

interface Payload {
  email: string
}

const useForgotPassword = (): UseMutationResult<any, ApiError, Payload> => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios.post<any>('/auth/forgot-password', payload).then(res => res.data),
    {
      mutationKey: 'forgot password',
      onSuccess: () => {
        enqueueSnackbar('Email sent with instructions', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to send email', { variant: 'error' })
      }
    }
  )
}
export default useForgotPassword
