import { useDeleteCompanyReport, useUpdateCompanyReport } from '@api/mutations'
import { AccessCompanyReport } from '@models'
import { confirmFirst } from '@utils'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import CompanyReportModal, { CompanyReportFormData } from './CompanyReportModal'

interface Props {
  open: boolean
  report?: AccessCompanyReport
  tags: string[]
  onClose(): void
}

const EditCompanyReportModal: FC<Props> = ({ open, report, tags, onClose }) => {
  const editCompanyReportForm = useForm<CompanyReportFormData>({
    defaultValues: new CompanyReportFormData(report)
  })

  const updateCompanyReportMut = useUpdateCompanyReport(report?.id ?? '')
  const deleteCompanyReportMut = useDeleteCompanyReport()

  useEffect(() => {
    if (open && report)
      editCompanyReportForm.reset(new CompanyReportFormData(report))
  }, [open, report])

  const onDelete = () =>
    confirmFirst('Would you like to delete this report?', () =>
      deleteCompanyReportMut.mutate(
        { reportId: report?.id ?? '' },
        { onSuccess: onClose }
      )
    )

  const onSubmit: SubmitHandler<CompanyReportFormData> = data => {
    updateCompanyReportMut.mutate(data, {
      onSuccess: onClose
    })
  }

  return (
    <CompanyReportModal
      open={open}
      form={editCompanyReportForm}
      tags={tags}
      title="Edit Report"
      onClose={onClose}
      onDelete={onDelete}
      onSubmit={onSubmit}
    />
  )
}

export default EditCompanyReportModal
