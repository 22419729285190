import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult } from 'react-query'

interface Payload {
  resetToken: string
  newPassword: string
}

const useResetPassword = (
  onSuccess: () => void
): UseMutationResult<void, ApiError, Payload> => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios.post<void>('/auth/reset-password', payload).then(res => res.data),
    {
      mutationKey: 'forgot password',
      onSuccess: () => {
        enqueueSnackbar('Password reset', { variant: 'success' })
        onSuccess()
      },
      onError: () => {
        enqueueSnackbar('Failed to reset password', { variant: 'error' })
      }
    }
  )
}
export default useResetPassword
