import {
  ApiError,
  ConnectionItemLine,
  ConnectionMapping,
  QBConnection
} from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  lineId: string
}

const useDeleteConnectionMappingLine = (
  connectionId: string,
  mappingId: string
): UseMutationResult<ConnectionItemLine, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ lineId }) =>
      axios
        .delete<ConnectionItemLine>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}/lines/${lineId}`
        )
        .then(res => res.data),
    {
      mutationKey: 'delete connection mapping line',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionMapping | undefined>(
          ['quickbooks', 'connections', connectionId, 'mappings', mappingId],
          data =>
            data && { ...data, lines: data.lines.filter(l => l._id !== res._id) }
        )
        enqueueSnackbar('Line deleted', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to delete line', { variant: 'error' })
      }
    }
  )
}

export default useDeleteConnectionMappingLine
