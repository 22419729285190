import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement
} from 'react'
import { SubmitHandler, UseFormProps, UseFormReturn } from 'react-hook-form'
import { useIsMutating } from 'react-query'

interface Props<FormValues> {
  form: UseFormReturn<FormValues>
  defaultValues?: UseFormProps<FormValues>['defaultValues']
  isLoading?: boolean
  open: boolean
  title: string
  secondaryActions?: Array<ButtonProps>
  onClose(): void
  onSubmit: SubmitHandler<FormValues>
}

interface Default {
  [key: string]: any
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  ['& .MuiDialog-paper']: {
    maxWidth: 750
  },
  ['& .MuiDialogTitle-root']: {
    padding: theme.spacing(2, 3.5, 1)
  },
  ['& .MuiDialogContent-root']: {
    padding: theme.spacing(2, 6)
  },
  ['& .MuiDialogActions-root']: {
    padding: theme.spacing(0, 2, 1)
  }
}))

const FormDialog = <FormValues extends Default>({
  form: { control, handleSubmit, reset: resetForm, getValues, watch },
  children,
  isLoading = false,
  open,
  title,
  secondaryActions,
  onSubmit,
  onClose
}: PropsWithChildren<Props<FormValues>>): ReactElement => {
  const isMutating = useIsMutating()

  const loading = isMutating > 0 || isLoading

  const closeDialog = (clear: boolean) => {
    if (clear) resetForm()
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={() => closeDialog(false)}
      fullWidth
      aria-labelledby="form-dialog-title"
      data-testid="form-dialog"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{ fontSize: 'h2.fontSize', textTransform: 'capitalize' }}
      >
        {title}

        <IconButton
          aria-label="close"
          onClick={() => closeDialog(true)}
          title="Exit modal"
          sx={{
            position: 'absolute',
            top: 18,
            right: 18,
            color: theme => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <Divider variant="middle" />

      <DialogContent>
        <form id="dialog-form" onSubmit={handleSubmit(onSubmit)}>
          {Children.map(children, child =>
            isValidElement(child)
              ? cloneElement(child, {
                  control,
                  getValues,
                  watch
                })
              : child
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <div>
          {secondaryActions &&
            secondaryActions.map(action => (
              <LoadingButton
                key={action.title}
                loading={loading}
                variant="text"
                {...action}
              >
                {action.title}
              </LoadingButton>
            ))}

          <LoadingButton
            loading={loading}
            color="secondary"
            variant="text"
            title="Reset"
            onClick={() => resetForm()}
          >
            Reset
          </LoadingButton>
        </div>

        <LoadingButton
          loading={loading}
          color="primary"
          type="submit"
          form="dialog-form"
          title="Submit"
          variant="text"
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default FormDialog
