import { InputProps } from '@models'
import { TextField, TextFieldProps } from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'

interface Props extends InputProps {
  fullWidth?: boolean
  autocomplete?: string
  required?: boolean
  type?: TextFieldProps['type']
}

const Input: FC<Props> = ({
  control,
  name,
  autocomplete,
  defaultValue = '',
  label,
  type = 'text',
  variant,
  fullWidth = true,
  required = true
}) => {
  const {
    field: { ref, ...field },
    fieldState
  } = useController({
    control,
    name,
    defaultValue,
    rules: { required: required && 'Field required' }
  })

  const getLabel = () => {
    if (label) return label
    else {
      const text = name.replace(/([A-Z])/g, ' $1')

      return text.charAt(0).toUpperCase() + text.slice(1)
    }
  }

  return (
    <TextField
      {...field}
      autoComplete={autocomplete || name}
      value={field.value || ''}
      fullWidth={fullWidth}
      id={name}
      label={getLabel()}
      error={!!fieldState.error}
      helperText={fieldState.error?.message || ' '}
      inputRef={ref}
      type={type}
      variant={variant}
      inputProps={{ step: '0.1' }}
    />
  )
}

export default Input
