import { Checkbox } from '@common/inputs/form'
import { InputProps } from '@models'
import { Divider, FormGroup, FormLabel, Stack } from '@mui/material'
import { FC } from 'react'

const CompanyReportParamsChecklist: FC<InputProps> = ({ control }) => {
  return (
    <FormGroup>
      <FormLabel>Parameters</FormLabel>
      <Stack mx={7} mt={1} divider={<Divider light />}>
        <Checkbox label="Date Range" name="parameters.dateRange" control={control} />

        <Checkbox label="Payroll ID" name="parameters.payrollId" control={control} />
      </Stack>
    </FormGroup>
  )
}

export default CompanyReportParamsChecklist
