import { useSendAutofyConnection } from '@api/mutations'
import { ErrorMessage } from '@common'
import { ContentCopy } from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Typography
} from '@mui/material'
import { FC } from 'react'
import { AutofySettings } from '../QBInstructions'

interface Props {
  settings: AutofySettings
  token: string | null
}

const QBDesktopInstructions: FC<Props> = ({ settings, token }) => {
  const sendConnectionMut = useSendAutofyConnection(token ?? '', 'qbd')

  return (
    <Box pt={3} pb={3}>
      <Typography variant="subtitle1" gutterBottom>
        Click the button below to connect.
      </Typography>

      <Button
        sx={{ mt: 1 }}
        variant="contained"
        color="success"
        title="Connect"
        onClick={() => sendConnectionMut.mutate()}
      >
        Connect
      </Button>

      <Box pt={4}>
        <MuiLink href="https://rightlabs.s3.amazonaws.com/AgentInstallers/Branded/Cloud9/21.0.0922.331_Cloud9SyncManager.exe">
          Download Cloud9 Sync Agent
        </MuiLink>

        <br />

        <TextField
          sx={{ mt: 3, width: 300 }}
          size="small"
          variant="outlined"
          id="agent-token"
          value={settings.agentToken}
          label="Agent Token"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  title="Copy"
                  disabled={!settings.agentToken}
                  onClick={() => navigator.clipboard.writeText(settings.agentToken)}
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            ),
            readOnly: true
          }}
        />
      </Box>

      <Box pt={3}>
        {sendConnectionMut.isLoading && (
          <Typography variant="h5">
            Waiting for response from QuickBooks...
          </Typography>
        )}
        {sendConnectionMut.isError && <ErrorMessage message="Failed to connect" />}
      </Box>
    </Box>
  )
}

export default QBDesktopInstructions
