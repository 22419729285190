import { ApiError, DateRangeType, InvoicedPayroll } from '@models'
import { formatDate } from '@utils'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const usePayrollsInvoiced = (
  dates: DateRangeType
): UseQueryResult<InvoicedPayroll[], ApiError> => {
  const startDate = formatDate(dates.startDate)
  const endDate = formatDate(dates.endDate)

  return useQuery(['dashboards', 'payrolls-invoiced', startDate, endDate], () =>
    axios
      .get<InvoicedPayroll[]>(
        `/dashboards/payrolls-invoiced?startDate=${startDate}&endDate=${endDate}`
      )
      .then(res => res.data)
  )
}
export default usePayrollsInvoiced
