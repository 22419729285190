import { ApiError, Payroll } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const usePayrolls = (
  companyId: string | undefined,
  enabled?: boolean
): UseQueryResult<Payroll[], ApiError> =>
  useQuery(
    ['payrolls', companyId],
    () => axios.get<Payroll[]>(`/payrolls/${companyId}`).then(res => res.data),
    {
      select: data => data.sort(p => (p.companyPayrollId[7] === '9' ? -1 : 0)),
      enabled: !!companyId && enabled
    }
  )

export default usePayrolls
