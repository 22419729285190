import { FormDialog } from '@common'
import { TextArea } from '@common/inputs/form'
import { Fulfillment } from '@models'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

interface AddToForm {
  note: string
}

interface Props {
  title: string
  fulfillment?: Fulfillment
  onSubmit: SubmitHandler<AddToForm>
  onClose(): void
}

const EditFulfillmentModal: FC<Props> = ({
  title,
  fulfillment,
  onSubmit,
  onClose
}) => {
  const updateFulfillmentForm = useForm<AddToForm>({
    defaultValues: { note: '' }
  })

  useEffect(() => {
    if (fulfillment) updateFulfillmentForm.reset({ note: fulfillment.note })
  }, [fulfillment])

  return (
    <FormDialog
      form={updateFulfillmentForm}
      open={!!fulfillment}
      title={title}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <TextArea label="Add a note" name="note" variant="outlined" required={false} />
    </FormDialog>
  )
}

export default EditFulfillmentModal
