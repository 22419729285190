import { useDuplicateConnectionMapping } from '@api/mutations'
import {
  useCompanies,
  useConnectionDetails,
  useConnectionMappings
} from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete } from '@common/inputs/form'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export interface DuplicateMappingForm {
  mappingId: string
  companyId: string
}

interface Props {
  mappingId?: string
  onClose(): void
}

const DuplicateConnectionMappingModal: FC<Props> = ({ mappingId, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchMappings = useConnectionMappings(connectionId)
  const fetchConnectionDetails = useConnectionDetails(connectionId)

  const fetchCompanies = useCompanies(
    !!mappingId && fetchMappings.isSuccess && fetchConnectionDetails.isSuccess,
    data => {
      const usedOptions = fetchMappings.data?.map(c => c.companyId) ?? []

      return data.filter(
        d =>
          !usedOptions.includes(d.id) &&
          d.resellerId === fetchConnectionDetails.data?.companyId
      )
    }
  )

  const duplicateMappingMut = useDuplicateConnectionMapping(connectionId)

  const defaultValues = {
    mappingId,
    companyId: ''
  }

  const duplicateMappingForm = useForm<DuplicateMappingForm>({
    defaultValues
  })

  useEffect(() => {
    if (mappingId) duplicateMappingForm.reset({ ...defaultValues })
  }, [mappingId])

  const onSubmit: SubmitHandler<DuplicateMappingForm> = data => {
    const company = fetchCompanies.data?.find(comp => comp.id === data.companyId)

    duplicateMappingMut.mutate(
      { ...data, companyName: company?.name ?? '' },
      { onSuccess: onClose }
    )
  }

  return (
    <FormDialog<DuplicateMappingForm>
      form={duplicateMappingForm}
      open={!!mappingId}
      title="Duplicate Mapping"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Autocomplete
        label="Mapping"
        name="mappingId"
        idKey="_id"
        optionLabelKey="companyName"
        query={fetchMappings}
      />

      <Autocomplete
        label="Company"
        name="companyId"
        optionLabelKey="name"
        query={fetchCompanies}
      />
    </FormDialog>
  )
}

export default DuplicateConnectionMappingModal
