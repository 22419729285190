import { useAccessCompany } from '@api/queries'
import { Breadcrumb, TabsPanel } from '@common'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import AccessCompanyUsersTab from './tabs/users/AccessCompanyUsersTab'

interface UrlProps {
  companyId: string | undefined
}

const AccessCompany: FC = () => {
  const { companyId } = useParams<UrlProps>()
  const fetchAccessCompany = useAccessCompany(companyId)

  const breadcrumbs = useMemo(
    () => [
      { label: 'Access', path: '/access' },
      { label: 'Companies', path: '/access/companies' },
      { label: fetchAccessCompany.data?.companyName ?? '...' }
    ],
    [fetchAccessCompany.data]
  )

  return (
    <>
      <Breadcrumb crumbs={breadcrumbs} />

      <TabsPanel tabs={[{ name: 'users', component: AccessCompanyUsersTab }]} />
    </>
  )
}

export default AccessCompany
