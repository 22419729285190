import { ApiError, ConnectionDetails } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionDetails = (
  connectionId?: string
): UseQueryResult<ConnectionDetails, ApiError> => {
  return useQuery(['quickbooks', 'connections', connectionId, 'details'], () =>
    axios
      .get<ConnectionDetails>(`/quickbooks/connections/${connectionId}/details`)
      .then(res => res.data)
  )
}
export default useConnectionDetails
