import { InputProps } from '@models'
import {
  Autocomplete as MatAutocomplete,
  AutocompleteProps as MatAutocompleteProps,
  TextField
} from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'
import { UseQueryResult } from 'react-query'

interface Data {
  id?: string | number
  [key: string]: any
}

interface AutocompleteProps extends InputProps {
  query: UseQueryResult<Data[]>
  label: string
  idKey?: string
  optionLabelKey?: string
  sort?: { key: string; direction: 'asc' | 'desc' }
  required?: boolean
  getOptionLabel?: MatAutocompleteProps<Data, false, false, false>['getOptionLabel']
  renderOption?: MatAutocompleteProps<Data, false, false, false>['renderOption']
}

const Autocomplete: FC<AutocompleteProps> = ({
  control,
  label,
  name,
  idKey = 'id',
  optionLabelKey = 'id',
  query,
  sort = { key: '', direction: 'asc' },
  variant,
  required = true,
  getOptionLabel,
  renderOption
}) => {
  const {
    field: { ref, ...field },
    fieldState
  } = useController<any>({
    control,
    name,
    defaultValue: null,
    rules: { required: { value: required, message: 'Field required' } }
  })

  const error = query.isError
    ? 'Failed to fetch options'
    : !!fieldState.error && 'Field required'

  const sortOptions = (a: Data, b: Data) => {
    const key = sort.key || optionLabelKey

    return sort.direction === 'asc'
      ? a[key] > b[key]
        ? 1
        : -1
      : a[key] > b[key]
      ? -1
      : 1
  }

  return (
    <MatAutocomplete
      openOnFocus
      autoComplete
      autoHighlight
      fullWidth
      loading={query.isLoading}
      disabled={!query.isSuccess || query.isLoading}
      options={query.data?.sort(sortOptions) || []}
      getOptionLabel={getOptionLabel || (option => option[optionLabelKey])}
      isOptionEqualToValue={(option, value) =>
        option[idKey].toString() === value[idKey].toString()
      }
      onChange={(e, option) => field.onChange(option ? option[idKey] : '')}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error || ' '}
          inputRef={ref}
          variant={variant}
        />
      )}
      renderOption={renderOption}
      value={query.data?.find(opt => opt[idKey] === field.value) || null}
    />
  )
}

export default Autocomplete
