import { useCreateConnectionMapping } from '@api/mutations'
import {
  useCompanies,
  useConnectionDetails,
  useConnectionMappings
} from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete } from '@common/inputs/form'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export interface ConnectionMappingForm {
  companyId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateConnectionMappingModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchMappings = useConnectionMappings(connectionId)
  const fetchCompanyDetails = useConnectionDetails(connectionId)

  const fetchCompanies = useCompanies(
    open && fetchMappings.isSuccess && fetchCompanyDetails.isSuccess,
    data => {
      const usedOptions = fetchMappings.data?.map(c => c.companyId) ?? []

      return data.filter(
        d =>
          !usedOptions.includes(d.id) &&
          d.resellerId === fetchCompanyDetails.data?.companyId
      )
    }
  )

  const createMappingMut = useCreateConnectionMapping(connectionId)

  const createMappingForm = useForm<ConnectionMappingForm>({
    defaultValues: { companyId: '' }
  })

  const onSubmit: SubmitHandler<ConnectionMappingForm> = data => {
    const company = fetchCompanies.data?.find(comp => comp.id === data.companyId)

    createMappingMut.mutate(
      { ...data, companyName: company?.name ?? '' },
      { onSuccess: onClose }
    )
  }

  return (
    <FormDialog<ConnectionMappingForm>
      form={createMappingForm}
      open={open}
      title="Create Mapping"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Autocomplete
        label="Company"
        name="companyId"
        optionLabelKey="name"
        query={fetchCompanies}
      />
    </FormDialog>
  )
}

export default CreateConnectionMappingModal
