import { ApiError, ConnectionClass, ConnectionDetails } from '@models'
import axios from 'axios'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

const useConnectionClasses = (
  connectionId: string | undefined,
  enabled?: boolean,
  includeAll?: boolean
): UseQueryResult<ConnectionClass[], ApiError> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['quickbooks', 'connections', connectionId, 'classes'],
    () =>
      axios
        .get<ConnectionClass[]>(`/quickbooks/connections/${connectionId}/classes`)
        .then(res => res.data),
    {
      enabled: !!connectionId && enabled,
      select: data => {
        const connection = queryClient.getQueryData<ConnectionDetails>([
          'quickbooks',
          'connections',
          connectionId,
          'details'
        ])
        const preferred = connection?.preferences.classes

        if (includeAll || !preferred) return data
        return data.filter(d => preferred.includes(d.classId))
      }
    }
  )
}
export default useConnectionClasses
