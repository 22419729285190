import { FileUploader, Input } from '@common/inputs/form'
import { InputProps, Report, ReportQuery } from '@models'
import { Add, ArrowDownward, ArrowUpward, Remove } from '@mui/icons-material'
import { Box, FormGroup, FormLabel, IconButton, Stack } from '@mui/material'
import { FC } from 'react'
import { useFieldArray } from 'react-hook-form'

const CompanyReportQueryInputs: FC<InputProps> = ({ control }) => {
  const { fields, append, swap, remove } = useFieldArray<Report, 'queries'>({
    control,
    name: 'queries'
  })

  const addQuery = () => append({ label: '', query: '' })

  const removeQuery = (index: number) => remove(index)

  const moveQuery = (direction: 'up' | 'down', index: number) =>
    swap(index, direction === 'up' ? index - 1 : index + 1)

  return (
    <FormGroup>
      <FormLabel>
        Queries{' '}
        <IconButton edge="end" size="small" title="Add query" onClick={addQuery}>
          <Add />
        </IconButton>
      </FormLabel>

      <Stack
        sx={{
          mt: 1,
          px: 6,
          ['& .query-input-box']: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }
        }}
      >
        {fields.map((field: ReportQuery, index: number) => (
          <Box key={field.id} className="query-input-box">
            <Input
              label="Label"
              name={`queries.${index}.label`}
              control={control}
              defaultValue={field.label}
              fullWidth={false}
            />

            <FileUploader
              label="Query"
              name={`queries.${index}.query`}
              control={control}
              defaultValue={field.query}
            />

            <div>
              <IconButton
                title="Move up"
                disabled={index === 0}
                onClick={() => moveQuery('up', index)}
                size="large"
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                title="Move down"
                disabled={index === fields.length - 1}
                onClick={() => moveQuery('down', index)}
                size="large"
              >
                <ArrowDownward />
              </IconButton>
              <IconButton
                title="Remove query"
                disabled={fields.length === 1}
                onClick={() => removeQuery(index)}
                color="error"
                size="large"
              >
                <Remove />
              </IconButton>
            </div>
          </Box>
        ))}
      </Stack>
    </FormGroup>
  )
}

export default CompanyReportQueryInputs
