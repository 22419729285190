import { Select } from '@common/inputs/form'
import { InputProps } from '@models'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, FormGroup, FormLabel, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'

const QuarterInput: FC<InputProps> = ({ control }) => {
  const { field: yearField } = useController({
    control,
    name: 'year',
    // defaultValue: new Date(),
    rules: { required: 'Field required' }
  })

  return (
    <Box my={2}>
      <FormLabel>Quarter</FormLabel>

      <FormGroup
        row
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
          px: 2,
          ['& > *']: {
            flex: 1
          }
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...yearField}
            views={['year']}
            label="Year"
            renderInput={params => <TextField id="year-picker" {...params} />}
          />
        </LocalizationProvider>

        <Typography variant="h4" textAlign="center">
          -
        </Typography>

        <Select
          control={control}
          label="Quarter"
          name="quarter"
          fullWidth={false}
          options={[
            { label: 'Q1', value: 1 },
            { label: 'Q2', value: 2 },
            { label: 'Q3', value: 3 },
            { label: 'Q4', value: 4 }
          ]}
        />
      </FormGroup>
    </Box>
  )
}

export default QuarterInput
