import { Box, Typography } from '@mui/material'
import { FC, useState } from 'react'
import CompanyUsersTable from './components/CompanyUsersTable'
import CreateCompanyUserModal from './components/CreateCompanyUserModal'

const AccessCompanyUsersTab: FC = () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Users
      </Typography>

      <CompanyUsersTable openForm={() => setModalOpen(true)} />

      <CreateCompanyUserModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </Box>
  )
}

export default AccessCompanyUsersTab
