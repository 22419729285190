import { useSendAutofyConnection } from '@api/mutations'
import { ErrorMessage } from '@common'
import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { AutofySettings } from '../QBInstructions'

interface Props {
  settings: AutofySettings
  token: string | null
}

const QBOnlineInstructions: FC<Props> = ({ settings, token }) => {
  const sendConnectionMut = useSendAutofyConnection(token ?? '', 'qbo')

  window.addEventListener('message', event => {
    if (event.origin === 'https://api.propelware.com') {
      const data = JSON.parse(event.data)
      const endpointId: string = data.company.endpointId

      if (endpointId) sendConnectionMut.mutate({ endpointId })
    }
  })

  return (
    <Box pt={4} pb={2}>
      <Typography variant="subtitle1" gutterBottom>
        Follow the button below to connect.
      </Typography>

      <iframe
        id="af_frame"
        title="QB Button"
        height="54"
        width="120"
        frameBorder="0"
        src={`https://api.propelware.com/channel/connect?appId=${settings.appId}&shortCode=qbo&userId=${settings.userId}`}
      />

      <Box pt={3}>
        {sendConnectionMut.isLoading && (
          <Typography variant="h5">
            Waiting for response from QuickBooks...
          </Typography>
        )}
        {sendConnectionMut.isError && <ErrorMessage message="Failed to connect" />}
      </Box>
    </Box>
  )
}

export default QBOnlineInstructions
