import { useCreateConnectionExport } from '@api/mutations'
import {
  useCompanies,
  useConnectionDetails,
  useConnectionMappings,
  usePayrolls
} from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete } from '@common/inputs/form'
import { ConnectionEntityType } from '@models'
import { Box, Typography } from '@mui/material'
import { formatDate } from '@utils'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export interface ConnectionExportForm {
  companyId?: string
  payrollId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateConnectionExportModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchConnectionDetails = useConnectionDetails(connectionId)
  const fetchConnectionMappings = useConnectionMappings(connectionId)

  const createExportForm = useForm<ConnectionExportForm>({
    defaultValues: { payrollId: '' }
  })
  const watchCompanyId = createExportForm.watch('companyId')

  const companyId = fetchConnectionDetails.isSuccess
    ? fetchConnectionDetails.data.entityType === ConnectionEntityType.Company
      ? fetchConnectionDetails.data.companyId
      : watchCompanyId
    : undefined

  const fetchCompanies = useCompanies(
    open &&
      fetchConnectionDetails.data?.entityType === ConnectionEntityType.Reseller,
    data =>
      data.filter(
        c =>
          fetchConnectionMappings.data?.length &&
          fetchConnectionMappings.data.some(m => m.companyId === c.id)
      )
  )
  const fetchPayrolls = usePayrolls(
    companyId,
    open && fetchConnectionDetails.isSuccess
  )

  const createExportMut = useCreateConnectionExport(connectionId)

  const onSubmit: SubmitHandler<ConnectionExportForm> = data => {
    if (fetchConnectionDetails.data?.entityType === ConnectionEntityType.Company) {
      createExportMut.mutate({ payrollId: data.payrollId }, { onSuccess: onClose })
    } else if (
      fetchConnectionDetails.data?.entityType === ConnectionEntityType.Reseller
    ) {
      const mapping = fetchConnectionMappings.data?.find(
        m => m.companyId === data.companyId
      )

      if (mapping)
        createExportMut.mutate(
          {
            payrollId: data.payrollId,
            mappingId: mapping._id
          },
          { onSuccess: onClose }
        )
    }
  }

  return (
    <FormDialog<ConnectionExportForm>
      form={createExportForm}
      open={open}
      title="Export"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      {fetchConnectionDetails.data?.entityType === ConnectionEntityType.Reseller && (
        <Autocomplete
          label="Company"
          name="companyId"
          optionLabelKey="name"
          query={fetchCompanies}
        />
      )}

      <Autocomplete
        label="Payroll"
        name="payrollId"
        optionLabelKey="name"
        query={fetchPayrolls}
        getOptionLabel={option =>
          `${option.companyPayrollId} - ${formatDate(
            new Date(option.checkDate),
            'PP'
          )}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography variant="body1" mr={1}>
              {option.companyPayrollId}
            </Typography>
            -
            <Typography variant="body2" ml={1}>
              {formatDate(new Date(option.checkDate), 'P')}
            </Typography>
          </Box>
        )}
      />
    </FormDialog>
  )
}

export default CreateConnectionExportModal
