import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

export interface DownloadResponse {
  label: string
  results: any[]
}

interface Payload {
  startDate?: Date | string
  endDate?: Date | string
  quarter?: number
  year?: Date | number
  resellerId?: string
  taxPayerEntityId?: string
  companyId?: string
  payrollId?: string
}

const useDownloadReport = (
  reportId: string
): UseMutationResult<DownloadResponse[], ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<DownloadResponse[]>(`/reports/${reportId}`, payload)
        .then(res => res.data),
    {
      mutationKey: 'download report',
      onSuccess: () => {
        queryClient.invalidateQueries(['reports'])
      },
      onError: () => {
        enqueueSnackbar('Failed to download', { variant: 'error' })
      }
    }
  )
}

export default useDownloadReport
