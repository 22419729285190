import { useAutofySettings } from '@api/queries'
import { useDarkMode, useQueryParams } from '@hooks'
import { Add } from '@mui/icons-material'
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Typography
} from '@mui/material'
import { FC, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import QBDesktopInstructions from './components/QBDesktopInstructions'
import QBOnlineInstructions from './components/QBOnlineInstructions'

export interface AutofySettings {
  appId: string
  userId: string
  agentToken: string
  channel: 'qbo' | 'qbd'
}

const QBInstructions: FC = () => {
  const { toggle: toggleDarkMode, isDarkMode } = useDarkMode(false)
  const query = useQueryParams()
  const token = query.get('token')

  const fetchAutofySettings = useAutofySettings(token ?? '', !!token)

  useEffect(() => {
    if (isDarkMode) toggleDarkMode()
  }, [])

  return (
    <Container maxWidth="md" disableGutters>
      <Paper
        sx={{
          mt: 15
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            py: 3
          }}
        >
          <img
            src={process.env.PUBLIC_URL + '/Cloud9HR_logo.png'}
            alt="Cloud9HR Logo"
            width="300"
            height="80"
          />

          <Add fontSize="large" />

          <img
            src={process.env.PUBLIC_URL + '/QuickBooks_logo.png'}
            alt="QuickBooks Logo"
            width="300"
            height="80"
          />
        </Box>

        <Divider />

        <Box
          sx={{
            mt: 4,
            px: 4,
            textAlign: 'center'
          }}
        >
          <Typography variant="h1" gutterBottom>
            {fetchAutofySettings.data?.channel === 'qbo' && 'QuickBooks Online'}
            {fetchAutofySettings.data?.channel === 'qbd' && 'QuickBooks Desktop'}
          </Typography>

          {fetchAutofySettings.data?.channel === 'qbo' && (
            <QBOnlineInstructions
              settings={fetchAutofySettings.data}
              token={token}
            />
          )}
          {fetchAutofySettings.data?.channel === 'qbd' && (
            <QBDesktopInstructions
              settings={fetchAutofySettings.data}
              token={token}
            />
          )}
        </Box>
      </Paper>

      <Backdrop open={fetchAutofySettings.isLoading}>
        <CircularProgress />
      </Backdrop>

      {(!token || fetchAutofySettings.isError) && <Redirect to="/auth/sign-in" />}
    </Container>
  )
}

export default QBInstructions
