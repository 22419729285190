import { useUpdateConnectionMappingIds } from '@api/mutations'
import {
  useConnectionClasses,
  useConnectionCustomers,
  useConnectionMapping
} from '@api/queries'
import { Autocomplete } from '@common/inputs/basic'
import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

interface UrlProps {
  connectionId: string
  mappingId: string
}

const MappingItemsHeader: FC = () => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchClasses = useConnectionClasses(connectionId)
  const fetchCustomers = useConnectionCustomers(connectionId)

  const updateMappingIdsMut = useUpdateConnectionMappingIds(connectionId, mappingId)

  const updateId = (updateData: { classId?: string; customerId?: string }) => {
    if (updateData.classId) {
      const selectedClass = fetchClasses.data?.find(
        c => c.classId === updateData.classId
      )
      updateMappingIdsMut.mutate({
        classId: updateData.classId,
        className: selectedClass?.fullName ?? ''
      })
    } else if (updateData.customerId) {
      const selectedCustomer = fetchCustomers.data?.find(
        c => c.customerId === updateData.customerId
      )
      updateMappingIdsMut.mutate({
        customerId: updateData.customerId,
        customerName: selectedCustomer?.fullName ?? ''
      })
    }
  }

  return (
    <Box
      data-testid="connection-mapping-header"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ['& .MuiAutocomplete-root']: {
          width: 150,
          mx: 2
        }
      }}
    >
      <Typography variant="h3" flexGrow={2} gutterBottom>
        Mapping
      </Typography>

      <Autocomplete
        value={fetchMapping.data?.classId ?? ''}
        label="Class"
        name="classId"
        query={fetchClasses}
        optionLabelKey="fullName"
        idKey="classId"
        fullWidth={false}
        noMargin
        variant="outlined"
        size="small"
        disableClearable
        onChange={val => updateId({ classId: val })}
      />
      <Autocomplete
        value={fetchMapping.data?.customerId ?? ''}
        label="Customer"
        name="customerId"
        query={fetchCustomers}
        optionLabelKey="fullName"
        idKey="customerId"
        fullWidth={false}
        noMargin
        variant="outlined"
        size="small"
        disableClearable
        onChange={val => updateId({ customerId: val })}
      />
    </Box>
  )
}

export default MappingItemsHeader
