import { useCreateGroup } from '@api/mutations'
import { useCompanies } from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, Input } from '@common/inputs/form'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

export interface GroupForm {
  name: string
  companyId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateGroupModal: FC<Props> = ({ open, onClose }) => {
  const createGroupForm = useForm<GroupForm>({
    defaultValues: { name: '', companyId: '' }
  })

  const fetchCompanies = useCompanies(open)
  const createGroupMut = useCreateGroup()

  return (
    <FormDialog<GroupForm>
      form={createGroupForm}
      open={open}
      title="Create Group"
      onClose={onClose}
      onSubmit={data => createGroupMut.mutate(data, { onSuccess: onClose })}
    >
      <Input label="Name" name="name" />
      <Autocomplete
        label="Company"
        name="companyId"
        optionLabelKey="name"
        query={fetchCompanies}
      />
    </FormDialog>
  )
}
export default CreateGroupModal
