import { useGroup } from '@api/queries'
import { Breadcrumb, TabsPanel } from '@common'
import { Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GroupMembersTab from './tabs/members/GroupMembersTab'
import GroupRightsTab from './tabs/rights/GroupRightsTab'

interface UrlProps {
  groupId: string
}

const GroupAccess: FC = () => {
  const { groupId } = useParams<UrlProps>()

  const fetchGroup = useGroup(groupId)

  const breadcrumbs = useMemo(
    () => [
      { label: 'Access', path: '/access' },
      { label: 'Groups', path: '/access/groups' },
      { label: fetchGroup.data?.name ?? '...' }
    ],
    [fetchGroup.data]
  )

  return (
    <div>
      <Breadcrumb crumbs={breadcrumbs} />

      <Typography variant="h1" gutterBottom>
        {fetchGroup.data?.name}
      </Typography>
      <Typography variant="h2" color="error">
        {fetchGroup.isError && 'Failed to fetch group'}
      </Typography>

      <TabsPanel
        tabs={[
          { name: 'members', component: GroupMembersTab },
          { name: 'rights', component: GroupRightsTab }
        ]}
      />
    </div>
  )
}

export default GroupAccess
