import { Backdrop, CircularProgress } from '@mui/material'
import { FC } from 'react'

const Loading: FC = () => {
  return (
    <Backdrop open sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default Loading
