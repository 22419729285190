import { useDeleteConnectionLine } from '@api/mutations'
import { useConnectionLines, useConnectionSelectorParams } from '@api/queries'
import { DataTable } from '@common/table'
import { ConnectionLine, ConnectionLineType } from '@models'
import { Delete, Edit } from '@mui/icons-material'
import { Button } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { confirmFirst } from '@utils'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  selectLine(line: ConnectionLine): void
  openCreateModal(): void
  openTestModal(): void
}

const ConnectionLinesTable: FC<Props> = ({
  selectLine,
  openCreateModal,
  openTestModal
}) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchLines = useConnectionLines(connectionId)
  const fetchSelectors = useConnectionSelectorParams(connectionId)

  const deleteLineMut = useDeleteConnectionLine(connectionId)

  const onDelete = (lineId: string) =>
    confirmFirst('Would you like to delete this line?', () =>
      deleteLineMut.mutate({ lineId: String(lineId) })
    )

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'accountName', headerName: 'Account', flex: 2 },
      {
        field: 'selectorType',
        headerName: 'Selector',
        flex: 1,
        type: 'singleSelect',
        valueOptions: fetchSelectors.data?.map(s => s.label),
        valueGetter: ({ row }) =>
          fetchSelectors.data?.find(s => s.type === row.selector.type)?.label
      },
      { field: 'memo', headerName: 'Memo', flex: 1.5 },
      {
        field: 'type',
        headerName: 'Type',
        flex: 0.5,
        type: 'singleSelect',
        valueOptions: [ConnectionLineType.Credit, ConnectionLineType.Debit],
        valueFormatter: ({ value }) =>
          (value === ConnectionLineType.Credit && 'Credit') ||
          (value === ConnectionLineType.Debit && 'Debit')
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={`edit-${row._id}`}
            icon={<Edit />}
            label="Edit Line"
            onClick={() => selectLine(row as ConnectionLine)}
            showInMenu
          />,
          <GridActionsCellItem
            key={`delete-${row._id}`}
            icon={<Delete />}
            label="Delete Line"
            onClick={() => onDelete(row._id ?? '')}
            showInMenu
          />
        ]
      }
    ],
    [fetchSelectors.data, fetchLines.status]
  )

  return (
    <DataTable
      columns={columns}
      errorMessage="Failed to fetch lines"
      height={625}
      idKey="_id"
      defaultSort="accountName"
      query={fetchLines}
      addNewRow={openCreateModal}
      customButton={
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ ml: 2 }}
          onClick={() => openTestModal()}
        >
          Test
        </Button>
      }
    />
  )
}

export default ConnectionLinesTable
