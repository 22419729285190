import { useConnectionAccounts } from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, Input, Select } from '@common/inputs/form'
import { ConnectionLine, ConnectionLineType } from '@models'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import ConnectionLineSelectorFields from '../ConnectionLineSelectorFields'

export type ConnectionLineFormData = Pick<
  ConnectionLine,
  'accountId' | 'accountName' | 'type' | 'memo' | 'selector'
>

interface Props {
  open: boolean
  form: UseFormReturn<ConnectionLineFormData>
  title: string
  onClose(): void
  onSubmit(data: ConnectionLineFormData): void
}

const ConnectionLineFormModal: FC<Props> = ({
  open,
  form,
  title,
  onClose,
  onSubmit
}) => {
  const { connectionId } = useParams<{ connectionId: string }>()
  const fetchAccounts = useConnectionAccounts(connectionId, open)

  const resetParameters = (params: string[]) => {
    form.setValue('selector.params', params)
  }

  return (
    <FormDialog<ConnectionLineFormData>
      form={form}
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Autocomplete
        label="Account"
        name="accountId"
        idKey="accountId"
        optionLabelKey="fullName"
        query={fetchAccounts}
      />

      <Select
        label="Type"
        name="type"
        options={[
          { label: 'Debit', value: ConnectionLineType.Debit },
          { label: 'Credit', value: ConnectionLineType.Credit }
        ]}
      />

      <Input label="Memo" name="memo" required={false} />

      <ConnectionLineSelectorFields
        name="selector"
        resetParameters={resetParameters}
      />
    </FormDialog>
  )
}

export default ConnectionLineFormModal
