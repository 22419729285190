import { Skeleton, Typography } from '@mui/material'
import { GridOverlay } from '@mui/x-data-grid'
import { FC } from 'react'

const TableOverlay: FC = ({ children }) => (
  <GridOverlay>
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {children}
    </div>
  </GridOverlay>
)

export const ErrorOverlay: FC<{ message: string }> = ({ message }) => (
  <TableOverlay>
    <Typography variant="h2" color="error">
      {message || 'Failed to fetch data'}
    </Typography>
  </TableOverlay>
)

export const LoadingOverlay: FC = () => (
  <GridOverlay>
    <Skeleton
      height="100%"
      width="100%"
      variant="rectangular"
      animation="wave"
      data-testid="data-table-skeleton"
    />
  </GridOverlay>
)

export const NoResultsOverlay: FC = () => (
  <TableOverlay>
    <Typography variant="h2">No results</Typography>
  </TableOverlay>
)
