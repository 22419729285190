import { ApiError, Group } from '@models'
import axios from 'axios'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

const useGroup = (groupId: string | undefined): UseQueryResult<Group, ApiError> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['groups', groupId],
    () => axios.get<Group>(`/groups/${groupId}`).then(res => res.data),
    {
      enabled: !!groupId,
      initialData: () =>
        queryClient.getQueryData<Group[]>(['groups'])?.find(g => g.id === groupId)
    }
  )
}
export default useGroup
