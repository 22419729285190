import { Breadcrumb } from '@common'
import { Typography } from '@mui/material'
import { FC } from 'react'
import DashboardsGrid from './components/DashboardsGrid'

const DashboardsPage: FC = () => (
  <>
    <Breadcrumb crumbs={[{ label: 'Dashboards' }]} />

    <Typography variant="h1" mb={2}>
      Dashboards
    </Typography>

    <DashboardsGrid />
  </>
)

export default DashboardsPage
