import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

export interface DownloadResponse {
  label: string
  results: any[]
}

interface Payload {
  startDate?: Date | string
  endDate?: Date | string
  payrollId?: string
}

const useDownloadCompanyReport = (
  reportId: string | undefined
): UseMutationResult<DownloadResponse[], ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<DownloadResponse[]>(`/company/reports/${reportId}/download`, payload)
        .then(res => res.data),
    {
      mutationKey: 'download company report',
      onSuccess: () => {
        queryClient.invalidateQueries(['reports'])
      },
      onError: () => {
        enqueueSnackbar('Failed to download', { variant: 'error' })
      }
    }
  )
}

export default useDownloadCompanyReport
