import { useDarkMode } from '@hooks'
import { FC } from 'react'
import ReactJson from 'react-json-view'

interface Props {
  data: any
}

const JsonPreview: FC<Props> = ({ data }) => {
  const { isDarkMode } = useDarkMode()

  return (
    <ReactJson
      name="Data"
      src={data}
      groupArraysAfterLength={10}
      displayDataTypes={false}
      enableClipboard={copy => {
        navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))
      }}
      collapsed={3}
      theme={isDarkMode ? 'ocean' : 'bright:inverted'}
    />
  )
}

export default JsonPreview
