import { useCreateConnectionExport } from '@api/mutations'
import { useConnectionMapping, usePayrolls } from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete } from '@common/inputs/form'
import { Box, Typography } from '@mui/material'
import { formatDate } from '@utils'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export interface MappingExportForm {
  companyId?: string
  payrollId: string
}

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateMappingExportModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchPayrolls = usePayrolls(
    fetchMapping.data?.companyId,
    open && fetchMapping.isSuccess
  )

  const createExportMut = useCreateConnectionExport(connectionId)

  const createExportForm = useForm<MappingExportForm>({
    defaultValues: { payrollId: '' }
  })

  const onSubmit: SubmitHandler<MappingExportForm> = data => {
    createExportMut.mutate(
      {
        payrollId: data.payrollId,
        mappingId: mappingId
      },
      { onSuccess: onClose }
    )
  }

  return (
    <FormDialog<MappingExportForm>
      form={createExportForm}
      open={open}
      title="Export"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Autocomplete
        label="Payroll"
        name="payrollId"
        optionLabelKey="name"
        query={fetchPayrolls}
        getOptionLabel={option =>
          `${option.companyPayrollId} - ${formatDate(
            new Date(option.checkDate),
            'PP'
          )}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography variant="body1" mr={1}>
              {option.companyPayrollId}
            </Typography>
            -
            <Typography variant="body2" ml={1}>
              {formatDate(new Date(option.checkDate), 'P')}
            </Typography>
          </Box>
        )}
      />
    </FormDialog>
  )
}

export default CreateMappingExportModal
