import { Breadcrumb } from '@common'
import { Typography } from '@mui/material'
import { FC, useState } from 'react'
import CreateUserModal from './components/CreateUserModal'
import UsersTable from './components/UsersTable'

const breadcrumbs = [{ label: 'Access', path: '/access' }, { label: 'Users' }]

const UsersAccess: FC = () => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Breadcrumb crumbs={breadcrumbs} />
      <Typography variant="h1" mb={1}>
        Users
      </Typography>

      <UsersTable openForm={() => setModalOpen(true)} />

      <CreateUserModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </>
  )
}

export default UsersAccess
