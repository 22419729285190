import { ApiError, User } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useAccessCompanyUsers = (
  companyId: string | undefined
): UseQueryResult<User[], ApiError> =>
  useQuery(
    ['access', 'companies', companyId, 'users'],
    () => axios.get(`/access/companies/${companyId}/users`).then(res => res.data),
    { enabled: !!companyId }
  )

export default useAccessCompanyUsers
