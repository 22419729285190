import { TabsPanel } from '@common'
import { DateRange } from '@common/inputs/basic'
import { DateRangeType } from '@models'
import { Box, Container, Typography } from '@mui/material'
import { nextFriday } from 'date-fns'
import { FC, useState } from 'react'
import DeliveriesTab from './tabs/deliveries/DeliveriesTab'
import MailedTab from './tabs/mailed/MailedTab'
import PickupsTab from './tabs/pickups/PickupsTab'
import UnfulfilledTab from './tabs/unfulfilled/UnfulfilledTab'

const defaultDates = {
  startDate: new Date(),
  endDate: nextFriday(new Date())
}

const Fulfillments: FC = () => {
  const [dates, setDates] = useState<DateRangeType>(defaultDates)

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mt: 2.5,
          mb: 1
        }}
      >
        <Typography variant="h1" flexGrow={1}>
          Fulfillments
        </Typography>

        <DateRange
          dates={dates}
          setDates={setDates}
          variant="outlined"
          size="small"
        />
      </Box>

      <TabsPanel
        tabs={[
          { name: 'unfulfilled', component: UnfulfilledTab },
          { name: 'deliveries', component: DeliveriesTab },
          { name: 'mailed', component: MailedTab },
          { name: 'pickups', component: PickupsTab }
        ]}
        tabProps={{ dates }}
      />
    </Container>
  )
}

export default Fulfillments
