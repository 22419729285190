import { useConnectionDetails } from '@api/queries'
import { ConnectionEntityType, ConnectionLine } from '@models'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import CompanyConnectionMapping from './components/CompanyConnectionMapping'
import ResellerConnectionMapping from './components/ResellerConnectionMapping'

export type Selected =
  | { line: undefined; type: 'none' }
  | { line: undefined; type: 'create' }
  | { line: ConnectionLine; type: 'edit' }

const ConnectionMappingTab: FC = () => {
  const { connectionId } = useParams<{ connectionId: string }>()
  const { data: connection } = useConnectionDetails(connectionId)

  return (
    <>
      {connection?.entityType === ConnectionEntityType.Company && (
        <CompanyConnectionMapping />
      )}

      {connection?.entityType === ConnectionEntityType.Reseller && (
        <ResellerConnectionMapping />
      )}
    </>
  )
}

export default ConnectionMappingTab
