export interface ReportDownload {
  date: Date
  user: string
  userName: string
}

export interface ReportOptions {
  inherit: {
    companyId: boolean
  }
  select: {
    dateRange: boolean
    quarterly: boolean
    resellerId: boolean
    taxPayerEntityId: boolean
    companyId: boolean
    payrollId: boolean
  }
}

export interface ReportQuery {
  id?: string
  label: string
  query: string
}

export default class Report {
  public id: string
  public name: string
  public tags: string[]
  public queries: ReportQuery[]
  public downloads: ReportDownload[]
  public options: ReportOptions

  constructor(report: Partial<Report>) {
    this.id = report.id || ''
    this.name = report.name || ''
    this.tags = report.tags || []
    this.queries = report.queries || []

    this.options = report.options || {
      inherit: {
        companyId: false
      },
      select: {
        dateRange: false,
        quarterly: false,
        resellerId: false,
        taxPayerEntityId: false,
        companyId: false,
        payrollId: false
      }
    }

    this.downloads = report.downloads?.length
      ? report.downloads
          .sort((a, b) => (a.date > b.date ? -1 : 1))
          .map(d => ({ ...d, date: new Date(d.date) }))
      : []
  }

  get lastDownload(): Date | undefined {
    if (this.downloads.length) {
      return this.downloads[0].date
    }
  }
}
