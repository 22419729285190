import { ApiError, TaxPayerEntity } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useTaxPayerEntities = (
  resellerId?: string,
  enabled?: boolean
): UseQueryResult<TaxPayerEntity[], ApiError> =>
  useQuery(
    ['tax-payer-entities', resellerId],
    () =>
      axios
        .get<TaxPayerEntity[]>(`/tax-payer-entities/${resellerId}`)
        .then(res => res.data),
    { enabled: !!resellerId && enabled }
  )

export default useTaxPayerEntities
