import { useGroups } from '@api/queries'
import { Link } from '@common'
import { DataTable } from '@common/table'
import { GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

interface Props {
  openForm(): void
}

const GroupsTable: FC<Props> = ({ openForm }) => {
  const { url } = useRouteMatch()

  const fetchGroups = useGroups()

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: ({ row: group }) => (
          <Link label={group.name} path={`${url}/${group.id}`} />
        )
      },
      {
        field: 'members',
        headerName: 'Members',
        flex: 1,
        valueGetter: ({ row: group }) => group.users.length
      },
      { field: 'companyId', headerName: 'Company', flex: 1 }
    ],
    [url, fetchGroups.status]
  )

  return (
    <DataTable
      query={fetchGroups}
      columns={columns}
      defaultSort="name"
      errorMessage="Failed to fetch groups"
      addNewRow={openForm}
    />
  )
}
export default GroupsTable
