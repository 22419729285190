import { useCreateConnectionLine } from '@api/mutations'
import { useConnectionAccounts } from '@api/queries'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import ConnectionLineFormModal, {
  ConnectionLineFormData
} from './ConnectionLineFormModal'

interface Props {
  open: boolean
  onClose(): void
}

const CreateConnectionLineModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchAccounts = useConnectionAccounts(connectionId, open)
  const createLineMut = useCreateConnectionLine(connectionId)

  const defaultValues = {
    accountId: '',
    accountName: '',
    type: undefined,
    memo: '',
    selector: {
      type: undefined,
      params: []
    }
  }

  const createConnectionLineForm = useForm<ConnectionLineFormData>({ defaultValues })

  const onSubmit: SubmitHandler<ConnectionLineFormData> = data => {
    const account = fetchAccounts.data?.find(acc => acc.accountId === data.accountId)
    createLineMut.mutate(
      { ...data, accountName: account?.fullName },
      { onSuccess: onClose }
    )
  }

  return (
    <ConnectionLineFormModal
      open={open}
      form={createConnectionLineForm}
      title="Create Line"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default CreateConnectionLineModal
