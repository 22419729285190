import { ApiError, ConnectionMapping } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  mappingId: string
  companyId: string
  companyName: string
}

const useDuplicateConnectionMapping = (
  connectionId: string
): UseMutationResult<ConnectionMapping, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ mappingId, ...payload }) =>
      axios
        .post<ConnectionMapping>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}/duplicate`,
          payload
        )
        .then(res => res.data),
    {
      mutationKey: 'duplicate connection mapping',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionMapping[] | undefined>(
          ['quickbooks', 'connections', connectionId, 'mappings'],
          data => data && [...data, res]
        )
        enqueueSnackbar(`Mapping created`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create mapping', { variant: 'error' })
      }
    }
  )
}
export default useDuplicateConnectionMapping
