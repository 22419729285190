import { InputProps } from '@models'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MatSelect
} from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'

interface Option {
  label: string
  value: string | number
}

interface SelectProps extends InputProps {
  allowEmpty?: boolean
  fullWidth?: boolean
  options: Option[]
}

const Select: FC<SelectProps> = ({
  control,
  name,
  allowEmpty = false,
  defaultValue = '',
  fullWidth = true,
  label,
  options,
  variant
}) => {
  const id = `${name}-select`

  const {
    field: { ref, ...field },
    fieldState
  } = useController({
    control,
    name,
    defaultValue,
    rules: { required: 'Field required' }
  })

  return (
    <FormControl variant={variant} fullWidth={fullWidth} error={!!fieldState.error}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>

      <MatSelect
        {...field}
        value={(options.length && field.value) || ''}
        labelId={`${id}-label`}
        id={id}
        label={label}
        error={!!fieldState.error}
        inputRef={ref}
        disabled={!options.length}
      >
        {allowEmpty && <MenuItem value="">N/A</MenuItem>}
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </MatSelect>
      <FormHelperText style={{ height: 22 }}>
        {fieldState.error?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default Select
