import { ApiError, QuerySelect, Reseller } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useResellers = (
  enabled?: boolean,
  select?: QuerySelect<Reseller[]>
): UseQueryResult<Reseller[], ApiError> =>
  useQuery(
    ['resellers'],
    () => axios.get<Reseller[]>(`/resellers`).then(res => res.data),
    { enabled, select }
  )

export default useResellers
