import { ApiError, ConnectionDetails, ConnectionItem } from '@models'
import axios from 'axios'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

const useConnectionItems = (
  connectionId: string | undefined,
  enabled?: boolean,
  includeAll?: boolean
): UseQueryResult<ConnectionItem[], ApiError> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['quickbooks', 'connections', connectionId, 'items'],
    () =>
      axios
        .get<ConnectionItem[]>(`/quickbooks/connections/${connectionId}/items`)
        .then(res => res.data),
    {
      enabled: !!connectionId && enabled,
      select: data => {
        const connection = queryClient.getQueryData<ConnectionDetails>([
          'quickbooks',
          'connections',
          connectionId,
          'details'
        ])
        const preferred = connection?.preferences.items

        if (includeAll || !preferred) return data
        return data.filter(d => preferred.includes(d.itemId))
      }
    }
  )
}
export default useConnectionItems
