import { FormDialog } from '@common'
import { Input, Multiselect } from '@common/inputs/form'
import { Report, ReportQuery } from '@models'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import OptionsChecklist from '../inputs/OptionsChecklist'
import QueryInputs from '../inputs/QueryInputs'

export class ReportFormData {
  name: string
  tags: string[]
  queries: ReportQuery[]
  options: Report['options']
  formSettings?: { companyId: boolean }

  constructor(data?: ReportFormData | Report) {
    this.name = data?.name || ''
    this.tags = data?.tags || []
    this.queries = data?.queries || [
      {
        label: '',
        query: ''
      }
    ]
    this.options = {
      inherit: {
        companyId: data?.options.inherit.companyId || false
      },
      select: {
        dateRange: data?.options.select.dateRange || false,
        quarterly: data?.options.select.quarterly || false,
        resellerId: data?.options.select.resellerId || false,
        taxPayerEntityId: data?.options.select.taxPayerEntityId || false,
        companyId: data?.options.select.companyId || false,
        payrollId: data?.options.select.payrollId || false
      }
    }
    this.formSettings = {
      companyId: Boolean(
        data?.options.inherit.companyId || data?.options.select.companyId
      )
    }
  }
}

interface Props {
  open: boolean
  form: UseFormReturn<ReportFormData>
  tags: string[]
  title: string
  onClose(): void
  onDelete?(): void
  onSubmit(data: ReportFormData): void
}

const ReportFormModal: FC<Props> = ({
  open,
  form,
  tags,
  title,
  onClose,
  onDelete,
  onSubmit
}) => (
  <FormDialog<ReportFormData>
    form={form}
    open={open}
    title={title}
    secondaryActions={
      onDelete && [{ title: 'Delete', color: 'error', onClick: onDelete }]
    }
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Input label="Name" name="name" />
    <Multiselect label="Tags" name="tags" options={tags} freeSolo />

    <QueryInputs name="queries" />

    <OptionsChecklist name="options" setValue={form.setValue} />
  </FormDialog>
)

export default ReportFormModal
