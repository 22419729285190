import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  payrollId: string
}

const useDeleteExport = (
  connectionId: string
): UseMutationResult<void, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ payrollId }) =>
      axios
        .delete<void>(`/quickbooks/exports/${connectionId}/${payrollId}`)
        .then(res => res.data),
    {
      mutationKey: 'delete export',
      onSuccess: () => {
        queryClient.invalidateQueries([
          'quickbooks',
          'exports',
          connectionId,
          'exports'
        ])
        enqueueSnackbar('Export deleted', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to delete export', { variant: 'error' })
      }
    }
  )
}

export default useDeleteExport
