import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useQuery, UseQueryResult } from 'react-query'

interface Response {
  invoiceAmount: number
  invoice: {
    Class: { ID: string; FullName: string }
    Customer: { ID: string; FullName: string }
    Date: string | Date
    ItemLines: Array<{
      Item: { ID: string; FullName: string }
      Quantity: number
      Rate: number
      Description: string
    }>
  }
}

const useConnectionTest = (
  connectionId: string | undefined,
  mappingId: string | undefined,
  payrollId: string | undefined
): UseQueryResult<Response, ApiError> => {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    [
      'quickbooks',
      'connections',
      connectionId,
      'mappings',
      mappingId,
      'test',
      payrollId
    ],
    () =>
      axios
        .get<Response>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}/test/${payrollId}`
        )
        .then(res => res.data),
    {
      enabled: Boolean(connectionId && mappingId && payrollId),
      onError: () => {
        enqueueSnackbar('Failed to run test', { variant: 'error' })
      }
    }
  )
}

export default useConnectionTest
