import { useUpdateGroup } from '@api/mutations'
import { useGroup, useReports } from '@api/queries'
import { DataTable } from '@common/table'
import { Box, Button, Switch } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { FC, useEffect, useMemo, useState } from 'react'
import { useIsFetching, useIsMutating } from 'react-query'
import { useParams } from 'react-router-dom'

const GroupReportsRights: FC = () => {
  const { groupId } = useParams<{ groupId: string }>()

  const fetchGroup = useGroup(groupId)
  const fetchReports = useReports()
  const updateGroupMut = useUpdateGroup(groupId)

  const isFetching = useIsFetching()
  const isMutating = useIsMutating()

  const [selected, setSelected] = useState(fetchGroup.data?.reports)

  useEffect(() => {
    if (fetchGroup.isSuccess && !selected) setSelected(fetchGroup.data.reports)
  }, [fetchGroup])

  const toggleReport = ({ row: report }: any) => {
    const newSelected = selected?.includes(report.id)
      ? selected.filter(selectedId => selectedId !== report.id)
      : [...(selected || []), report.id]

    setSelected(newSelected)
  }

  const isDisabled = (): boolean => {
    if (isFetching || isMutating || !fetchGroup.data || !selected) return true

    if (fetchGroup.data.reports.length !== selected.length) return false

    return selected.every(rep => fetchGroup.data.reports.includes(rep))
  }

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'name', headerName: 'Report Name', flex: 1 },
      {
        field: 'hasAccess',
        headerName: 'Has Access',
        flex: 1,
        type: 'boolean',
        renderCell: ({ row: report }) => (
          <Switch checked={selected?.includes(report.id)} color="primary" />
        ),
        valueGetter: ({ row: report }) => selected?.includes(report.id) || false
      }
    ],
    [selected, fetchReports.status]
  )

  return (
    <DataTable
      query={fetchReports}
      columns={columns}
      defaultSort="name"
      errorMessage="Failed to fetch reports"
      height={575}
      toolbar={{ column: false, exportable: true }}
      onRowClick={toggleReport}
      customButton={
        <Box display="inline" ml={4}>
          <Button
            variant="outlined"
            color="warning"
            size="small"
            onClick={() => setSelected(fetchGroup.data?.reports || selected)}
            sx={{ mr: 1 }}
            disabled={isDisabled()}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => updateGroupMut.mutate({ reports: selected })}
            disabled={isDisabled()}
          >
            Save
          </Button>
        </Box>
      }
    />
  )
}

export default GroupReportsRights
