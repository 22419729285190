import { useConnectionSelectorParams } from '@api/queries'
import { Select } from '@common/inputs/form'
import { InputProps } from '@models'
import { Box, Collapse, FormGroup, FormLabel } from '@mui/material'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ConnectionLineSelectorParams from './ConnectionLineSelectorParams'

interface Props extends InputProps {
  resetParameters(params: string[]): void
}

const ConnectionLineSelectorFields: FC<Props> = ({
  control,
  watch,
  resetParameters,
  getValues
}) => {
  const { connectionId } = useParams<{ connectionId: string }>()
  const watchSelectorType = watch && watch('selector.type')

  const fetchSelectors = useConnectionSelectorParams(connectionId)

  const selector = fetchSelectors.data?.find(s => s.type === watchSelectorType)

  useEffect(() => {
    if (getValues && fetchSelectors.isSuccess) {
      const params: string[] = getValues('selector.params') ?? []
      const allowed = selector?.params.map(p => p.value.toString()) ?? []
      resetParameters(params.filter(p => allowed.includes(p)))
    }
  }, [watchSelectorType])

  return (
    <FormGroup>
      <FormLabel sx={{ my: 2 }} component="h5">
        Selectors
      </FormLabel>

      <Box px={4}>
        <Select
          label="Selector Type"
          name="selector.type"
          options={
            fetchSelectors.data?.map(s => ({ label: s.label, value: s.type })) ?? []
          }
          control={control}
        />

        <Collapse
          in={!!watchSelectorType && !!fetchSelectors.isSuccess}
          timeout="auto"
        >
          <ConnectionLineSelectorParams
            name="selector.params"
            parameters={selector?.params ?? []}
            control={control}
          />
        </Collapse>
      </Box>
    </FormGroup>
  )
}

export default ConnectionLineSelectorFields
