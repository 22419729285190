import {
  useConnectionAccounts,
  useConnectionClasses,
  useConnectionCustomers,
  useConnectionItems
} from '@api/queries'
import { DataTable } from '@common/table'
import { ConnectionPreferences } from '@models'
import { Box, Button, Checkbox } from '@mui/material'
import { FC } from 'react'
import { useIsMutating } from 'react-query'
import { useParams } from 'react-router-dom'
import { AutofyType } from './ConnectionPreferencesHeader'

interface Props {
  type: AutofyType
  selected: ConnectionPreferences
  setSelected(selected: ConnectionPreferences): void
  savePreferences(): void
  resetSelected(): void
}

const ConnectionPreferencesTable: FC<Props> = ({
  type,
  selected,
  setSelected,
  savePreferences,
  resetSelected
}) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchAccounts = useConnectionAccounts(
    connectionId,
    type === 'accounts',
    true
  )
  const fetchClasses = useConnectionClasses(connectionId, type === 'classes', true)
  const fetchCustomers = useConnectionCustomers(
    connectionId,
    type === 'customers',
    true
  )
  const fetchItems = useConnectionItems(connectionId, type === 'items', true)

  const typeMap = {
    accounts: {
      query: fetchAccounts,
      key: 'accountId',
      selected: selected.accounts,
      columns: [
        { field: 'name', headerName: 'Account Name', flex: 1.5 },
        { field: 'type', headerName: 'Account Type', flex: 1 }
      ]
    },
    classes: {
      query: fetchClasses,
      key: 'classId',
      selected: selected.classes,
      columns: [{ field: 'fullName', headerName: 'Class Name', flex: 1.5 }]
    },
    customers: {
      query: fetchCustomers,
      key: 'customerId',
      selected: selected.customers,
      columns: [{ field: 'fullName', headerName: 'Customer Name', flex: 1.5 }]
    },
    items: {
      query: fetchItems,
      key: 'itemId',
      selected: selected.items,
      columns: [
        { field: 'fullName', headerName: 'Item Name', flex: 1.5 },
        { field: 'type', headerName: 'Item Type', flex: 1 }
      ]
    }
  }

  const selectedType = typeMap[type]
  const isMutating = useIsMutating({ mutationKey: 'refresh autofy data' })

  const toggleRow = (id: string) => {
    if (selectedType.query.isSuccess) {
      const old = selectedType.selected
      const updated = old.includes(id) ? old.filter(r => r !== id) : [...old, id]

      setSelected({ ...selected, [type]: updated })
    }
  }

  const toggleAllRows = () => {
    if (selectedType.query.isSuccess) {
      const old = selectedType.selected
      const updated =
        old.length !== selectedType.query.data.length
          ? selectedType.query.data.map((d: any) => d[selectedType.key])
          : []

      setSelected({ ...selected, [type]: updated })
    }
  }

  return (
    <DataTable
      query={selectedType.query}
      idKey={selectedType.key}
      height={625}
      errorMessage={`Failed to fetch ${type}`}
      loading={isMutating > 0}
      columns={[
        ...selectedType.columns,
        {
          field: 'preferred',
          width: 150,
          type: 'boolean',
          disableColumnMenu: true,
          valueGetter: ({ row }) =>
            selectedType.selected.includes(row[selectedType.key]),
          renderCell: ({ row, value }) => (
            <Checkbox
              checked={value}
              onClick={() => toggleRow(row[selectedType.key])}
            />
          ),
          renderHeader: () => (
            <div>
              <strong>Preferred</strong>
              <Checkbox
                disabled={
                  !selectedType.query.isSuccess ||
                  selectedType.query.data?.length === 0
                }
                checked={
                  selectedType.selected.length === selectedType.query.data?.length &&
                  selectedType.query.data?.length !== 0
                }
                indeterminate={
                  selectedType.selected.length > 0 &&
                  selectedType.selected.length <
                    (selectedType.query.data?.length ?? 0)
                }
                onClick={toggleAllRows}
                inputProps={{
                  title: 'Toggle all'
                }}
              />
            </div>
          )
        }
      ]}
      customButton={
        <Box display="inline" ml={4}>
          <Button
            variant="outlined"
            color="warning"
            size="small"
            onClick={resetSelected}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={savePreferences}
            sx={{ ml: 2 }}
          >
            Save
          </Button>
        </Box>
      }
    />
  )
}

export default ConnectionPreferencesTable
