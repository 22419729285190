import { AccessCompanyReport, ApiError, QuerySelect } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useCompanyReports = (
  select?: QuerySelect<AccessCompanyReport[]>
): UseQueryResult<AccessCompanyReport[], ApiError> =>
  useQuery(
    ['company', 'reports'],
    () => axios.get('/company/reports').then(res => res.data),
    { select }
  )

export default useCompanyReports
