import { ApiError, ConnectionExport, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type ErrorData = Array<{
  Message: string
}>

type Payload = { payrollId: string; mappingId?: string }

const useCreateConnectionExport = (
  connectionId: string
): UseMutationResult<ConnectionExport, ApiError<ErrorData>, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ payrollId, mappingId }) =>
      axios
        .post<ConnectionExport>(
          `/quickbooks/connections/${connectionId}${
            mappingId ? '/mappings/' + mappingId : ''
          }/exports/${payrollId}`
        )
        .then(res => res.data),
    {
      mutationKey: 'create connection export',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionExport[] | undefined>(
          ['quickbooks', 'connections', connectionId, 'exports'],
          data => data && [...data, res]
        )
        enqueueSnackbar(`Export successful`, { variant: 'success' })
      },
      onError: err => {
        if (err.response.status === 502)
          err.response.data.forEach(e =>
            enqueueSnackbar(e.Message, {
              variant: 'error',
              persist: true
            })
          )
        else enqueueSnackbar('Error exporting', { variant: 'error' })
      }
    }
  )
}

export default useCreateConnectionExport
