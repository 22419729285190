import { useSignIn } from '@api/mutations'
import { Input, PasswordInput } from '@common/inputs/form'
import { FC } from 'react'
import AuthForm from 'routes/auth/components/AuthForm'

interface FormValues {
  email: string
  password: string
}

const SignIn: FC = () => {
  const signInMut = useSignIn()

  return (
    <AuthForm<FormValues>
      title="Sign In"
      buttonText="Sign In"
      mutation={signInMut}
      link="forgot-password"
      transform={data => ({ ...data, email: data.email.toLowerCase() })}
    >
      <Input name="email" variant="outlined" />
      <PasswordInput name="password" variant="outlined" />
    </AuthForm>
  )
}

export default SignIn
