import { ApiError, ConnectionEntityType, ConnectionList } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnections = (
  entityType: ConnectionEntityType
): UseQueryResult<ConnectionList, ApiError> =>
  useQuery(
    ['quickbooks', 'connections'],
    () => axios.get<ConnectionList>('/quickbooks/connections').then(res => res.data),
    {
      select: connections => connections.filter(con => con.entityType === entityType)
    }
  )

export default useConnections
