import {
  useConnectionMapping,
  useConnectionMappingTest,
  usePayrolls
} from '@api/queries'
import { DataPreviewer, Modal } from '@common'
import { Autocomplete } from '@common/inputs/basic'
import { Check, Error } from '@mui/icons-material'
import { Box, Collapse, Divider, Tooltip, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { formatDate } from '@utils'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

const tableColumns: GridColumns = [
  { field: 'ItemID', headerName: 'ID', flex: 1, hide: true },
  { field: 'ItemName', headerName: 'Item Name', flex: 1.5 },
  { field: 'Description', headerName: 'Description', flex: 1 },
  { field: 'Rate', headerName: 'Rate', flex: 1, type: 'number' },
  { field: 'Quantity', headerName: 'Quantity', flex: 1, type: 'number' }
]

interface TestResponse {
  invoiceAmount: number
  invoice: {
    Class: { ID: string; FullName: string }
    Customer: { ID: string; FullName: string }
    Date: string | Date
    ItemLines: Array<{
      Item: { ID: string; FullName: string }
      Quantity: number
      Rate: number
      Description: string
    }>
  }
}

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const TestMappingModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const [payrollId, setPayrollId] = useState('')

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchPayrolls = usePayrolls(
    fetchMapping.data?.companyId,
    open && fetchMapping.isSuccess
  )
  const fetchMappingTest = useConnectionMappingTest(
    connectionId,
    mappingId,
    payrollId
  )

  const total = useMemo(
    () =>
      Number(
        fetchMappingTest.data?.invoice.ItemLines.reduce(
          (a, b) => a + b.Quantity,
          0
        ).toFixed(2) ?? 0
      ),
    [fetchMappingTest.data]
  )

  const closeModal = () => {
    setPayrollId('')
    onClose()
  }

  return (
    <Modal open={open} title="Test" onClose={closeModal}>
      <Autocomplete
        value={payrollId}
        onChange={val => setPayrollId(val)}
        label="Payroll"
        name="payrollId"
        optionLabelKey="name"
        query={fetchPayrolls}
        getOptionLabel={option =>
          `${option.companyPayrollId} - ${formatDate(
            new Date(option.checkDate),
            'PP'
          )}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography variant="body1" mr={1}>
              {option.companyPayrollId}
            </Typography>
            -
            <Typography variant="body2" ml={1}>
              {formatDate(new Date(option.checkDate), 'P')}
            </Typography>
          </Box>
        )}
      />

      <Collapse
        in={fetchMappingTest.isSuccess || fetchMappingTest.isLoading}
        mountOnEnter
        unmountOnExit
      >
        <DataPreviewer
          query={fetchMappingTest}
          tableColumns={tableColumns}
          fileName={`TestExport_${fetchMappingTest.data?.invoice.Date}`}
          getRows={(data: TestResponse) =>
            data.invoice.ItemLines.map((line, index) => ({
              id: index,
              ItemID: line.Item.ID,
              ItemName: line.Item.FullName,
              Quantity: line.Quantity,
              Rate: line.Rate,
              Description: line.Description
            }))
          }
          header={
            <>
              <Typography mx={1}>
                <strong>Date: </strong>
                {fetchMappingTest.data?.invoice.Date}
              </Typography>
              <Typography mx={1}>
                <strong>Class: </strong>
                {fetchMappingTest.data?.invoice.Class.FullName}
              </Typography>
              <Typography mx={1}>
                <strong>Customer: </strong>
                {fetchMappingTest.data?.invoice.Customer.FullName}
              </Typography>
            </>
          }
          footer={
            <>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <div>
                  <Typography>
                    <strong>Total:</strong> ${total}
                  </Typography>
                  <Typography>
                    <strong>Invoice Amount:</strong> $
                    {fetchMappingTest.data?.invoiceAmount}
                  </Typography>
                </div>

                {fetchMappingTest.data?.invoiceAmount === total ? (
                  <Tooltip title="Amounts add up" placement="top">
                    <Check color="success" fontSize="large" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Amounts do not match" placement="top">
                    <Error color="error" fontSize="large" />
                  </Tooltip>
                )}
              </Box>
            </>
          }
        />
      </Collapse>
    </Modal>
  )
}

export default TestMappingModal
