import { useDarkMode } from '@hooks'
import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import { createRef, FC } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import QueryClientProvider from './QueryClientProvider'
import theme from './theme'

const AppProviders: FC = ({ children }) => {
  const { isDarkMode } = useDarkMode(false)

  const notistackRef = createRef<SnackbarProvider>()
  const dismissSnackbar = (key: SnackbarKey) => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <ThemeProvider theme={theme(isDarkMode)}>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        maxSnack={3}
        action={key => (
          <IconButton
            color="inherit"
            onClick={() => dismissSnackbar(key)}
            title="Close"
          >
            <Close />
          </IconButton>
        )}
      >
        <QueryClientProvider>
          {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
          <BrowserRouter>{children}</BrowserRouter>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default AppProviders
