import { useMobileMode, useQueryParams } from '@hooks'
import { Grid, Paper, Tab, Tabs } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const Panel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <Grid
    item
    xs={12}
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    {...other}
  >
    <Paper variant="outlined" square className={`tabpanel-container`}>
      {value === index && children}
    </Paper>
  </Grid>
)

const ariaProps = (index: number) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`
})

type Tab = Array<{
  name: string
  label?: string
  component: FC<any>
}>
interface Props {
  tabs: Tab
  tabProps?: any
}

const TabsPanel = React.memo<Props>(({ tabs, tabProps }) => {
  const history = useHistory()
  const query = useQueryParams()
  const tabName = query.get('tab')
  const mobileMode = useMobileMode()

  const indexToName = (index: number) => tabs[index].name
  const nameToIndex = (name: string | null) => {
    const index = tabs.findIndex(t => t.name === name)
    return index === -1 ? 0 : index
  }

  const [tab, setTab] = useState(nameToIndex(tabName))

  useEffect(() => {
    if (!tabName) {
      history.replace(`${history.location.pathname}?tab=${tabs[0].name}`)
      setTab(0)
    } else {
      history.replace(`${history.location.pathname}?tab=${tabName}`)
      setTab(nameToIndex(tabName))
    }
  }, [tabName])

  const changeTab = (e: any, newTab: number) => {
    history.push(`${history.location.pathname}?tab=${indexToName(newTab)}`)
    setTab(newTab)
  }

  return (
    <Grid
      container
      spacing={0}
      sx={{
        ['& .tabpanel-container']: {
          px: mobileMode ? 1.5 : 3,
          py: mobileMode ? 1 : 2
        }
      }}
    >
      <Grid item xs={12}>
        <Paper
          square
          variant="outlined"
          sx={{
            mt: 2,
            mb: 3
          }}
        >
          <Tabs
            value={tab}
            onChange={changeTab}
            variant={mobileMode ? 'fullWidth' : 'standard'}
            indicatorColor="primary"
            textColor="primary"
            aria-label="page tabs"
            sx={{
              '& .MuiTab-root': {
                minWidth: { xs: 0, sm: 150, md: 200 }
              }
            }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label ?? tab.name} {...ariaProps(index)} />
            ))}
          </Tabs>
        </Paper>
      </Grid>

      {tabs.map(({ component: Component }, index) => (
        <Panel key={index} index={index} value={tab}>
          <Component {...tabProps} />
        </Panel>
      ))}
    </Grid>
  )
})
export default TabsPanel
