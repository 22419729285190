import { ApiError, User } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useCurrentUser = (): UseQueryResult<User, ApiError> =>
  useQuery(
    ['current_user'],
    () => axios.get<User>('/current_user').then(res => res.data),
    {
      retry: false,
      select: user => new User(user),
      staleTime: Infinity,
      onError: undefined
    }
  )

export default useCurrentUser
