import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface Props extends MuiLinkProps {
  label: string
  path?: string
}

const Link: FC<Props> = ({ label, path, ...rest }) =>
  path ? (
    <MuiLink component={RouterLink} to={path} {...rest}>
      {label}
    </MuiLink>
  ) : (
    <MuiLink sx={{ cursor: 'pointer' }} {...rest}>
      {label}
    </MuiLink>
  )

export default Link
