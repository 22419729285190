import { usePayrollsInvoiced } from '@api/queries'
import { Breadcrumb } from '@common'
import { DateRangeType } from '@models'
import { Divider } from '@mui/material'
import { subDays } from 'date-fns'
import { FC, useState } from 'react'
import PayrollsInvoicedGraph from './components/PayrollsInvoicedGraph'
import PayrollsInvoicedHeader from './components/PayrollsInvoicedHeader'

export type GraphType = 'bar' | 'line'

const breadcrumbs = [
  { label: 'Dashboards', path: '/dashboards' },
  { label: 'Payrolls Invoiced' }
]

const defaultDates = {
  startDate: subDays(new Date(), 7),
  endDate: new Date()
}

const PayrollsInvoiced: FC = () => {
  const [graphType, setGraphType] = useState<GraphType>('bar')
  const [dates, setDates] = useState<DateRangeType>(defaultDates)

  const fetchPayrollsInvoiced = usePayrollsInvoiced(dates)

  return (
    <>
      <Breadcrumb crumbs={breadcrumbs} />
      <PayrollsInvoicedHeader
        dates={dates}
        graphType={graphType}
        setDates={setDates}
        setGraphType={setGraphType}
      />

      <Divider />

      <PayrollsInvoicedGraph query={fetchPayrollsInvoiced} type={graphType} />
    </>
  )
}

export default PayrollsInvoiced
