import { useConnectionDetails } from '@api/queries'
import { ConnectionEntityType, ConnectionStatus, ConnectionType } from '@models'
import { ContentCopy, ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { formatDate } from '@utils'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

const InputBox: FC<{ title: string }> = ({ children, title }) => (
  <Accordion
    defaultExpanded
    elevation={0}
    sx={{
      border: 1,
      borderColor: 'grey.100',
      ['&:before']: {
        display: 'none'
      }
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls={`${title}-panel-content`}
      id={`${title}-panel-header`}
    >
      <Typography component="h4">{title}</Typography>
    </AccordionSummary>

    <AccordionDetails>
      <Grid container>{children}</Grid>
    </AccordionDetails>
  </Accordion>
)

interface InputFieldProps {
  label: string
  value?: string
  copy?: boolean
}
const InputField: FC<InputFieldProps> = ({ label, value = '', copy }) => (
  <Grid item xs={12} sm={4} py={3} px={3}>
    <TextField
      variant="outlined"
      fullWidth
      id={label.replace(' ', '')}
      value={value}
      label={label}
      InputProps={{
        endAdornment: copy && (
          <InputAdornment position="end">
            <IconButton
              title="Copy"
              disabled={!value}
              onClick={() => navigator.clipboard.writeText(value)}
            >
              <ContentCopy />
            </IconButton>
          </InputAdornment>
        ),
        readOnly: true
      }}
    />
  </Grid>
)

const printConnectionType = (connectionType?: ConnectionType) => {
  switch (connectionType) {
    case ConnectionType.Desktop:
      return 'Desktop'
    case ConnectionType.Online:
      return 'Online'
    default:
      return 'N/A'
  }
}

const printEntityType = (entityType?: ConnectionEntityType) => {
  switch (entityType) {
    case ConnectionEntityType.Company:
      return 'Company'
    case ConnectionEntityType.Reseller:
      return 'Reseller'
    default:
      return 'N/A'
  }
}

const printStatus = (status?: ConnectionStatus) => {
  switch (status) {
    case ConnectionStatus.Created:
      return 'Created'
    case ConnectionStatus.InvitationSent:
      return 'Invitation Sent'
    case ConnectionStatus.OpenedEmail:
      return 'Opened Email'
    case ConnectionStatus.Connected:
      return 'Connected'
    default:
      return 'N/A'
  }
}

const ConnectionDetailsTab: FC = () => {
  const { connectionId } = useParams<{ connectionId: string }>()
  const { data: connection } = useConnectionDetails(connectionId)

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" gutterBottom>
          Details
        </Typography>

        <Typography>
          Created Date:{' '}
          {connection
            ? formatDate(new Date(connection.createdAt), 'MM/dd/yyyy')
            : ''}
        </Typography>
      </Box>

      <InputBox title="Connection Details">
        <InputField label="Company ID" value={connection?.companyId} />
        <InputField label="Company Name" value={connection?.companyName} />
        <InputField
          label="Entity Type"
          value={printEntityType(connection?.entityType)}
        />

        <InputField
          label="Connection Type"
          value={printConnectionType(connection?.connectionType)}
        />
        <InputField
          label="Connection Status"
          value={printStatus(connection?.status)}
        />
        <InputField label="Contact Email" value={connection?.contactEmail} />
      </InputBox>

      <InputBox title="Autofy Details">
        <InputField label="Autofy User ID" value={connection?.userId} copy />
        <InputField label="Autofy Agent Token" value={connection?.agentToken} copy />
        <InputField label="Autofy Endpoint ID" value={connection?.endpointId} copy />
      </InputBox>
    </>
  )
}

export default ConnectionDetailsTab
