import { useResetPassword } from '@api/mutations'
import { PasswordInput } from '@common/inputs/form'
import { useQueryParams } from '@hooks'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import AuthForm, { ValidateAuthForm } from 'routes/auth/components/AuthForm'

interface FormValues {
  newPassword: string
  confirmPassword: string
}

const ResetPassword: FC = () => {
  const history = useHistory()
  const query = useQueryParams()
  const resetToken = query.get('resetToken') || ''

  const resetPasswordMut = useResetPassword(() => {
    history.push('/auth/sign-in')
  })

  const validate: ValidateAuthForm<FormValues> = ({
    newPassword,
    confirmPassword
  }) =>
    newPassword !== confirmPassword && {
      key: 'confirmPassword',
      message: 'Passwords must match'
    }

  return (
    <AuthForm<FormValues>
      mutation={resetPasswordMut}
      buttonText="Reset"
      link="sign-in"
      title="Reset Password"
      transform={data => ({ resetToken, newPassword: data.newPassword })}
      validate={validate}
    >
      <PasswordInput name="newPassword" variant="outlined" />
      <PasswordInput
        name="confirmPassword"
        label="Confirm Password"
        variant="outlined"
      />
    </AuthForm>
  )
}

export default ResetPassword
