import { DateRangeType, InputProps } from '@models'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, FormGroup, FormLabel, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'

const DateRange: FC<InputProps> = ({
  control,
  defaultValue = new Date(),
  label = 'Date Range'
}) => {
  const {
    // eslint-disable-next-line
    field: startDate // { ref: startRef, ...startDate }
  } = useController<DateRangeType>({
    control,
    name: 'startDate',
    defaultValue
  })

  const {
    field: endDate // { ref: endRef, ...endDate }
  } = useController<DateRangeType>({
    control,
    name: `endDate`,
    defaultValue
  })

  return (
    <Box my={2}>
      <FormLabel>{label}</FormLabel>

      <FormGroup
        row
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
          px: 2,
          ['& > *']: {
            flex: 1
          }
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...startDate}
            maxDate={endDate.value}
            label="Start Date"
            renderInput={params => <TextField id="start-date" {...params} />}
          />
        </LocalizationProvider>

        <Typography variant="h4" textAlign="center">
          -
        </Typography>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...endDate}
            minDate={startDate.value}
            label="End Date"
            renderInput={params => <TextField id="end-date" {...params} />}
          />
        </LocalizationProvider>
      </FormGroup>
    </Box>
  )
}

export default DateRange
