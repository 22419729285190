import { ConnectionItemLine } from '@models'
import { Grid } from '@mui/material'
import { FC, useState } from 'react'
import MappingItemsHeader from './components/MappingItemsHeader'
import MappingLinesTable from './components/MappingLinesTable'
import CreateMappingLineModal from './components/modals/CreateMappingLineModal'
import EditMappingLineModal from './components/modals/EditMappingLineModal'
import TestMappingModal from './components/modals/TestMappingModal'

export type Selected =
  | { line: undefined; type: 'none' }
  | { line: undefined; type: 'create' }
  | { line: ConnectionItemLine; type: 'edit' }

const MappingItemsTab: FC = () => {
  const [testModalOpen, setTestModalOpen] = useState(false)
  const [selected, setSelected] = useState<Selected>({
    line: undefined,
    type: 'none'
  })

  const closeModal = () => setSelected({ line: undefined, type: 'none' })

  return (
    <Grid item xs={12}>
      <MappingItemsHeader />

      <MappingLinesTable
        selectLine={line => setSelected({ line, type: 'edit' })}
        openCreateModal={() => setSelected({ line: undefined, type: 'create' })}
        openTestModal={() => setTestModalOpen(true)}
      />

      <CreateMappingLineModal
        open={selected.type === 'create'}
        onClose={closeModal}
      />

      <EditMappingLineModal
        open={selected.type === 'edit'}
        line={selected.line}
        onClose={closeModal}
      />

      <TestMappingModal
        open={testModalOpen}
        onClose={() => setTestModalOpen(false)}
      />
    </Grid>
  )
}

export default MappingItemsTab
