import { AccessCompanyReport, ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  reportId: string
}

const useDeleteCompanyReport = (): UseMutationResult<
  AccessCompanyReport,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ reportId }) =>
      axios
        .delete<AccessCompanyReport>(`/access/companies/reports/${reportId}`)
        .then(res => res.data),
    {
      mutationKey: 'delete access company report',
      onSuccess: res => {
        queryClient.setQueryData<AccessCompanyReport[] | undefined>(
          ['access', 'companies', 'reports'],
          data => data?.filter(d => d.id !== res.id)
        )
        enqueueSnackbar('Report deleted', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to delete report', { variant: 'error' })
      }
    }
  )
}

export default useDeleteCompanyReport
