import { useUpdateFulfillment } from '@api/mutations'
import { useFulfillments } from '@api/queries'
import { useMobileMode } from '@hooks'
import { DateRangeType, Fulfillment, FulfillmentStatus } from '@models'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import {
  GridActionsCellItem,
  GridColumns,
  GridColumnVisibilityModel
} from '@mui/x-data-grid'
import { formatDistance } from 'date-fns'
import { FC, useCallback, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import EditFulfillmentModal from 'routes/fulfillments/components/EditFulfillmentModal'
import FulfillmentsTable from 'routes/fulfillments/components/FulfillmentsTable'
import CreateDeliveryModal from './components/CreateDeliveryModal'

type SelectedFulfillmentState = {
  fulfillment: Fulfillment
  type: 'delivered' | 'edit-note'
}

interface Props {
  dates: DateRangeType
}

const DeliveriesTab: FC<Props> = ({ dates }) => {
  const mobileMode = useMobileMode()

  const [showDelivered, setShowDelivered] = useState(false)
  const [selected, setSelected] = useState<SelectedFulfillmentState>()

  const updateFulfillmentMut = useUpdateFulfillment()
  const fetchDeliveries = useFulfillments(dates, data =>
    data.filter(
      d =>
        d.status === FulfillmentStatus.DELIVERIES && !!d.delivery === showDelivered
    )
  )

  const tableColumns = useMemo<GridColumns>(
    () => [
      { field: 'companyName', headerName: 'Company', flex: 1 },
      { field: 'companyPayrollId', headerName: 'Payroll', flex: 0.5 },
      {
        field: 'checkDate',
        headerName: 'Check Date',
        flex: 0.5,
        type: 'date',
        valueGetter: ({ value }) => new Date(value)
      },
      {
        field: 'note',
        headerName: 'Note',
        flex: 0.5,
        valueGetter: ({ row }) => (showDelivered ? row.delivery?.note : row.note)
      },
      {
        field: 'deliveryDate',
        headerName: 'Date Delivered',
        flex: 0.5,
        type: 'dateTime',
        valueGetter: ({ row }) => row.delivery?.date && new Date(row.delivery.date),
        valueFormatter: ({ value }) =>
          value && `${formatDistance(new Date(), value as Date)} ago`
      },
      {
        field: 'actions',
        type: 'actions',
        width: mobileMode ? 50 : 80,
        getActions: ({ id, row }) => {
          const fulfillment = row as Fulfillment
          return [
            <GridActionsCellItem
              key={`delivered-action-${id}`}
              label="Delivered"
              onClick={() => setSelected({ fulfillment, type: 'delivered' })}
              showInMenu
            />,
            <GridActionsCellItem
              key={`edit-note-action-${id}`}
              label="Edit note"
              onClick={() => setSelected({ fulfillment, type: 'edit-note' })}
              showInMenu
            />,
            <GridActionsCellItem
              key={`unfulfilled-action-${id}`}
              label="Add to Unfulfilled"
              onClick={() => addToUnfulfilled(fulfillment)}
              showInMenu
            />
          ]
        }
      }
    ],
    [fetchDeliveries, showDelivered, mobileMode]
  )

  const tableColumnVisibility = useMemo<GridColumnVisibilityModel>(
    () => ({
      companyName: true,
      companyPayrollId: !mobileMode,
      checkDate: true,
      note: true,
      deliveryDate: showDelivered,
      actions: true
    }),
    [showDelivered, mobileMode]
  )

  const addToUnfulfilled = useCallback(
    (fulfillment: Fulfillment) => {
      updateFulfillmentMut.mutate({
        id: fulfillment.id,
        data: {
          status: FulfillmentStatus.UNFULFILLED,
          note: ''
        }
      })
    },
    [fetchDeliveries]
  )

  const onEditFulfillmentNoteSubmit: SubmitHandler<{ note: string }> = ({
    note
  }) => {
    if (selected?.type === 'edit-note')
      updateFulfillmentMut.mutate(
        {
          id: selected.fulfillment.id,
          data: {
            status: selected.fulfillment.status,
            note
          }
        },
        {
          onSuccess: () => {
            setSelected(undefined)
          }
        }
      )
  }

  return (
    <Box>
      <EditFulfillmentModal
        fulfillment={
          selected?.type === 'edit-note' ? selected.fulfillment : undefined
        }
        title="Edit Note"
        onSubmit={onEditFulfillmentNoteSubmit}
        onClose={() => setSelected(undefined)}
      />

      <CreateDeliveryModal
        fulfillment={
          selected?.type === 'delivered' ? selected.fulfillment : undefined
        }
        onClose={() => setSelected(undefined)}
      />

      <FulfillmentsTable
        columns={tableColumns}
        query={fetchDeliveries}
        columnVisibilityModel={tableColumnVisibility}
        customButton={
          <Box>
            <FormControlLabel
              label={`${showDelivered ? 'Hide' : 'Show'} Delivered`}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={showDelivered}
                  onChange={() => setShowDelivered(prev => !prev)}
                />
              }
            />
          </Box>
        }
      />
    </Box>
  )
}

export default DeliveriesTab
