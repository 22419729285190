import { useDownloadCompanyReport } from '@api/mutations'
import { useCurrentUser, usePayrolls } from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, DateRange } from '@common/inputs/form'
import { AccessCompanyReport } from '@models'
import { Box, Typography } from '@mui/material'
import { formatDate, toCsvSheet } from '@utils'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import XLSX from 'xlsx'

interface DownloadFormData {
  startDate?: Date | string
  endDate?: Date | string
  payrollId?: string
}

interface Props {
  open: boolean
  report?: AccessCompanyReport
  onClose(): void
}

const DownloadCompanyReportModal: FC<Props> = ({ open, report, onClose }) => {
  const { data: user } = useCurrentUser()

  const formFields: AccessCompanyReport['parameters'] = {
    dateRange: !!report?.parameters.dateRange,
    payrollId: !!report?.parameters.payrollId
  }

  const defaultValues = open
    ? {
        ...(formFields.dateRange && {
          startDate: new Date(),
          endDate: new Date()
        }),
        ...(formFields.payrollId && { payrollId: '' })
      }
    : {}

  const downloadForm = useForm<DownloadFormData>({ defaultValues })

  const downloadReportMut = useDownloadCompanyReport(report?.id)

  const fetchPayrolls = usePayrolls(user?.companyId, open && formFields.payrollId)

  useEffect(() => {
    if (open && report) downloadForm.reset({ ...defaultValues })
  }, [open, report])

  const onSubmit: SubmitHandler<DownloadFormData> = submitData => {
    let data = { ...submitData }

    if (data.startDate && data.endDate)
      data = {
        ...data,
        startDate: formatDate(new Date(data.startDate), 'yyyy-MM-dd'),
        endDate: formatDate(new Date(data.endDate), 'yyyy-MM-dd')
      }

    downloadReportMut.mutate(data, {
      onSuccess: res => {
        const workbook = XLSX.utils.book_new()

        for (const response of res) {
          XLSX.utils.book_append_sheet(
            workbook,
            toCsvSheet(response.results),
            response.label
          )
        }

        const name = report?.name.replace(/[[|\]:/\\?*]/g, '')
        XLSX.writeFile(
          workbook,
          `${name}-${formatDate(new Date(), 'MM_dd_yy')}.xlsx`
        )

        onClose()
      }
    })
  }

  return (
    <FormDialog
      form={downloadForm}
      open={open}
      title={report?.name || ''}
      onClose={() => onClose()}
      onSubmit={onSubmit}
    >
      {formFields.dateRange && <DateRange name="dateRange" />}

      {formFields.payrollId && (
        <Autocomplete
          label="Payroll"
          name="payrollId"
          query={fetchPayrolls}
          sort={{ key: 'checkDate', direction: 'desc' }}
          getOptionLabel={option =>
            `${option.companyPayrollId} - ${formatDate(
              new Date(option.checkDate),
              'PP'
            )}`
          }
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Typography variant="body1" mr={1}>
                {option.companyPayrollId}
              </Typography>
              -
              <Typography variant="body2" ml={1}>
                {formatDate(new Date(option.checkDate), 'P')}
              </Typography>
            </Box>
          )}
        />
      )}
    </FormDialog>
  )
}

export default DownloadCompanyReportModal
