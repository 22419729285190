import { ApiError, ConnectionMapping, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  companyId: string
  companyName: string
}

const useCreateConnectionMapping = (
  connectionId: string
): UseMutationResult<ConnectionMapping, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<ConnectionMapping>(
          `/quickbooks/connections/${connectionId}/mappings`,
          payload
        )
        .then(res => res.data),
    {
      mutationKey: 'create connection mapping',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionMapping[] | undefined>(
          ['quickbooks', 'connections', connectionId, 'mappings'],
          data => data && [...data, res]
        )
        enqueueSnackbar(`Mapping created`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create mapping', { variant: 'error' })
      }
    }
  )
}
export default useCreateConnectionMapping
