import { useCreateFulfillmentDelivery } from '@api/mutations'
import { FormDialog } from '@common'
import { TextArea } from '@common/inputs/form'
import { Fulfillment } from '@models'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

interface CreateDeliveryForm {
  note: string
}

interface Props {
  fulfillment?: Fulfillment
  onClose(): void
}

const CreateDeliveryModal: FC<Props> = ({ fulfillment, onClose }) => {
  const createDeliveryMut = useCreateFulfillmentDelivery()
  const createDeliveryForm = useForm<CreateDeliveryForm>({
    defaultValues: { note: '' }
  })

  useEffect(() => {
    if (fulfillment) createDeliveryForm.reset({ note: '' })
  }, [fulfillment])

  const onSubmit: SubmitHandler<CreateDeliveryForm> = ({ note }) => {
    if (fulfillment)
      createDeliveryMut.mutate(
        {
          id: fulfillment.id,
          data: { note }
        },
        { onSuccess: onClose }
      )
  }

  return (
    <FormDialog
      form={createDeliveryForm}
      open={!!fulfillment}
      title="Delivered"
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <TextArea label="Add a note" name="note" variant="outlined" required={false} />
    </FormDialog>
  )
}

export default CreateDeliveryModal
