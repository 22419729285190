import { DateRangeType, InputProps } from '@models'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Box, TextField } from '@mui/material'
import { FC } from 'react'

interface Props {
  dates: DateRangeType
  setDates(dates: DateRangeType): void
  variant?: InputProps['variant']
  size?: InputProps['size']
}

const DateRange: FC<Props> = ({ dates, setDates, variant, size }) => (
  <Box
    sx={{
      display: 'flex',
      gap: 2,
      ['& .MuiFormControl-root']: {
        width: 175
      }
    }}
  >
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Start Date"
        maxDate={dates.endDate}
        value={dates.startDate}
        onChange={val => setDates({ ...dates, startDate: val || new Date() })}
        renderInput={params => (
          <TextField variant={variant} size={size} {...params} />
        )}
      />
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="End Date"
        minDate={dates.startDate}
        value={dates.endDate}
        onChange={val => setDates({ ...dates, endDate: val || new Date() })}
        renderInput={params => (
          <TextField variant={variant} size={size} {...params} />
        )}
      />
    </LocalizationProvider>
  </Box>
)

export default DateRange
