import { useConnections } from '@api/queries'
import { Link } from '@common'
import { DataTable } from '@common/table'
import {
  ConnectionEntityType,
  ConnectionStatus,
  ConnectionType,
  QBConnection
} from '@models'
import { MailOutline } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'

interface Props {
  entityType: ConnectionEntityType
  openCreateModal(): void
  openInviteModal(connection: QBConnection): void
}

const QBConnectionsTable: FC<Props> = ({
  entityType,
  openCreateModal,
  openInviteModal
}) => {
  const fetchConnections = useConnections(entityType)

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'companyName',
        headerName:
          (entityType === ConnectionEntityType.Company && 'Company') ||
          (entityType === ConnectionEntityType.Reseller && 'Reseller') ||
          '',
        flex: 1.5,
        renderCell: ({ row: con }) =>
          con.status === ConnectionStatus.Connected ? (
            <Link
              label={con.companyName}
              path={`/quickbooks/connections/${con.id}`}
            />
          ) : (
            <Typography variant="body2">{con.companyName}</Typography>
          )
      },
      {
        field: 'connectionType',
        headerName: 'Type',
        flex: 1,
        type: 'singleSelect',
        valueOptions: [ConnectionType.Online, ConnectionType.Desktop],
        valueFormatter: ({ value }) =>
          (value === ConnectionType.Online && 'Online') ||
          (value === ConnectionType.Desktop && 'Desktop')
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        type: 'singleSelect',
        valueOptions: [
          ConnectionStatus.Created,
          ConnectionStatus.InvitationSent,
          ConnectionStatus.OpenedEmail,
          ConnectionStatus.Connected
        ],
        valueFormatter: ({ value }) =>
          (value === ConnectionStatus.Created && 'Created') ||
          (value === ConnectionStatus.InvitationSent && 'Invitation Sent') ||
          (value === ConnectionStatus.OpenedEmail && 'Opened Email') ||
          (value === ConnectionStatus.Connected && 'Connected')
      },
      {
        field: 'actions',
        width: 80,
        type: 'actions',
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={`invite-${row.id}`}
            icon={<MailOutline fontSize="large" />}
            label="Send Invite"
            onClick={() => openInviteModal(row as QBConnection)}
          />
        ]
      }
    ],
    [fetchConnections.status]
  )

  return (
    <DataTable
      query={fetchConnections}
      columns={columns}
      errorMessage="Failed to fetch connections"
      addNewRow={openCreateModal}
    />
  )
}

export default QBConnectionsTable
