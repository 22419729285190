import { Button, Grid } from '@mui/material'
import { Dispatch, FC } from 'react'
import { Action, State } from '../GroupMembersTab'

interface MoveButtonsProps {
  state: State
  dispatch: Dispatch<Action>
}

const MoveButtons: FC<MoveButtonsProps> = ({ state, dispatch }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    sx={{
      '& button': {
        my: 0.5
      }
    }}
  >
    <Button
      size="small"
      color="inherit"
      disabled={!state.left.length}
      onClick={() => dispatch({ type: 'move_all_left_to_right' })}
    >
      {'>>'}
    </Button>

    <Button
      size="small"
      color="inherit"
      disabled={!state.checkedLeft.length}
      onClick={() => dispatch({ type: 'move_checked_left_to_right' })}
    >
      {'>'}
    </Button>

    <Button
      size="small"
      color="inherit"
      disabled={!state.checkedRight.length}
      onClick={() => dispatch({ type: 'move_checked_right_to_left' })}
    >
      {'<'}
    </Button>

    <Button
      size="small"
      color="inherit"
      disabled={!state.right.length}
      onClick={() => dispatch({ type: 'move_all_right_to_left' })}
    >
      {'<<'}
    </Button>
  </Grid>
)

export default MoveButtons
