import { ApiError, Group } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  reports?: string[]
  users?: string[]
  fulfillment?: boolean
  delivery?: boolean
  qbConnectionManagement?: boolean
}

const useUpdateGroup = (
  groupId: string
): UseMutationResult<Group, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios.patch<Group>(`/groups/${groupId}`, payload).then(res => res.data),
    {
      mutationKey: 'update group',
      onSuccess: res => {
        queryClient.setQueryData<Group[] | undefined>(['groups'], data =>
          data?.map(d => (d.id === res.id ? res : d))
        )
        queryClient.setQueryData(['groups', groupId], res)

        enqueueSnackbar('Group updated', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update group', { variant: 'error' })
      }
    }
  )
}

export default useUpdateGroup
