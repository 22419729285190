import { ApiError, ConnectionCustomer, QBConnection } from '@models'
import axios from 'axios'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

const useConnectionCustomers = (
  connectionId: string | undefined,
  enabled?: boolean,
  includeAll?: boolean
): UseQueryResult<ConnectionCustomer[], ApiError> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['quickbooks', 'connections', connectionId, 'customers'],
    () =>
      axios
        .get<ConnectionCustomer[]>(
          `/quickbooks/connections/${connectionId}/customers`
        )
        .then(res => res.data),
    {
      enabled: !!connectionId && enabled,
      initialData: () =>
        queryClient.getQueryData<QBConnection>([
          'quickbooks',
          'connections',
          connectionId
        ])?.customers,
      select: data => {
        const connection = queryClient.getQueryData<QBConnection>([
          'quickbooks',
          'connections',
          connectionId
        ])
        const preferred = connection?.preferences.customers

        if (includeAll || !preferred) return data
        return data.filter(d => preferred.includes(d.customerId))
      }
    }
  )
}
export default useConnectionCustomers
