import { Select } from '@common/inputs/basic'
import { Box } from '@mui/material'
import { FC, useState } from 'react'
import GroupFulfillmentRights from './components/GroupFulfillmentRights'
import GroupQuickBooksRights from './components/GroupQuickBooksRights'
import GroupReportsRights from './components/GroupReportsRights'

enum Services {
  Reports = 'reports',
  Fulfillment = 'fulfillment',
  QuickBooks = 'quickbooks'
}

const GroupRightsTab: FC = () => {
  const [service, setService] = useState<Services>(Services.Reports)

  return (
    <Box>
      <Select
        label="Service"
        value={service}
        onChange={val => setService(val as Services)}
        options={[
          { label: 'Reports', value: Services.Reports },
          { label: 'Fulfillment', value: Services.Fulfillment },
          { label: 'QuickBooks', value: Services.QuickBooks }
        ]}
      />

      {service === Services.Reports && <GroupReportsRights />}

      {service === Services.Fulfillment && <GroupFulfillmentRights />}

      {service === Services.QuickBooks && <GroupQuickBooksRights />}
    </Box>
  )
}

export default GroupRightsTab
