import { Breadcrumb } from '@common'
import { ConnectionExportPayroll, DateRangeType } from '@models'
import { Box } from '@mui/material'
import { nextFriday } from 'date-fns'
import { FC, useState } from 'react'
import QBExportsHeader from './components/QBExportsHeader'
import QBExportsTable from './components/QBExportsTable'
import QBExportsTestModal from './components/QBExportsTestModal'

const breadcrumbs = [
  { label: 'QuickBooks', path: '/quickbooks' },
  { label: 'Exports' }
]

const QBExports: FC = () => {
  const [dates, setDates] = useState<DateRangeType>({
    startDate: new Date(),
    endDate: nextFriday(new Date())
  })

  const [selectedForTest, setSelectedForTest] = useState<ConnectionExportPayroll>()

  return (
    <Box>
      <Breadcrumb crumbs={breadcrumbs} />

      <QBExportsHeader dates={dates} setDates={setDates} />

      <QBExportsTable dates={dates} selectForTest={setSelectedForTest} />

      <QBExportsTestModal
        selected={selectedForTest}
        onClose={() => setSelectedForTest(undefined)}
      />
    </Box>
  )
}

export default QBExports
