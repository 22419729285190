import { createTheme, PaletteOptions, Theme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'

// Lightmode
const cloud9 = {
  blue: '#2D7495',
  yellow: '#FDA900',
  green: '#2D9270',
  black: '#30302F',
  background: '#F9F9FA',
  darkBlue: '#0f3039',
  lightGrey: '#676866',
  contrastText: '#576d75'
}

// Darkmode
const material = {
  amber: '#ffc400',
  lightBlue: '#03a9f4',
  paper: '#424242',
  background: '#303030',
  primaryText: '#fff',
  secondaryText: 'rgba(255, 255, 255, 0.7)'
}

const common = {
  black: '#000',
  white: '#fff',
  error: '#C92B2B',
  grey: '#c7c7c7',
  success: '#2e7d32' //cloud9.green
}

// Main

const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: { main: cloud9.blue },
  secondary: { main: cloud9.yellow },
  error: { main: common.error },
  // warning: default,
  success: { main: common.success },
  background: { default: cloud9.background },
  text: {
    primary: cloud9.darkBlue,
    secondary: cloud9.lightGrey,
    disabled: common.grey
  }
}

const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: { main: material.lightBlue },
  secondary: { main: material.amber },
  error: { main: common.error },
  // warning: default,
  success: { main: common.success },
  background: { default: material.background, paper: material.paper },
  text: {
    primary: material.primaryText,
    secondary: material.secondaryText,
    disabled: common.grey
  }
}

const typography = {
  h1: {
    fontWeight: 400,
    fontSize: '2.125rem',
    lineHeight: 1.235,
    letterSpacing: '0.00735em'
  },
  h2: {
    fontWeight: 400,
    fontSize: '1.75rem',
    lineHeight: 1.28,
    letterSpacing: '0.0075em'
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em'
  },
  h4: {
    fontWeight: 600, // bold and differend font
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  h6: {
    fontWeight: 400,
    fontSize: '1.15rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  }
}

const headerHeight = 70

const theme = (darkMode: boolean): Theme => {
  const palette = darkMode ? darkPalette : lightPalette

  return createTheme({
    palette,
    mixins: {
      toolbar: {
        height: headerHeight
      }
    },
    components: {
      // Header
      MuiAppBar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: darkMode ? material.lightBlue : cloud9.background,
            color: common.black
          }
        }
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            height: headerHeight
          }
        }
      },

      // Sidebar
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: darkMode ? material.paper : cloud9.darkBlue,
            color: darkMode ? common.grey : cloud9.contrastText
          }
        }
      },

      // Layout
      MuiContainer: {
        styleOverrides: {
          maxWidthXl: {
            '@media (min-width: 1920px)': {
              maxWidth: 1800
            },
            '@media (max-width: 1920px)': {
              maxWidth: 1500
            }
          }
        }
      },

      // Other
      MuiTextField: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained'
        },
        styleOverrides: {
          colorInherit: {
            color: 'black'
          }
        }
      }
    },
    typography
  })
}

export default theme
