import { ApiError, ConnectionExport } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionExport = (
  connectionId: string | undefined,
  exportId: string | undefined
): UseQueryResult<ConnectionExport | undefined, ApiError> => {
  return useQuery(
    ['quickbooks', 'connections', connectionId, 'exports'],
    () =>
      axios
        .get<ConnectionExport[]>(`/quickbooks/connections/${connectionId}/exports`)
        .then(res => res.data),
    {
      enabled: Boolean(connectionId && exportId),
      select: data => data.find(e => e.id === exportId)
    }
  )
}

export default useConnectionExport
