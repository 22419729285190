import { ApiError, ConnectionLineType } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useQuery, UseQueryResult } from 'react-query'

interface Response {
  invoiceCost: number
  journalEntry: {
    Date: string | Date
    Lines: Array<{
      Account: { ID: string; FullName: string }
      Type: ConnectionLineType
      Memo: string
      Amount: number
    }>
  }
}

const useConnectionTest = (
  connectionId: string | undefined,
  payrollId: string | undefined
): UseQueryResult<Response, ApiError> => {
  const { enqueueSnackbar } = useSnackbar()

  return useQuery(
    ['quickbooks', 'connections', connectionId, 'test', payrollId],
    () =>
      axios
        .get<Response>(`/quickbooks/connections/${connectionId}/test/${payrollId}`)
        .then(res => res.data),
    {
      enabled: Boolean(connectionId && payrollId),
      onError: () => {
        enqueueSnackbar('Failed to run test', { variant: 'error' })
      }
    }
  )
}

export default useConnectionTest
