import { useConnectionMappings } from '@api/queries'
import { Link } from '@common'
import { DataTable } from '@common/table'
import { ContentCopy } from '@mui/icons-material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  openDuplicateModal(mappingId: string): void
  openCreateModal(): void
}

const ConnectionMappingsTable: FC<Props> = ({
  openDuplicateModal,
  openCreateModal
}) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const fetchMappings = useConnectionMappings(connectionId)

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'companyName',
        headerName: 'Company',
        flex: 1.75,
        renderCell: ({ row }) => (
          <Link
            label={row.companyName}
            path={`/quickbooks/connections/${connectionId}/mappings/${row._id}`}
          />
        )
      },
      { field: 'updatedAt', headerName: 'Last Modified', flex: 1, type: 'dateTime' },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={`duplicate-${row._id}`}
            icon={<ContentCopy />}
            label="Duplicate"
            onClick={() => openDuplicateModal(row._id)}
            showInMenu
          />
        ]
      }
    ],
    [connectionId, fetchMappings.status]
  )

  return (
    <DataTable
      columns={columns}
      errorMessage="Failed to fetch connection"
      height={625}
      idKey="_id"
      defaultSort="companyName"
      query={fetchMappings}
      addNewRow={openCreateModal}
    />
  )
}

export default ConnectionMappingsTable
