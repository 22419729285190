import { ApiError, ConnectionMapping } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionMapping = (
  connectionId: string | undefined,
  mappingId: string | undefined
): UseQueryResult<ConnectionMapping | undefined, ApiError> =>
  useQuery(
    ['quickbooks', 'connections', connectionId, 'mappings', mappingId],
    () =>
      axios
        .get<ConnectionMapping>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}`
        )
        .then(res => res.data),
    {
      enabled: Boolean(connectionId && mappingId)
    }
  )

export default useConnectionMapping
