import { MenuItem, SxProps, TextField, TextFieldProps } from '@mui/material'
import { FC } from 'react'

interface Option {
  label: string
  value: string
}

interface Props {
  label: string
  options: Option[]
  value: string
  variant?: TextFieldProps['variant']
  size?: TextFieldProps['size']
  sx?: SxProps
  onChange(val: string): void
}

const Select: FC<Props> = ({
  label,
  options,
  value,
  variant = 'outlined',
  size = 'small',
  sx = { minWidth: 120 },
  onChange
}) => (
  <TextField
    id={`${label.toLowerCase()}-select`}
    label={label}
    value={value}
    select
    onChange={e => onChange(e.target.value)}
    variant={variant}
    sx={sx}
    size={size}
  >
    {options.map((opt, i) => (
      <MenuItem key={i} value={opt.value}>
        {opt.label}
      </MenuItem>
    ))}
  </TextField>
)

export default Select
