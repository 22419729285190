import { useForgotPassword } from '@api/mutations'
import { Input } from '@common/inputs/form'
import { FC } from 'react'
import AuthForm from 'routes/auth/components/AuthForm'

interface FormValues {
  email: string
}

const ForgotPassword: FC = () => {
  const forgotPasswordMut = useForgotPassword()

  return (
    <AuthForm<FormValues>
      mutation={forgotPasswordMut}
      buttonText="Send"
      link="sign-in"
      title="Forgot Password"
    >
      <Input name="email" variant="outlined" />
    </AuthForm>
  )
}

export default ForgotPassword
