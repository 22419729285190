import { ApiError, ConnectionExportPayroll, DateRangeType } from '@models'
import { formatDate } from '@utils'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionExportPayrolls = (
  connectionId: string | undefined,
  dates: DateRangeType
): UseQueryResult<ConnectionExportPayroll[], ApiError> => {
  const startDate = formatDate(dates.startDate, 'yyyy-MM-dd')
  const endDate = formatDate(dates.endDate, 'yyyy-MM-dd')

  return useQuery(
    ['quickbooks', 'exports', connectionId, startDate, endDate],
    () =>
      axios
        .get(
          `/quickbooks/exports/${connectionId}` +
            `?startDate=${startDate}` +
            `&endDate=${endDate}`
        )
        .then(res => res.data),
    { enabled: !!connectionId }
  )
}

export default useConnectionExportPayrolls
