import { Grid, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

const DashboardsGrid: FC = () => {
  const { url } = useRouteMatch()

  const Card: FC<{ name: string; path: string }> = ({ name, path }) => (
    <Grid item xs={12} sm={3}>
      <RouterLink to={`${url}/${path}`} style={{ textDecoration: 'none' }}>
        <Paper className="dashboard-paper">
          <Typography variant="subtitle2">{name}</Typography>
        </Paper>
      </RouterLink>
    </Grid>
  )

  return (
    <Grid
      container
      spacing={5}
      sx={{
        '& .dashboard-paper': {
          padding: 3,
          textAlign: 'center',
          cursor: 'pointer'
        }
      }}
    >
      <Card name="Payrolls Invoiced" path="payrolls-invoiced" />
    </Grid>
  )
}

export default DashboardsGrid
