import { Link } from '@common'
import { ApiError } from '@models'
import { Box, Button, Container, LinearProgress, Typography } from '@mui/material'
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement
} from 'react'
import { Control, Path, SubmitHandler, useForm, UseFormProps } from 'react-hook-form'
import { UseMutationResult } from 'react-query'

export type ValidateAuthForm<FormValues> = (
  data: FormValues
) => { key: Path<FormValues>; message: string } | false

interface Props<FormValues> {
  buttonText: string
  defaultValues?: UseFormProps<FormValues>['defaultValues']
  link?: 'forgot-password' | 'sign-in'
  title: string
  transform?(data: FormValues): any
  validate?: ValidateAuthForm<FormValues>
  mutation: UseMutationResult<any, ApiError, any>
}

interface Default {
  [key: string]: any
}

const AuthForm = <FormValues extends Default>({
  children,
  buttonText,
  defaultValues,
  link = 'sign-in',
  validate,
  title,
  transform,
  mutation
}: PropsWithChildren<Props<FormValues>>): ReactElement => {
  const { control, handleSubmit, setError } = useForm<FormValues>({
    defaultValues
  })

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    const formValues = formData as FormValues

    const error = validate && validate(formValues)

    if (error) {
      return setError(error.key, {
        type: 'invalid',
        message: error.message
      })
    }

    mutation.mutate(transform ? transform(formValues) : formValues)
  }

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          mt: { xs: 10, sm: 16 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& form': {
            mt: 4,
            width: '100%',
            '& button': { my: 2 }
          }
        }}
      >
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            {Children.map(children, child =>
              isValidElement(child)
                ? cloneElement<{ control: Control<any> }>(child, { control })
                : child
            )}
          </Box>
          <Button type="submit" fullWidth color="primary">
            {buttonText}
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 2
            }}
          >
            <Link
              label={link === 'sign-in' ? 'Sign In' : 'Forgot Password'}
              path={`/auth/${link}`}
            />
          </Box>

          {mutation.isLoading && <LinearProgress />}
        </form>
      </Box>
    </Container>
  )
}

export default AuthForm
