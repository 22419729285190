import { useConnectionExports } from '@api/queries'
import { DataTable } from '@common/table'
import { Visibility } from '@mui/icons-material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  selectExport(exportId: string): void
  openCreateModal(): void
}

const MappingExportsTable: FC<Props> = ({ selectExport, openCreateModal }) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const fetchExports = useConnectionExports(connectionId, mappingId)

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'createdAt',
        headerName: 'Date',
        flex: 1,
        type: 'dateTime'
      },
      {
        field: 'payrollNumber',
        headerName: 'Payroll ID',
        flex: 1
      },

      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={`view-${row._id}`}
            icon={<Visibility />}
            label="View response"
            onClick={() => selectExport(row._id)}
            showInMenu
          />
        ]
      }
    ],
    [fetchExports.status]
  )

  return (
    <DataTable
      columns={columns}
      errorMessage="Failed to fetch exports"
      height={625}
      idKey="id"
      query={fetchExports}
      addNewRow={openCreateModal}
    />
  )
}

export default MappingExportsTable
