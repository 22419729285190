import { useAccessCompanyUsers } from '@api/queries'
import { DataTable } from '@common/table'
import { GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  openForm(): void
}

const CompanyUsersTable: FC<Props> = ({ openForm }) => {
  const { companyId } = useParams<{ companyId: string }>()
  const fetchUsers = useAccessCompanyUsers(companyId)

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'name', headerName: 'Name', flex: 1 },
      { field: 'email', headerName: 'Email', flex: 1 }
    ],
    [fetchUsers.data]
  )

  return (
    <DataTable
      query={fetchUsers}
      columns={columns}
      defaultSort="name"
      errorMessage="Failed to fetch users"
      addNewRow={openForm}
      height={625}
    />
  )
}

export default CompanyUsersTable
