import { useRefreshAutofyData, useUpdateConnectionPreferences } from '@api/mutations'
import { useConnectionDetails } from '@api/queries'
import { ConnectionPreferences as ConnectionPreferencesType } from '@models'
import { Cancel, Refresh, Save } from '@mui/icons-material'
import { Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ConnectionPreferencesHeader, {
  AutofyType
} from './components/ConnectionPreferencesHeader'
import ConnectionPreferencesTable from './components/ConnectionPreferencesTable'

const ConnectionPreferencesTab: FC = () => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const [type, setType] = useState<AutofyType>('accounts')
  const [selected, setSelected] = useState<ConnectionPreferencesType>({
    accounts: [],
    classes: [],
    customers: [],
    items: []
  })

  const fetchConnection = useConnectionDetails(connectionId)

  const updatePreferencesMut = useUpdateConnectionPreferences(connectionId)
  const refreshDataMut = useRefreshAutofyData(connectionId, type)

  useEffect(() => {
    if (fetchConnection.isSuccess) {
      const prefs = fetchConnection.data?.preferences

      setSelected({
        accounts: prefs?.accounts ?? [],
        classes: prefs?.classes ?? [],
        customers: prefs?.customers ?? [],
        items: prefs?.items ?? []
      })
    }
  }, [fetchConnection.isSuccess])

  const refreshData = () => refreshDataMut.mutate()

  const resetSelected = () =>
    setSelected({
      ...selected,
      [type]: fetchConnection.data?.preferences[type] ?? []
    })

  const savePreferences = () =>
    updatePreferencesMut.mutate({ [type]: selected[type] })

  return (
    <Grid item xs={12}>
      <ConnectionPreferencesHeader
        entityType={fetchConnection.data?.entityType}
        type={type}
        setType={setType}
        refreshData={refreshData}
      />

      <ConnectionPreferencesTable
        type={type}
        selected={selected}
        setSelected={setSelected}
        savePreferences={savePreferences}
        resetSelected={resetSelected}
      />

      <SpeedDial
        ariaLabel="Preferences speed dial"
        icon={<SpeedDialIcon />}
        sx={{
          position: 'absolute',
          bottom: 32,
          right: 32
        }}
      >
        <SpeedDialAction
          icon={<Save />}
          tooltipTitle="Save"
          onClick={savePreferences}
        />
        <SpeedDialAction
          icon={<Cancel />}
          tooltipTitle="Cancel"
          onClick={resetSelected}
        />
        <SpeedDialAction
          icon={<Refresh />}
          tooltipTitle="Refresh"
          onClick={refreshData}
        />
      </SpeedDial>
    </Grid>
  )
}

export default ConnectionPreferencesTab
