import { ApiError, ConnectionItemLine } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Partial<ConnectionItemLine>

const useCreateConnectionMappingLine = (
  connectionId: string,
  mappingId: string
): UseMutationResult<ConnectionItemLine, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<ConnectionItemLine>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}/lines`,
          payload
        )
        .then(res => res.data),
    {
      mutationKey: 'create connection mapping line',
      onSuccess: () => {
        queryClient.invalidateQueries([
          'quickbooks',
          'connections',
          connectionId,
          'mappings',
          mappingId
        ])

        enqueueSnackbar(`Line created`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create line', { variant: 'error' })
      }
    }
  )
}
export default useCreateConnectionMappingLine
