import { ApiError, ConnectionExport, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type ErrorData = Array<{
  Message: string
}>

interface Payload {
  payrolls: Array<{
    companyId: string
    payrollId: string
    payrollNumber: string
  }>
}

const useCreateMultiExport = (
  connectionId: string
): UseMutationResult<ConnectionExport[], ApiError<ErrorData>, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<ConnectionExport[]>(`/quickbooks/exports/${connectionId}`, payload)
        .then(res => res.data),
    {
      mutationKey: 'create multi-export',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionExport[] | undefined>(
          ['quickbooks', 'connections', connectionId, 'exports'],
          data => data && [...data, ...res]
        )
        enqueueSnackbar('Exports successful', { variant: 'success' })
      },
      onError: err => {
        if (err.response.status === 502)
          err.response.data.forEach(e =>
            enqueueSnackbar(e.Message, {
              variant: 'error',
              persist: true
            })
          )
        else enqueueSnackbar('Error exporting', { variant: 'error' })
      }
    }
  )
}

export default useCreateMultiExport
