import { Grid, Typography } from '@mui/material'
import { FC, useState } from 'react'
import ConnectionExportsTable from './components/ConnectionExportsTable'
import ConnectionExportResponseModal from './components/ConnectionExportViewerModal'
import CreateConnectionExportModal from './components/CreateConnectionExportModal'

const ConnectionExportsTab: FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedExportId, setSelectedExport] = useState<string | undefined>()

  return (
    <Grid item xs={12}>
      <Typography variant="h3" gutterBottom>
        Exports
      </Typography>

      <ConnectionExportsTable
        selectExport={id => setSelectedExport(id)}
        openCreateModal={() => setCreateModalOpen(true)}
      />

      <CreateConnectionExportModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      <ConnectionExportResponseModal
        exportId={selectedExportId}
        onClose={() => setSelectedExport(undefined)}
      />
    </Grid>
  )
}

export default ConnectionExportsTab
