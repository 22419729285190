import { ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

interface FulfillmentCheck {
  id: number
  name: string
}

const useFulfillmentChecks = (
  fulfillmentId: string | undefined,
  select?: (data: FulfillmentCheck[]) => FulfillmentCheck[]
): UseQueryResult<FulfillmentCheck[], ApiError> => {
  return useQuery(
    ['fulfillments', fulfillmentId, 'checks'],
    () =>
      axios
        .get<FulfillmentCheck[]>(`/fulfillments/${fulfillmentId}/checks`)
        .then(res => res.data),
    {
      enabled: !!fulfillmentId,
      select //: data => (status ? data.filter(d => d.status === status) : data)
    }
  )
}
export default useFulfillmentChecks
