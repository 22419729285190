import { ApiError, User } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Pick<User, 'name' | 'email'>

const useCreateCompanyUser = (
  companyId: string
): UseMutationResult<User, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<User>(`/access/companies/${companyId}/users`, payload)
        .then(res => res.data),
    {
      mutationKey: 'create company user',
      onSuccess: res => {
        queryClient.setQueryData<User[] | undefined>(
          ['access', 'companies', companyId, 'users'],
          data => data && [...data, res]
        )
        enqueueSnackbar('User created', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create user', { variant: 'error' })
      }
    }
  )
}
export default useCreateCompanyUser
