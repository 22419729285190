import { useEffect } from 'react'
import useLocalStorage from './useLocalStorage'

const COLOR_SCHEME_QUERY = '(prefers-color-scheme: dark)'

interface UseDarkModeOutput {
  isDarkMode: boolean
  toggle(): void
}

function useDarkMode(defaultValue?: boolean): UseDarkModeOutput {
  const getPrefersScheme = (): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(COLOR_SCHEME_QUERY).matches
    }

    return !!defaultValue
  }

  const [isDarkMode, setDarkMode] = useLocalStorage('darkMode', getPrefersScheme)

  // Update darkMode if os prefers changes
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = () => setDarkMode(getPrefersScheme)

      const matchMedia = window.matchMedia(COLOR_SCHEME_QUERY)

      matchMedia.addEventListener('change', handler)

      return () => {
        matchMedia.removeEventListener('change', handler)
      }
    }
  }, [])

  return {
    isDarkMode,
    toggle: () => setDarkMode(prev => !prev)
  }
}

export default useDarkMode
