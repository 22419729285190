import { ConnectionItemLine } from '@models'
import { Grid, Typography } from '@mui/material'
import { FC, useState } from 'react'
import MappingExportsTable from './components/MappingExportsTable'
import CreateMappingExportModal from './components/modals/CreateMappingExportModal'
import MappingExportViewerModal from './components/modals/MappingExportViewerModal'

export type Selected =
  | { line: undefined; type: 'none' }
  | { line: undefined; type: 'create' }
  | { line: ConnectionItemLine; type: 'edit' }

const MappingExportsTab: FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedExportId, setSelectedExport] = useState<string | undefined>()

  return (
    <Grid item xs={12}>
      <Typography variant="h3" gutterBottom>
        Exports
      </Typography>

      <MappingExportsTable
        selectExport={id => setSelectedExport(id)}
        openCreateModal={() => setCreateModalOpen(true)}
      />

      <CreateMappingExportModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      <MappingExportViewerModal
        exportId={selectedExportId}
        onClose={() => setSelectedExport(undefined)}
      />
    </Grid>
  )
}

export default MappingExportsTab
