import { ApiError, Fulfillment } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  id: string
  data: {
    note: string
  }
}

const useCreateFulfillmentDelivery = (): UseMutationResult<
  Fulfillment,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ id, data }) =>
      axios
        .put<Fulfillment>(`/fulfillments/${id}/delivery`, data)
        .then(res => res.data),
    {
      mutationKey: 'create fulfillment delivery',
      onSuccess: res => {
        enqueueSnackbar(`Delivered`, {
          variant: 'success'
        })

        queryClient.setQueriesData<Fulfillment[] | undefined>(
          { queryKey: ['fulfillments'], active: true },
          data => data?.map(d => (d.id === res.id ? { ...d, ...res } : d))
        )
      },
      onError: () => {
        enqueueSnackbar('Failed to update', { variant: 'error' })
      }
    }
  )
}

export default useCreateFulfillmentDelivery
