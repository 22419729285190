import { ApiError, ConnectionExport } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionExports = (
  connectionId: string | undefined,
  mappingId?: string
): UseQueryResult<ConnectionExport[], ApiError> =>
  useQuery(
    ['quickbooks', 'connections', connectionId, 'exports'],
    () =>
      axios
        .get<ConnectionExport[]>(`/quickbooks/connections/${connectionId}/exports`)
        .then(res => res.data),
    {
      enabled: !!connectionId,
      select: exports =>
        exports
          .filter(e => (mappingId ? e.mappingId === mappingId : true))
          .map(e => new ConnectionExport(e))
    }
  )

export default useConnectionExports
