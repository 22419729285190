import { ApiError, ConnectionLine, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  lineId: string
}

const useDeleteConnectionLine = (
  connectionId: string
): UseMutationResult<ConnectionLine, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ lineId }) =>
      axios
        .delete<ConnectionLine>(
          `/quickbooks/connections/${connectionId}/lines/${lineId}`
        )
        .then(res => res.data),
    {
      mutationKey: 'delete connection line',
      onSuccess: res => {
        queryClient.setQueryData<ConnectionLine[] | undefined>(
          ['quickbooks', 'connections', connectionId, 'lines'],
          data => data && data.filter(l => l._id !== res._id)
        )
        enqueueSnackbar('Line deleted', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to delete line', { variant: 'error' })
      }
    }
  )
}

export default useDeleteConnectionLine
