export default class Fulfillment {
  public id: string
  public status: FulfillmentStatus

  public note?: string
  public delivery?: FulfillmentDelivery
  public pickups?: FulfillmentPickup[]

  public companyName: string
  public companyPayrollId: string
  public checkDate: Date
  public liveChecks: number
  public directDeposits: number

  constructor(f: Fulfillment) {
    this.id = f.id
    this.status = f.status
    this.note = f.note
    this.delivery = f.delivery
    this.pickups = f.pickups

    this.companyName = f.companyName
    this.companyPayrollId = f.companyPayrollId
    this.checkDate = f.checkDate
    this.liveChecks = f.liveChecks
    this.directDeposits = f.directDeposits
  }
}

export enum FulfillmentStatus {
  UNFULFILLED = 'UNFULFILLED',
  DELIVERIES = 'DELIVERIES',
  MAILED = 'MAILED',
  PICKUPS = 'PICKUPS',
  REMOVE_OR_NO_CHECKS_PRINTED = 'REMOVE_OR_NO_CHECKS_PRINTED'
}

export interface FulfillmentDelivery {
  username: string
  date: Date
  note?: string
}

export interface FulfillmentPickup {
  name: string
  checkIds: number[]
  date: Date
  createdBy: string
}
