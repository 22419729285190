import { useCompanyReports } from '@api/queries'
import { Multiselect } from '@common/inputs/basic'
import { AccessCompanyReport } from '@models'
import { Box, Typography } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import CompanyReportsTable from './components/CompanyReportsTable'
import DownloadCompanyReportModal from './components/DownloadCompanyReportModal'

export type Selected =
  | { report: undefined; type: 'none' }
  | { report: AccessCompanyReport; type: 'download' }

const CompanyReports: FC = () => {
  const fetchCompanyReports = useCompanyReports()

  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selected, setSelected] = useState<Selected>({
    report: undefined,
    type: 'none'
  })

  const allTags = useMemo(() => {
    if (!fetchCompanyReports.isSuccess) return []

    const tagsList: string[] = []

    fetchCompanyReports.data.forEach(rep => {
      rep.tags.forEach(t => {
        if (!tagsList.includes(t)) tagsList.push(t)
      })
    })

    return tagsList
  }, [fetchCompanyReports.data, selectedTags])

  const toggleTag = (tag: string) =>
    setSelectedTags(oldTags =>
      oldTags.includes(tag) ? oldTags.filter(t => t !== tag) : [...oldTags, tag]
    )

  return (
    <Box>
      <Typography
        sx={{
          pt: 2,
          mb: 2
        }}
        variant="h1"
      >
        Reports
      </Typography>

      <Multiselect
        name="tags-filter"
        value={selectedTags}
        options={allTags}
        label="Filters"
        onChange={val => setSelectedTags(val)}
        variant="outlined"
        chipVariant="outlined"
      />

      <CompanyReportsTable
        tags={selectedTags}
        toggleTag={toggleTag}
        setSelected={setSelected}
      />

      <DownloadCompanyReportModal
        open={selected.type === 'download'}
        report={selected.report}
        onClose={() => setSelected({ type: 'none', report: undefined })}
      />
    </Box>
  )
}

export default CompanyReports
