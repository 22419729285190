import { ApiError, Report } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Pick<Report, 'name' | 'tags' | 'queries' | 'options'>

const useCreateReport = (): UseMutationResult<Report, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload => axios.post<Report>('/reports', payload).then(res => res.data),
    {
      mutationKey: 'create report',
      onSuccess: () => {
        queryClient.resetQueries('reports')
        enqueueSnackbar('Report created', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create report', { variant: 'error' })
      }
    }
  )
}

export default useCreateReport
