import { ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

type Response = Array<{
  id: string
  name: string
}>

const useAccessCompanyResellerSelectors = (
  enabled?: boolean
): UseQueryResult<Response, ApiError> =>
  useQuery(
    ['access', 'companies', 'selectors', 'resellers'],
    () =>
      axios
        .get<Response>('/access/companies/selectors/resellers')
        .then(res => res.data),
    {
      select: data => data.map(d => ({ id: d.id + '', name: d.name })),
      enabled
    }
  )

export default useAccessCompanyResellerSelectors
