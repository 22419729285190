import { ConnectionLine } from '@models'
import { Typography } from '@mui/material'
import { FC, useState } from 'react'
import ConnectionLinesTable from './ConnectionLinesTable'
import CreateConnectionLineModal from './modals/CreateConnectionLineModal'
import EditConnectionLineModal from './modals/EditConnectionLineModal'
import TestConnectionModal from './modals/TestConnectionModal'

export type Selected =
  | { line: undefined; type: 'none' }
  | { line: undefined; type: 'create' }
  | { line: ConnectionLine; type: 'edit' }

const CompanyConnectionMapping: FC = () => {
  const [testModalOpen, setTestModalOpen] = useState(false)
  const [selected, setSelected] = useState<Selected>({
    line: undefined,
    type: 'none'
  })

  const closeModal = () => setSelected({ line: undefined, type: 'none' })

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Mapping
      </Typography>

      <ConnectionLinesTable
        selectLine={line => setSelected({ line, type: 'edit' })}
        openCreateModal={() => setSelected({ line: undefined, type: 'create' })}
        openTestModal={() => setTestModalOpen(true)}
      />

      <CreateConnectionLineModal
        open={selected.type === 'create'}
        onClose={closeModal}
      />

      <EditConnectionLineModal
        open={selected.type === 'edit'}
        line={selected.line}
        onClose={closeModal}
      />

      <TestConnectionModal
        open={testModalOpen}
        onClose={() => setTestModalOpen(false)}
      />
    </>
  )
}

export default CompanyConnectionMapping
