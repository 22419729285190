import { Container } from '@mui/material'
import { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import CompanyReports from './paths/reports/CompanyReports'

const Company: FC = () => {
  const { path } = useRouteMatch()

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/company/reports">
          <CompanyReports />
        </Route>

        <Route path={path}>
          <Redirect to="/company/reports" />
        </Route>
      </Switch>
    </Container>
  )
}

export default Company
