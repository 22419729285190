import { useCreateConnectionMappingLine } from '@api/mutations'
import {
  useCompanyTaxPayerEntities,
  useConnectionClasses,
  useConnectionItems,
  useConnectionMapping
} from '@api/queries'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import MappingLineFormModal, { MappingLineFormData } from './MappingLineFormModal'

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateMappingLineModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const createLineMut = useCreateConnectionMappingLine(connectionId, mappingId)

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchItems = useConnectionItems(connectionId, open)
  const fetchClasses = useConnectionClasses(connectionId, open)
  const fetchTaxPayerEntities = useCompanyTaxPayerEntities(
    fetchMapping.data?.companyId,
    open && fetchMapping.isSuccess
  )

  const defaultValues = {
    itemId: '',
    description: '',
    rate: Number(1).toFixed(1),
    classId: '',
    taxPayerEntityId: '',
    selector: {
      type: undefined,
      params: []
    }
  }

  const createMappingLineForm = useForm<MappingLineFormData>({ defaultValues })

  const onSubmit: SubmitHandler<MappingLineFormData> = data => {
    const itemDoc = fetchItems.data?.find(item => item.itemId === data.itemId)
    const classDoc = fetchClasses.data?.find(cls => cls.classId === data.classId)
    const tpeDoc = fetchTaxPayerEntities.data?.find(
      tpe => tpe.taxPayerEntityId.toString() === data.taxPayerEntityId.toString()
    )

    createLineMut.mutate(
      {
        ...data,
        itemName: itemDoc?.fullName,
        className: classDoc?.fullName ?? '',
        taxPayerEntityId: data.taxPayerEntityId.toString(),
        taxPayerEntityName: tpeDoc?.name ?? ''
      },
      {
        onSuccess: () => {
          onClose()
          createMappingLineForm.reset()
        }
      }
    )
  }

  return (
    <MappingLineFormModal
      open={open}
      form={createMappingLineForm}
      title="Create Line"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default CreateMappingLineModal
