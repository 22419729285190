import { useCreateAccessCompany } from '@api/mutations'
import {
  useAccessCompanyCompSelectors,
  useAccessCompanyResellerSelectors
} from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete } from '@common/inputs/form'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

interface CompanyAccessForm {
  resellerId: string
  companyId: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateAccessCompanyModal: FC<Props> = ({ open, onClose }) => {
  const history = useHistory()
  const createAccessCompanyForm = useForm<CompanyAccessForm>({
    defaultValues: { resellerId: '', companyId: '' }
  })

  const watchResellerId = createAccessCompanyForm.watch('resellerId')

  const fetchResellers = useAccessCompanyResellerSelectors(open)
  const fetchCompanies = useAccessCompanyCompSelectors(watchResellerId)
  const createAccessCompanyMut = useCreateAccessCompany()

  const onSubmit: SubmitHandler<CompanyAccessForm> = data => {
    const reseller = fetchResellers.data?.find(r => r.id === data.resellerId)
    const company = fetchCompanies.data?.find(c => c.id === data.companyId)

    createAccessCompanyMut.mutate(
      {
        ...data,
        resellerName: reseller?.name ?? '',
        companyName: company?.name ?? ''
      },
      { onSuccess: res => history.push(`/access/companies/${res.id}`) }
    )
  }

  return (
    <FormDialog
      form={createAccessCompanyForm}
      open={open}
      title="Create Company"
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Autocomplete
        label="Reseller"
        name="resellerId"
        optionLabelKey="name"
        query={fetchResellers}
      />

      <Autocomplete
        label="Company"
        name="companyId"
        optionLabelKey="name"
        query={fetchCompanies}
      />
    </FormDialog>
  )
}

export default CreateAccessCompanyModal
