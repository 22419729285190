import { DataTable } from '@common/table'
import { Fulfillment } from '@models'
import { GridColumns, GridColumnVisibilityModel } from '@mui/x-data-grid'
import { FC, ReactElement } from 'react'
import { UseQueryResult } from 'react-query'

interface Props {
  columns: GridColumns
  query: UseQueryResult<Fulfillment[]>
  columnVisibilityModel?: GridColumnVisibilityModel
  customButton?: ReactElement
}

const FulfillmentsTable: FC<Props> = ({
  columns,
  query,
  columnVisibilityModel,
  customButton
}) => (
  <DataTable
    query={query}
    columns={columns}
    loading={query.isFetching}
    columnVisibilityModel={columnVisibilityModel}
    customButton={customButton}
    errorMessage="Failed to fetch fulfillments"
    idKey="id"
    height={650}
    toolbar={{ column: false, exportable: false }}
  />
)

export default FulfillmentsTable
