import { AccessCompany, ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useAccessCompanies = (): UseQueryResult<AccessCompany[], ApiError> =>
  useQuery(['access', 'companies'], () =>
    axios.get('/access/companies').then(res => res.data)
  )

export default useAccessCompanies
