import { JsonViewer } from '@common'
import { DataTable } from '@common/table'
import { ApiError } from '@models'
import { Code, TableRows } from '@mui/icons-material'
import { Box, Divider, IconButton, Paper } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { forwardRef, ReactNode, useState } from 'react'
import { UseQueryResult } from 'react-query'

type DisplayType = 'table' | 'json'

interface Props {
  query: UseQueryResult<any, ApiError>
  tableColumns: GridColumns
  fileName: string
  getRows(data: any): any[]
  header: ReactNode
  footer?: ReactNode
}

const DataPreviewer = forwardRef<HTMLDivElement, Props>(
  ({ query, tableColumns, fileName, getRows, header, footer }, ref) => {
    const [displayType, setDisplayType] = useState<DisplayType>('table')

    const toggleDisplayType = () => {
      setDisplayType(displayType === 'table' ? 'json' : 'table')
    }

    return (
      <Paper
        variant="outlined"
        ref={ref}
        data-testid="data-preview"
        sx={{
          width: '100%',
          padding: 1,
          mb: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {header}

          <IconButton
            onClick={toggleDisplayType}
            color="secondary"
            title="Toggle dispay"
            aria-label="toggle display"
          >
            {displayType === 'table' && <TableRows />}
            {displayType === 'json' && <Code />}
          </IconButton>
        </Box>

        <Divider />

        <Box
          sx={{
            height: 510,
            width: '100%',
            overflowY: 'auto',
            my: 1
          }}
        >
          {displayType === 'table' && (
            <DataTable
              query={query}
              columns={tableColumns}
              errorMessage="Request failed"
              height={500}
              toolbar={{ exportable: true, filter: false, density: false }}
              csvOptions={{ fileName: fileName, allColumns: true }}
              getRows={getRows}
            />
          )}
          {displayType === 'json' && <JsonViewer data={{ ...query.data }} />}
        </Box>

        {footer}
      </Paper>
    )
  }
)

export default DataPreviewer
