import { useCurrentUser, useReports } from '@api/queries'
import { Multiselect } from '@common/inputs/basic'
import { Report } from '@models'
import { Container, Typography } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import CreateReportModal from './components/modals/CreateReportModal'
import DownloadReportModal from './components/modals/DownloadReportModal'
import EditReportModal from './components/modals/EditReportModal'
import ReportLogModal from './components/modals/ReportLogModal'
import ReportsTable from './components/ReportsTable'

export type Selected =
  | { report: undefined; type: 'none' }
  | { report: undefined; type: 'create' }
  | { report: Report; type: 'edit' }
  | { report: Report; type: 'download' }
  | { report: Report; type: 'log' }

const Reports: FC = () => {
  const { data: user } = useCurrentUser()
  const fetchReports = useReports()

  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selectedRow, setSelectedRow] = useState<Selected>({
    report: undefined,
    type: 'none'
  })

  const closeModal = () => setSelectedRow({ report: undefined, type: 'none' })

  const tags = useMemo(() => {
    if (!fetchReports.isSuccess) return []

    const tagsList: string[] = []

    fetchReports.data.forEach(rep => {
      rep.tags.forEach(t => {
        if (!tagsList.includes(t)) tagsList.push(t)
      })
    })

    return tagsList.sort(
      (first, second) =>
        +selectedTags.includes(second) - +selectedTags.includes(first) ||
        first.localeCompare(second)
    )
  }, [fetchReports])

  return (
    <Container maxWidth="xl">
      <Typography
        sx={{
          pt: 2,
          mb: 2
        }}
        variant="h1"
      >
        Reports
      </Typography>

      <Multiselect
        name="tags-filter"
        value={selectedTags}
        options={tags}
        label="Filters"
        onChange={val => setSelectedTags(val)}
        variant="outlined"
      />

      <ReportsTable tags={selectedTags} setSelected={setSelectedRow} />

      <DownloadReportModal
        open={!!selectedRow.report && selectedRow.type === 'download'}
        report={selectedRow.report}
        onClose={closeModal}
      />

      {user?.isAdmin && (
        <>
          <CreateReportModal
            open={selectedRow.type === 'create'}
            tags={tags}
            onClose={closeModal}
          />

          <EditReportModal
            open={selectedRow.type === 'edit'}
            report={selectedRow.report}
            tags={tags}
            onClose={closeModal}
          />

          <ReportLogModal
            selected={selectedRow}
            onClose={() => setSelectedRow({ report: undefined, type: 'none' })}
          />
        </>
      )}
    </Container>
  )
}

export default Reports
