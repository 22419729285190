import { Breadcrumb } from '@common'
import { Select } from '@common/inputs/basic'
import { ConnectionEntityType, QBConnection } from '@models'
import { Box, Typography } from '@mui/material'
import { FC, useState } from 'react'
import CreateConnectionModal from './components/CreateConnectionModal'
import QBConnectionsTable from './components/QBConnectionsTable'
import SendInviteModal from './components/SendInviteModal'

const breadcrumbs = [
  { label: 'QuickBooks', path: '/quickbooks' },
  { label: 'Connections' }
]

const QBConnections: FC = () => {
  const [entityType, setEntityType] = useState<ConnectionEntityType>(
    ConnectionEntityType.Reseller
  )

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [sendInviteConnection, setSendInviteConnection] = useState<QBConnection>()

  return (
    <Box>
      <Breadcrumb crumbs={breadcrumbs} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="h1">Connections</Typography>

        <Select
          label="Type"
          value={entityType}
          onChange={val => setEntityType(val as ConnectionEntityType)}
          sx={{ mb: 0 }}
          options={[
            { label: 'Companies', value: ConnectionEntityType.Company },
            { label: 'Resellers', value: ConnectionEntityType.Reseller }
          ]}
        />
      </Box>

      <QBConnectionsTable
        entityType={entityType}
        openCreateModal={() => setCreateModalOpen(true)}
        openInviteModal={con => setSendInviteConnection(con)}
      />

      <CreateConnectionModal
        open={createModalOpen}
        entityType={entityType}
        onClose={() => setCreateModalOpen(false)}
      />

      <SendInviteModal
        connection={sendInviteConnection}
        onClose={() => setSendInviteConnection(undefined)}
      />
    </Box>
  )
}

export default QBConnections
