import { ApiError, ConnectionItemLine, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  lineId: string
  data: Partial<ConnectionItemLine>
}

const useUpdateConnectionMappingLine = (
  connectionId: string,
  mappingId: string
): UseMutationResult<ConnectionItemLine, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ lineId, data }) =>
      axios
        .patch<ConnectionItemLine>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}/lines/${lineId}`,
          data
        )
        .then(res => res.data),
    {
      mutationKey: 'update connection mapping line',
      onSuccess: () => {
        queryClient.invalidateQueries([
          'quickbooks',
          'connections',
          connectionId,
          'mappings',
          mappingId
        ])
        enqueueSnackbar('Line updated', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update line', { variant: 'error' })
      }
    }
  )
}

export default useUpdateConnectionMappingLine
