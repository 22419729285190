import { useUpdateUserFavorites } from '@api/mutations'
import { useCurrentUser, useReports } from '@api/queries'
import { DataTable } from '@common/table'
import { Report } from '@models'
import { GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { Selected } from '../Reports'
import ReportCard from './ReportCard'

interface Props {
  tags: string[]
  setSelected(params: Selected): void
}
const ReportsTable: FC<Props> = ({ tags, setSelected }) => {
  const { data: user } = useCurrentUser()

  const isFavorite = (id: string) =>
    user?.settings.favoriteReportIds.includes(id) ?? false
  const fetchReports = useReports(reports =>
    reports
      .filter(rep => !tags.length || tags.every(t => rep.tags.includes(t)))
      .map(r => new Report(r))
      .sort((a, b) => {
        if (isFavorite(a.id) && !isFavorite(b.id)) return -1
        else if (!isFavorite(a.id) && isFavorite(b.id)) return 1

        if (a.lastDownload && !b.lastDownload) return -1
        else if (!a.lastDownload && b.lastDownload) return 1
        else if (a.lastDownload && b.lastDownload) {
          if (a.lastDownload > b.lastDownload) return -1
          else if (a.lastDownload < b.lastDownload) return 1
          else return 0
        }
        return 0
      })
  )
  const updateUserFavoritesMut = useUpdateUserFavorites()

  const toggleFavorite = (id: string) => {
    const favorites = user?.settings.favoriteReportIds ?? []

    updateUserFavoritesMut.mutate({
      favoriteReportIds: favorites.includes(id)
        ? favorites.filter(f => f !== id)
        : [...favorites, id]
    })
  }

  const select = (id: string, type: 'edit' | 'download' | 'log') => {
    const rep = fetchReports.data?.find(r => r.id === id)

    if (rep) setSelected({ report: rep, type })
  }

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell: ({ row }) => (
          <ReportCard
            admin={user?.isAdmin}
            favorite={user?.settings.favoriteReportIds.includes(row.id)}
            report={row}
            select={select}
            toggleFavorite={toggleFavorite}
          />
        )
      },
      {
        field: 'downloads',
        headerName: 'Downloads',
        type: 'number',
        valueGetter: ({ value }) => value?.length ?? 0,
        hide: true
      },
      {
        field: 'lastDownload',
        headerName: 'Last Download',
        type: 'dateTime',
        hide: true
      }
    ],
    [user, fetchReports]
  )

  return (
    <DataTable
      columns={columns}
      query={fetchReports}
      density="comfortable"
      errorMessage="Failed to fetch reports"
      headers={false}
      height={720}
      rowHeight={65}
      loading={updateUserFavoritesMut.isLoading}
      addNewRow={() => setSelected({ report: undefined, type: 'create' })}
      toolbar={{
        density: false,
        column: false,
        exportable: false,
        filter: !!user?.isAdmin
      }}
      sx={{
        ['& .report-card']: {
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          pl: 1,
          alignItems: 'center',
          ['& .report-card-title']: {
            cursor: 'pointer',
            mb: 1
          },
          ['& .report-card-info']: {
            display: 'inline'
          }
        },
        ['& .report-card-icon-box']: {
          display: 'flex',
          width: '30%',
          height: '100%',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          my: 'auto',
          // alignContent: 'center',
          ['& .MuiIconButton-root']: {
            display: { xs: 'none', md: 'block' }
          }
        }
      }}
    />
  )
}

export default ReportsTable
