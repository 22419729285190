import { useCurrentUser } from '@api/queries'
import { useMobileMode } from '@hooks'
import { FC } from 'react'
import DesktopSidebar from './components/DesktopSidebar'
import MobileSidebar from './components/MobileSidebar'

export interface SidebarProps {
  open: boolean
  setOpen(open: boolean): void
}
const Sidebar: FC<SidebarProps> = ({ open, setOpen }) => {
  const mobileMode = useMobileMode()
  const { isSuccess, data: user } = useCurrentUser()

  if (!isSuccess || !user) return null

  return mobileMode ? (
    <MobileSidebar open={open} setOpen={setOpen} />
  ) : (
    <DesktopSidebar open={open} setOpen={setOpen} />
  )
}

export default Sidebar
