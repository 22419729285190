import { Report } from '@models'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DescriptionIcon from '@mui/icons-material/Description'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box, IconButton, Typography } from '@mui/material'
import { formatDistance } from 'date-fns'
import { FC } from 'react'

interface Props {
  admin?: boolean
  favorite?: boolean
  report: Report
  select(id: string, type: 'edit' | 'download' | 'log'): void
  toggleFavorite(id: string): void
}

const FavoriteIcon: FC<{ favorite: boolean }> = ({ favorite }) =>
  favorite ? (
    <StarIcon fontSize="inherit" color="secondary" />
  ) : (
    <StarBorderIcon fontSize="inherit" />
  )

const ReportCard: FC<Props> = ({
  admin = false,
  favorite = false,
  report,
  select,
  toggleFavorite
}) => {
  return (
    <Box className="report-card">
      <Box>
        <Typography
          className="report-card-title"
          onClick={() => select(report.id, 'download')}
          color="primary"
          variant="subtitle1"
        >
          {report.name}
        </Typography>

        <Typography
          className="report-card-info"
          variant="caption"
          color="textSecondary"
        >
          {`Downloads: ${report.downloads.length}`} &emsp;
          {`Last Download: ${
            report.lastDownload
              ? `${formatDistance(new Date(), report.lastDownload)} ago`
              : 'N/A'
          }`}
        </Typography>
      </Box>
      <Box className="report-card-icon-box">
        {admin && (
          <IconButton
            className="report-card-edit-icon"
            title="Edit"
            onClick={() => select(report.id, 'edit')}
            size="large"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        )}

        {admin && (
          <IconButton
            className="report-card-log-icon"
            title="Log"
            onClick={() => select(report.id, 'log')}
            size="large"
          >
            <DescriptionIcon fontSize="inherit" />
          </IconButton>
        )}

        <IconButton
          className="report-card-download-icon"
          title="Download"
          onClick={() => select(report.id, 'download')}
          size="large"
        >
          <CloudDownloadIcon fontSize="inherit" />
        </IconButton>

        <IconButton
          className="report-card-favorite-icon"
          title="Toggle favorite"
          onClick={() => toggleFavorite(report.id)}
          size="large"
        >
          <FavoriteIcon favorite={favorite} />
        </IconButton>
      </Box>
    </Box>
  )
}

export default ReportCard
