import { User } from '@models'
import { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Access from 'routes/access/Access'
import ForgotPassword from 'routes/auth/paths/forgot-password/ForgotPassword'
import ResetPassword from 'routes/auth/paths/reset-password/ResetPassword'
import SignIn from 'routes/auth/paths/sign-in/SignIn'
import Company from 'routes/company/Company'
import Dashboards from 'routes/dashboards/Dashboards'
import Fulfillments from 'routes/fulfillments/Fulfillments'
import QBInstructions from 'routes/quickbooks/paths/instructions/QBInstructions'
import QuickBooks from 'routes/quickbooks/QuickBooks'
import Reports from 'routes/reports/Reports'

interface RouteProps {
  component: FC
  path: string
}

interface Props {
  user?: User
}

const Router: FC<Props> = ({ user }) => {
  const PublicRoute: FC<RouteProps> = ({ component: Component, path }) => (
    <Route path={path}>
      {!user ? (
        <Component />
      ) : (
        <Redirect to={user.isCompany ? '/company' : '/reports'} />
      )}
    </Route>
  )

  const CompanyRoute: FC<RouteProps> = ({ component: Component, path }) => (
    <Route path={path}>
      {user && user.isCompany ? <Component /> : <Redirect to="/auth/sign-in" />}
    </Route>
  )

  const BaseRoute: FC<RouteProps> = ({ component: Component, path }) => {
    if (!user)
      return (
        <Route path={path}>
          <Redirect to="/auth/sign-in" />
        </Route>
      )

    return (
      <Route path={path}>
        {user.isCompany ? <Redirect to="/company" /> : <Component />}
      </Route>
    )
  }

  const AdminRoute: FC<RouteProps> = ({ component: Component, path }) => (
    <Route path={path}>
      {user && user.isAdmin ? <Component /> : <Redirect to="/reports" />}
    </Route>
  )

  return (
    <Switch>
      <PublicRoute path="/auth/sign-in" component={SignIn} />
      <PublicRoute path="/auth/forgot-password" component={ForgotPassword} />
      <PublicRoute path="/auth/reset-password" component={ResetPassword} />
      <PublicRoute path="/quickbooks/instructions" component={QBInstructions} />

      <CompanyRoute path="/company" component={Company} />

      <BaseRoute path="/reports" component={Reports} />
      <BaseRoute path="/fulfillments" component={Fulfillments} />
      <BaseRoute path="/quickbooks" component={QuickBooks} />

      <AdminRoute path="/dashboards" component={Dashboards} />
      <AdminRoute path="/access" component={Access} />

      <Route path="/">
        <Redirect to="/reports" />
      </Route>
    </Switch>
  )
}

export default Router
