import { ApiError } from '@models'
import { Paper } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FC, ReactNode } from 'react'
import { UseQueryResult } from 'react-query'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { GraphType } from '../PayrollsInvoiced'

export interface InvoicedPayroll {
  invoiceDate: string
  payrollsInvoiced: number
  amountInvoiced: number
}

interface Props {
  query: UseQueryResult<InvoicedPayroll[], ApiError>
  type: GraphType
}

const PayrollsInvoicedGraph: FC<Props> = ({ query, type }) => {
  const theme = useTheme()

  const Chart = type === 'bar' ? BarChart : LineChart

  const Payrolls =
    type === 'bar' ? (
      <Bar
        yAxisId="left"
        dataKey="payrollsInvoiced"
        fill={theme.palette.primary.main}
        name="Payrolls Invoiced"
      />
    ) : (
      <Line
        yAxisId="left"
        dataKey="payrollsInvoiced"
        stroke={theme.palette.primary.main}
        name="Payrolls Invoiced"
        strokeWidth={2}
        type="monotone"
      />
    )

  const Amounts =
    type === 'bar' ? (
      <Bar
        yAxisId="right"
        dataKey="amountInvoiced"
        fill={theme.palette.success.main}
        name="Amount Invoiced"
      />
    ) : (
      <Line
        yAxisId="right"
        dataKey="amountInvoiced"
        stroke={theme.palette.success.main}
        name="Amount Invoiced"
        strokeWidth={2}
        type="monotone"
      />
    )

  return (
    <Paper sx={{ mt: 2, pt: 2, px: 1 }} data-testid="payrolls-invoiced-graph">
      <ResponsiveContainer width="100%" height={700}>
        <Chart
          data={query.data}
          margin={{
            top: 20,
            right: 35,
            left: 0,
            bottom: 20
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis dataKey="invoiceDate" interval="preserveStartEnd" />

          {/* payrolls invoiced */}
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke={theme.palette.primary.main}
          />

          {/* amount invoiced */}
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={val => '$' + new Intl.NumberFormat('en').format(val)}
            stroke={theme.palette.success.main}
          />

          <Tooltip
            formatter={(val: any, name: string): ReactNode =>
              name.includes('Amount')
                ? '$' + new Intl.NumberFormat('en').format(val)
                : val
            }
          />
          <Legend />

          {query.isSuccess && Payrolls}

          {query.isSuccess && Amounts}
        </Chart>
      </ResponsiveContainer>
    </Paper>
  )
}

export default PayrollsInvoicedGraph
