import { useConnectionDetails } from '@api/queries'
import { Breadcrumb, TabsPanel } from '@common'
import { ConnectionEntityType, ConnectionStatus } from '@models'
import { Box } from '@mui/material'
import { FC, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import ConnectionDetailsTab from './tabs/details/ConnectionDetailsTab'
import ConnectionExportsTab from './tabs/exports/ConnectionExportsTab'
import ConnectionMappingTab from './tabs/mapping/ConnectionMappingTab'
import ConnectionPreferencesTab from './tabs/preferences/ConnectionPreferencesTab'

interface UrlProps {
  connectionId: string
}

const QBConnectionPage: FC = () => {
  const { connectionId } = useParams<UrlProps>()
  const fetchConnection = useConnectionDetails(connectionId)

  const breadcrumbs = useMemo(
    () => [
      { label: 'QuickBooks', path: '/quickbooks' },
      { label: 'Connections', path: '/quickbooks/connections' },
      { label: fetchConnection.data?.companyName ?? '...' }
    ],
    [fetchConnection.data]
  )

  return (
    <Box>
      {fetchConnection.isSuccess &&
        fetchConnection.data.status !== ConnectionStatus.Connected && (
          <Redirect to="/quickbooks/connections" />
        )}
      <Breadcrumb crumbs={breadcrumbs} />

      <TabsPanel
        tabs={[
          { name: 'details', component: ConnectionDetailsTab },
          { name: 'preferences', component: ConnectionPreferencesTab },
          {
            name:
              fetchConnection.data?.entityType === ConnectionEntityType.Reseller
                ? 'mappings'
                : 'mapping',
            component: ConnectionMappingTab
          },
          { name: 'exports', component: ConnectionExportsTab }
        ]}
      />
    </Box>
  )
}

export default QBConnectionPage
