import { AccessCompanyReport, ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useAccessCompanyReports = (): UseQueryResult<
  AccessCompanyReport[],
  ApiError
> =>
  useQuery(['access', 'companies', 'reports'], () =>
    axios.get('/access/companies/reports').then(res => res.data)
  )

export default useAccessCompanyReports
