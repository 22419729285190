import { ApiError, Group } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  name: string
  companyId: string
}

const useCreateGroup = (): UseMutationResult<Group, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload => axios.post<Group>('/groups', payload).then(res => res.data),
    {
      mutationKey: 'create group',
      onSuccess: res => {
        queryClient.setQueryData<Group[] | undefined>(
          ['groups'],
          data => data && [...data, res]
        )
        queryClient.setQueryData(['groups', res.id], res)
        enqueueSnackbar(`Group created`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create group', { variant: 'error' })
      }
    }
  )
}
export default useCreateGroup
