import { useCreateReport } from '@api/mutations'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import ReportFormModal, { ReportFormData } from './ReportFormModal'

interface Props {
  open: boolean
  tags: string[]
  onClose(): void
}

const CreateReportModal: FC<Props> = ({ open, tags, onClose }) => {
  const createReportForm = useForm<ReportFormData>({
    defaultValues: new ReportFormData()
  })

  const createReportMut = useCreateReport()

  const onSubmit: SubmitHandler<ReportFormData> = data => {
    delete data.formSettings
    createReportMut.mutate(data, { onSuccess: onClose })
  }

  return (
    <ReportFormModal
      open={open}
      form={createReportForm}
      tags={tags}
      title="Create Report"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default CreateReportModal
