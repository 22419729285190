import { InputProps } from '@models'
import { Checkbox as MatCheckbox, FormControlLabel } from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'

interface CheckboxProps extends InputProps {
  isDisabled?: boolean
  small?: boolean
}

const Checkbox: FC<CheckboxProps> = ({
  control,
  name,
  defaultValue = false,
  label,
  isDisabled = false,
  small = false
}) => {
  const {
    field: { ref, ...field }
  } = useController({
    control,
    name,
    defaultValue
  })

  return (
    <FormControlLabel
      control={
        <MatCheckbox
          {...field}
          checked={field.value}
          color="primary"
          inputRef={ref}
          size={small ? 'small' : 'medium'}
          disabled={isDisabled}
        />
      }
      label={label ?? ''}
    />
  )
}

export default Checkbox
