import { ApiError, Report } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Pick<Report, 'name' | 'tags' | 'queries' | 'options'>

const useUpdateReport = (
  reportId: string
): UseMutationResult<Report, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios.patch<Report>(`/reports/${reportId}`, payload).then(res => res.data),
    {
      mutationKey: 'update report',
      onSuccess: res => {
        queryClient.setQueryData<Report[] | undefined>(
          ['reports'],
          data => data && data.map(d => (d.id === res.id ? res : d))
        )
        enqueueSnackbar('Report updated', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update report', { variant: 'error' })
      }
    }
  )
}

export default useUpdateReport
