import { ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

type Response = Array<{
  id: string
  name: string
}>

const useAccessCompanyCompSelectors = (
  resellerId: string | undefined
): UseQueryResult<Response, ApiError> =>
  useQuery(
    ['access', 'companies', 'selectors', 'companies', resellerId],
    () =>
      axios
        .get<Response>(`/access/companies/selectors/companies/${resellerId}`)
        .then(res => res.data),
    {
      enabled: !!resellerId,
      select: data => data.map(d => ({ id: d.id + '', name: d.name }))
    }
  )

export default useAccessCompanyCompSelectors
