import { useCreateFulfillmentPickup } from '@api/mutations'
import { FormDialog } from '@common'
import { Input } from '@common/inputs/form'
import { Fulfillment } from '@models'
import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import FulfillmentChecksTable from './FulfillmentChecksTable'

interface CreatePickupForm {
  name: string
  checkIds: number[]
}

interface Props {
  fulfillment?: Fulfillment
  onClose(): void
}

const CreatePickupModal: FC<Props> = ({ fulfillment, onClose }) => {
  const createPickupMut = useCreateFulfillmentPickup()
  const createPickupForm = useForm<CreatePickupForm>({
    defaultValues: { name: '', checkIds: [] }
  })

  useEffect(() => {
    if (fulfillment) createPickupForm.reset({ name: '', checkIds: [] })
  }, [fulfillment])

  const onSubmit: SubmitHandler<CreatePickupForm> = data => {
    if (fulfillment)
      createPickupMut.mutate(
        {
          id: fulfillment.id,
          data
        },
        { onSuccess: onClose }
      )
  }

  return (
    <FormDialog
      form={createPickupForm}
      open={!!fulfillment}
      title="Add Pickup"
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <Input label="Name" name="name" variant="outlined" />

      <FulfillmentChecksTable fulfillment={fulfillment} />
    </FormDialog>
  )
}

export default CreatePickupModal
