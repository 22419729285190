import { useFulfillmentChecks } from '@api/queries'
import { DataTable } from '@common/table'
import { Fulfillment } from '@models'
import { Checkbox } from '@mui/material'
import { Box } from '@mui/system'
import { GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { Control, useController } from 'react-hook-form'

interface Props {
  fulfillment?: Fulfillment
  control?: Control<{
    name: string
    checkIds: number[]
  }>
}

const FulfillmentChecksTable: FC<Props> = ({ fulfillment, control }) => {
  const { field } = useController({
    control,
    name: 'checkIds',
    defaultValue: []
  })

  const fetchFulfillmentChecks = useFulfillmentChecks(fulfillment?.id, data =>
    data.filter(d => {
      let include = true
      if (fulfillment?.pickups?.length)
        for (const pickup of fulfillment.pickups) {
          if (pickup.checkIds.includes(d.id)) include = false
        }

      return include
    })
  )

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'name', headerName: 'Name', flex: 1 },
      {
        field: 'selected',
        width: 200,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box>
            <strong>Select</strong>
            <Checkbox
              disabled={!fetchFulfillmentChecks.isSuccess}
              checked={field.value.length === fetchFulfillmentChecks.data?.length}
              indeterminate={
                field.value.length > 0 &&
                field.value.length < (fetchFulfillmentChecks.data?.length ?? false)
              }
              onClick={() => toggleAllRows()}
              inputProps={{
                title: 'Toggle all'
              }}
            />
          </Box>
        ),
        renderCell: ({ row }) => {
          const isSelected = field.value.includes(row.id)
          return (
            <Checkbox
              checked={isSelected}
              onClick={() =>
                field.onChange(
                  isSelected
                    ? field.value.filter(id => id !== row.id)
                    : [...field.value, row.id]
                )
              }
            />
          )
        }
      }
    ],
    [fetchFulfillmentChecks, field]
  )

  const toggleAllRows = () => {
    if (field.value.length === fetchFulfillmentChecks.data?.length)
      field.onChange([])
    else field.onChange(fetchFulfillmentChecks.data?.map(c => c.id) ?? [])
  }

  return (
    <DataTable
      query={fetchFulfillmentChecks}
      columns={columns}
      errorMessage="Failed to fetch checks"
      height={450}
      toolbar={{ column: false, exportable: false, filter: false, density: false }}
    />
  )
}

export default FulfillmentChecksTable
