import { Add, Clear, Search } from '@mui/icons-material'
import { Box, Button, IconButton, TextField } from '@mui/material'
import {
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid'
import { FC, ReactElement } from 'react'

export interface ToolbarProps {
  options: {
    column?: boolean
    density?: boolean
    exportable?: boolean
    filter?: boolean
  }
  csvOptions?: GridCsvExportOptions
  customButton?: ReactElement
  search?: {
    value: string
    onChange(event: React.ChangeEvent<HTMLInputElement>): void
    clearSearch(): void
  }
  addNewRow?(): void
}

export const DataTableToolbar: FC<ToolbarProps> = ({
  options = { column: true, density: true, exportable: false, filter: true },
  csvOptions,
  customButton,
  search,
  addNewRow
}) => (
  <Box
    sx={{
      py: 1,
      px: 3,
      display: 'flex',
      alignItems: 'center',
      ['& .MuiSvgIcon-root']: { mr: 0.5 },
      ['& .MuiInput-underline:before']: {
        borderBottom: theme => `1px solid ${theme.palette.divider}`
      }
    }}
  >
    {!!search && (
      <TextField
        placeholder="Search..."
        id="table-searchbar"
        value={search.value}
        onChange={search.onChange}
        size="small"
        InputProps={{
          startAdornment: <Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear search"
              size="small"
              style={{ visibility: search.value ? 'visible' : 'hidden' }}
              onClick={search.clearSearch}
            >
              <Clear fontSize="small" />
            </IconButton>
          )
        }}
      />
    )}

    <Box ml={3} flexGrow={1} display={{ xs: 'none', sm: 'block' }}>
      {options.column && <GridToolbarColumnsButton variant="text" />}
      {options.filter && (
        <GridToolbarFilterButton componentsProps={{ button: { variant: 'text' } }} />
      )}

      {options.density && <GridToolbarDensitySelector variant="text" />}
      {options.exportable && (
        <GridToolbarExport variant="text" csvOptions={csvOptions} />
      )}
    </Box>

    <Box
      sx={{
        display: { xs: 'none', sm: 'flex' }
      }}
    >
      {addNewRow && (
        <Button
          title="New item"
          id="new-row-button"
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ ml: 4 }}
          endIcon={<Add />}
          onClick={addNewRow}
        >
          New
        </Button>
      )}

      {customButton}
    </Box>
  </Box>
)
