import axios from 'axios'
import { FC } from 'react'
import AppProviders from './AppProviders'
import Main from './Main'

const App: FC = () => {
  axios.defaults.baseURL =
    process.env.NODE_ENV === 'development'
      ? `http://${window.location.hostname}:5000/api`
      : '/api'

  const token = localStorage.getItem('token')

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

  return (
    <AppProviders>
      <Main />
    </AppProviders>
  )
}

export default App
