import { parseISO } from 'date-fns'

// model

export default class QBConnection {
  public id: string
  public agentToken: string
  public companyId: string
  public companyName: string
  public contactEmail: string
  public endpointId: string
  public inviteToken: string
  public userId: string

  public connectionType: ConnectionType
  public entityType: ConnectionEntityType
  public status: ConnectionStatus

  public preferences: ConnectionPreferences

  public invites: ConnectionInvite[]
  public lines: ConnectionLine[]
  public exports: ConnectionExport[]
  public mappings: ConnectionMapping[]

  public accounts: ConnectionAccount[]
  public classes: ConnectionClass[]
  public customers: ConnectionCustomer[]
  public items: ConnectionItem[]

  public createdAt: Date | string
  public updatedAt: Date | string

  constructor(connection: Partial<QBConnection>) {
    this.id = connection.id || ''
    this.agentToken = connection.agentToken || ''
    this.companyId = connection.companyId || ''
    this.companyName = connection.companyName || ''
    this.contactEmail = connection.contactEmail || ''

    this.endpointId = connection.endpointId || ''
    this.inviteToken = connection.inviteToken || ''
    this.userId = connection.userId || ''

    this.connectionType = connection.connectionType || ConnectionType.Desktop
    this.entityType = connection.entityType || ConnectionEntityType.Company
    this.status = connection.status || ConnectionStatus.Created

    this.preferences = connection.preferences || {
      accounts: [],
      classes: [],
      customers: [],
      items: []
    }

    this.invites = connection.invites || []
    this.lines = connection.lines || []
    this.exports = connection.exports || []
    this.mappings = connection.mappings || []

    this.accounts = connection.accounts || []
    this.classes = connection.classes || []
    this.customers = connection.customers || []
    this.items = connection.items || []

    this.createdAt =
      typeof connection.createdAt === 'string'
        ? parseISO(connection.createdAt)
        : connection.createdAt || new Date()

    this.updatedAt =
      typeof connection.updatedAt === 'string'
        ? parseISO(connection.updatedAt)
        : connection.updatedAt || new Date()
  }

  printConnectionType(): string {
    switch (this.connectionType) {
      case ConnectionType.Desktop:
        return 'Desktop'
      case ConnectionType.Online:
        return 'Online'
      default:
        return 'N/A'
    }
  }

  printEntityType(): string {
    switch (this.entityType) {
      case ConnectionEntityType.Company:
        return 'Company'
      case ConnectionEntityType.Reseller:
        return 'Reseller'
      default:
        return 'N/A'
    }
  }

  printStatus(): string {
    switch (this.status) {
      case ConnectionStatus.Created:
        return 'Created'
      case ConnectionStatus.InvitationSent:
        return 'Invitation Sent'
      case ConnectionStatus.OpenedEmail:
        return 'Opened Email'
      case ConnectionStatus.Connected:
        return 'Connected'
      default:
        return 'N/A'
    }
  }
}

// SCHEMAS

export type ConnectionList = Pick<
  QBConnection,
  | 'id'
  | 'companyId'
  | 'companyName'
  | 'entityType'
  | 'connectionType'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'contactEmail'
>[]

export type ConnectionDetails = Pick<
  QBConnection,
  | 'id'
  | 'companyId'
  | 'companyName'
  | 'entityType'
  | 'connectionType'
  | 'status'
  | 'contactEmail'
  | 'endpointId'
  | 'agentToken'
  | 'userId'
  | 'preferences'
  | 'createdAt'
  | 'updatedAt'
>

// invite
export interface ConnectionInvite {
  contactEmail: string
  sendDate: Date | string
}

// line
export class ConnectionLine {
  public _id?: string
  public accountId: string
  public accountName: string
  public type: ConnectionLineType
  public memo: string
  public selector: ConnectionLineSelector

  constructor(line: Omit<ConnectionLine, 'printType' | 'printSelectorType'>) {
    this._id = line._id
    this.accountId = line.accountId
    this.accountName = line.accountName
    this.type = line.type
    this.memo = line.memo
    this.selector = line.selector
  }

  printType?(): string {
    switch (this.type) {
      case ConnectionLineType.Credit:
        return 'Credit'
      case ConnectionLineType.Debit:
        return 'Debit'
      default:
        return 'N/A'
    }
  }

  printSelectorType?(): string {
    switch (this.selector.type) {
      case ConnectionLineSelectorType.EEDeductions:
        return 'EE Deductions'
      case ConnectionLineSelectorType.EETaxes:
        return 'EE Taxes'
      case ConnectionLineSelectorType.ERBenefits:
        return 'ER Benefits'
      case ConnectionLineSelectorType.ERTaxes:
        return 'ER Taxes'
      case ConnectionLineSelectorType.InvoiceAmounts:
        return 'Invoice Amounts'
      case ConnectionLineSelectorType.WagesNetWages:
        return 'Wages: Net Wages'
      case ConnectionLineSelectorType.WagesPayTypes:
        return 'Wages: Pay Types'
      default:
        return 'N/A'
    }
  }
}

// export
export class ConnectionExport {
  id: string
  connection: string
  payrollId: string
  payrollNumber: string
  companyId: string
  companyName: string
  mappingId?: string
  type: ConnectionExportType
  request?: any

  createdAt: Date

  constructor(ex: ConnectionExport) {
    this.id = ex.id
    this.connection = ex.connection
    this.payrollId = ex.payrollId
    this.payrollNumber = ex.payrollNumber
    this.companyId = ex.companyId
    this.companyName = ex.companyName
    this.mappingId = ex.mappingId
    this.type = ex.type
    this.request = ex.request

    this.createdAt =
      typeof ex.createdAt === 'string'
        ? parseISO(ex.createdAt)
        : ex.createdAt || new Date()
  }
}

// itemLine
export class ConnectionItemLine {
  public _id?: string
  public itemId: string
  public itemName: string
  public description: string
  public rate: number | string
  public classId: string
  public className: string
  public taxPayerEntityId: string
  public taxPayerEntityName: string
  public selector: ConnectionLineSelector

  constructor(line: Omit<ConnectionItemLine, 'printSelectorType'>) {
    this._id = line._id
    this.itemId = line.itemId
    this.itemName = line.itemName
    this.description = line.description
    this.rate = line.rate
    this.classId = line.classId
    this.className = line.className
    this.taxPayerEntityId = line.taxPayerEntityId
    this.taxPayerEntityName = line.taxPayerEntityName
    this.selector = line.selector
  }

  printSelectorType?(): string {
    switch (this.selector.type) {
      case ConnectionLineSelectorType.EEDeductions:
        return 'EE Deductions'
      case ConnectionLineSelectorType.EETaxes:
        return 'EE Taxes'
      case ConnectionLineSelectorType.ERBenefits:
        return 'ER Benefits'
      case ConnectionLineSelectorType.ERTaxes:
        return 'ER Taxes'
      case ConnectionLineSelectorType.InvoiceAmounts:
        return 'Invoice Amounts'
      case ConnectionLineSelectorType.WagesNetWages:
        return 'Wages: Net Wages'
      case ConnectionLineSelectorType.WagesPayTypes:
        return 'Wages: Pay Types'
      default:
        return 'N/A'
    }
  }
}

// mapping
export class ConnectionMapping {
  public _id: string
  public companyId: string
  public companyName: string
  public customerId: string
  public customerName: string
  public classId: string
  public className: string
  public lines: ConnectionItemLine[]
  public updatedAt: Date | string

  constructor(map: ConnectionMapping) {
    this._id = map._id
    this.companyId = map.companyId
    this.companyName = map.companyName
    this.customerId = map.customerId
    this.customerName = map.customerName
    this.classId = map.classId
    this.className = map.className
    this.lines = map.lines
    this.updatedAt =
      typeof map.updatedAt === 'string'
        ? parseISO(map.updatedAt)
        : map.updatedAt || new Date()
  }
}

// AUTOFY

// account
export interface ConnectionAccount {
  id: string
  accountId: string
  number: string
  name: string
  active: string
  type: string
  fullName: string
  description: string
  detailType: string
  bankNumber: string
}

// class
export interface ConnectionClass {
  classId: string
  fullName: string
  active: boolean
}

// item
export interface ConnectionItem {
  itemId: string
  type: string
  fullName: string
  active: boolean
}

// customer
export interface ConnectionCustomer {
  customerId: string
  fullName: string
  active: boolean
}

// SUB SCHEMAS
export interface ConnectionPreferences {
  accounts: string[]
  classes: string[]
  customers: string[]
  items: string[]
}
export interface ConnectionLineSelector {
  type: ConnectionLineSelectorType
  params: string[]
}

// ENUMS
export enum ConnectionType {
  Desktop = 'DESKTOP',
  Online = 'ONLINE'
}
export enum ConnectionStatus {
  Created = 'CREATED',
  InvitationSent = 'INVITATION_SENT',
  OpenedEmail = 'OPENED_EMAIL',
  Connected = 'CONNECTED'
}
export enum ConnectionEntityType {
  Reseller = 'RESELLER',
  Company = 'COMPANY'
}
export enum ConnectionLineType {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}
export enum ConnectionLineSelectorType {
  EEDeductions = 'EE_DEDUCTIONS',
  EETaxes = 'EE_TAXES',
  ERBenefits = 'ER_BENEFITS',
  ERTaxes = 'ER_TAXES',
  InvoiceAmounts = 'INVOICE_AMOUNTS',
  WagesNetWages = 'WAGES:NET_WAGES',
  WagesPayTypes = 'WAGES:PAY_TYPES'
}
export enum ConnectionExportType {
  JournalEntry = 'JOURNAL_ENTRY',
  Invoice = 'Invoice'
}

// OTHER

export interface ConnectionExportPayroll {
  id: number | string
  companyId: number | string
  companyName: string
  payrollNumber: string
  checkDate: string
  mappingId: string
}
