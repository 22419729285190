import { useConnectionDetails, useConnectionTest, usePayrolls } from '@api/queries'
import { DataPreviewer, Modal } from '@common'
import { Autocomplete } from '@common/inputs/basic'
import { ConnectionLineType } from '@models'
import { Check, Error } from '@mui/icons-material'
import { Box, Collapse, Divider, Tooltip, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { formatDate } from '@utils'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

const tableColumns: GridColumns = [
  { field: 'AccountID', headerName: 'ID', flex: 1, hide: true },
  { field: 'AccountName', headerName: 'Account', flex: 2.5 },
  { field: 'Memo', headerName: 'Memo', flex: 1.5 },
  {
    field: 'Type',
    headerName: 'Type',
    flex: 1,
    type: 'singleSelect',
    valueOptions: [ConnectionLineType.Credit, ConnectionLineType.Debit],
    valueFormatter: ({ value }) =>
      (value === ConnectionLineType.Credit && 'Credit') ||
      (value === ConnectionLineType.Debit && 'Debit')
  },
  { field: 'Amount', headerName: 'Amount', flex: 1, type: 'number' }
]

interface TestResponse {
  invoiceCost: number
  journalEntry: {
    Date: string | Date
    Lines: Array<{
      Account: { ID: string; FullName: string }
      Type: ConnectionLineType
      Memo: string
      Amount: number
    }>
  }
}

interface Props {
  open: boolean
  onClose(): void
}

const TestConnectionModal: FC<Props> = ({ open, onClose }) => {
  const { connectionId } = useParams<{ connectionId: string }>()

  const [payrollId, setPayrollId] = useState('')

  const fetchConnection = useConnectionDetails(connectionId)
  const fetchPayrolls = usePayrolls(
    fetchConnection.data?.companyId,
    open && fetchConnection.isSuccess
  )
  const fetchConnectionTest = useConnectionTest(connectionId, payrollId)

  const closeModal = () => {
    setPayrollId('')
    onClose()
  }

  const getTotal = (type: ConnectionLineType) =>
    Number(
      fetchConnectionTest.data?.journalEntry.Lines.filter(l => l.Type === type)
        .reduce((a, b) => a + b.Amount, 0)
        .toFixed(2) ?? 0
    )

  const totals = useMemo(
    () => ({
      credit: getTotal(ConnectionLineType.Credit),
      debit: getTotal(ConnectionLineType.Debit)
    }),
    [fetchConnectionTest.data]
  )

  const amountsValid = useMemo(() => {
    if (!fetchConnectionTest.isSuccess) return false

    const invoiceCost = fetchConnectionTest.data.invoiceCost

    if (
      totals.credit !== totals.debit ||
      totals.credit !== invoiceCost ||
      totals.debit !== invoiceCost
    )
      return false
    return true
  }, [fetchConnectionTest.data])

  return (
    <Modal open={open} title="Test" onClose={closeModal}>
      <Autocomplete
        value={payrollId}
        onChange={val => setPayrollId(val)}
        label="Payroll"
        name="payrollId"
        optionLabelKey="name"
        query={fetchPayrolls}
        disableClearable
        getOptionLabel={option =>
          `${option.companyPayrollId} - ${formatDate(
            new Date(option.checkDate),
            'PP'
          )}`
        }
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Typography variant="body1" mr={1}>
              {option.companyPayrollId}
            </Typography>
            -
            <Typography variant="body2" ml={1}>
              {formatDate(new Date(option.checkDate), 'P')}
            </Typography>
          </Box>
        )}
      />

      <Collapse
        in={fetchConnectionTest.isSuccess || fetchConnectionTest.isLoading}
        mountOnEnter
        unmountOnExit
      >
        <DataPreviewer
          query={fetchConnectionTest}
          tableColumns={tableColumns}
          fileName={`TestExport_${fetchConnectionTest.data?.journalEntry.Date}`}
          getRows={(data: TestResponse) =>
            data.journalEntry.Lines.map((line, index) => ({
              id: index,
              AccountName: line.Account.FullName,
              AccountID: line.Account.ID,
              Type: line.Type,
              Memo: line.Memo,
              Amount: line.Amount
            }))
          }
          header={
            <Typography gutterBottom>
              Date: {fetchConnectionTest.data?.journalEntry.Date}
            </Typography>
          }
          footer={
            <>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <div>
                  <Typography>
                    <strong>Credit:</strong> ${totals.credit}
                  </Typography>
                  <Typography>
                    <strong>Debit:</strong> ${totals.debit}
                  </Typography>
                  <Typography>
                    <strong>Invoice Cost:</strong> $
                    {fetchConnectionTest.data?.invoiceCost}
                  </Typography>
                </div>

                {amountsValid ? (
                  <Tooltip title="Amounts add up" placement="top">
                    <Check color="success" fontSize="large" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Amounts do not match" placement="top">
                    <Error color="error" fontSize="large" />
                  </Tooltip>
                )}
              </Box>
            </>
          }
        />
      </Collapse>
    </Modal>
  )
}

export default TestConnectionModal
