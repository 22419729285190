import { useCompanyReports } from '@api/queries'
import { DataTable } from '@common/table'
import { AccessCompanyReport } from '@models'
import { CloudDownload } from '@mui/icons-material'
import { Box, Chip } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'
import { Selected } from '../CompanyReports'

interface Props {
  tags: string[]
  toggleTag(tag: string): void
  setSelected(params: Selected): void
}

const CompanyReportsTable: FC<Props> = ({ tags, toggleTag, setSelected }) => {
  const fetchCompanyReports = useCompanyReports(reports =>
    reports
      .filter(rep => !tags.length || tags.every(t => rep.tags.includes(t)))
      .sort((a, b) => {
        if (a.name > b.name) return 1
        else if (a.name < b.name) return -1
        else return 0
      })
  )

  const columns = useMemo<GridColumns>(() => {
    const isSelected = (tag: string) => tags.includes(tag)
    return [
      { field: 'name', headerName: 'Name', flex: 1 },
      {
        field: 'tags',
        headerName: 'Tags',
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => row.tags.toString(),
        renderCell: ({ id, row }) => {
          const cellTags: string[] = row.tags

          return (
            <Box
              key={`${id}-tagsCell`}
              sx={{
                ['.MuiChip-root']: { mr: 1 }
              }}
            >
              {cellTags
                .sort((a, b) => {
                  if (isSelected(a) && !isSelected(b)) return -1
                  else if (!isSelected(a) && isSelected(b)) return 1
                  else {
                    if (a > b) return 1
                    else if (a < b) return -1
                    else return 0
                  }
                })
                .map((tag, index) => (
                  <Chip
                    key={`${id}-tag-${index}`}
                    label={tag}
                    onClick={() => toggleTag(tag)}
                    color={isSelected(tag) ? 'primary' : 'default'}
                  />
                ))}
            </Box>
          )
        }
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={`download-${id}`}
            icon={<CloudDownload sx={{ fontSize: 32 }} />}
            label="Download"
            size="large"
            onClick={() =>
              setSelected({ type: 'download', report: row as AccessCompanyReport })
            }
          />
        ]
      }
    ]
  }, [fetchCompanyReports.status, tags])

  return (
    <DataTable
      query={fetchCompanyReports}
      columns={columns}
      defaultSort="name"
      errorMessage="Failed to fetch reports"
      density="comfortable"
      height={720}
    />
  )
}

export default CompanyReportsTable
