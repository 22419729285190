import { ApiError, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Pick<QBConnection, 'contactEmail'>

const useSendQuickbooksInvite = (
  connectionId?: string
): UseMutationResult<QBConnection, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<QBConnection>(
          `/quickbooks/connections/${connectionId}/invites`,
          payload
        )
        .then(res => res.data),
    {
      mutationKey: 'send invite',
      onSuccess: res => {
        queryClient.setQueryData<QBConnection[] | undefined>(
          ['quickbooks', 'connections'],
          data => data && data.map(d => (d.id === res.id ? res : d))
        )
        queryClient.setQueryData<QBConnection>(
          ['quickbooks', 'connections', res.id],
          res
        )
        enqueueSnackbar('Invite sent', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to send invite', { variant: 'error' })
      }
    }
  )
}

export default useSendQuickbooksInvite
