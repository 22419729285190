import { ApiError, Company, QuerySelect } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useCompanies = (
  enabled?: boolean,
  select?: QuerySelect<Company[]>
): UseQueryResult<Company[], ApiError> =>
  useQuery(
    ['companies'],
    () => axios.get<Company[]>('/companies').then(res => res.data),
    { enabled, select }
  )

export default useCompanies
