import { useAccessCompanies } from '@api/queries'
import { Link } from '@common'
import { DataTable } from '@common/table'
import { GridColumns } from '@mui/x-data-grid'
import { FC, useMemo } from 'react'

interface Props {
  openForm(): void
}

const AccessCompaniesTable: FC<Props> = ({ openForm }) => {
  const fetchCompanies = useAccessCompanies()

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
        renderCell: ({ value, id }) => (
          <Link label={value} path={`/access/companies/${id}`} />
        )
      },
      {
        field: 'resellerName',
        headerName: 'Reseller',
        flex: 1
      }
    ],
    [fetchCompanies.status]
  )

  return (
    <DataTable
      query={fetchCompanies}
      columns={columns}
      defaultSort="companyName"
      errorMessage="Failed to fetch companies"
      addNewRow={openForm}
      height={625}
    />
  )
}

export default AccessCompaniesTable
