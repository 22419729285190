import { useUpdateGroup } from '@api/mutations'
import { useGroup } from '@api/queries'
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

const GroupQuickBooksRights: FC = () => {
  const { groupId } = useParams<{ groupId: string }>()

  const fetchGroup = useGroup(groupId)
  const updateGroupMut = useUpdateGroup(groupId)

  const [qbConnectionManagement, setQbConnectionManagement] = useState(
    fetchGroup.data?.qbConnectionManagement ?? false
  )

  const isDisabled = (): boolean => {
    if (updateGroupMut.isLoading || fetchGroup.isFetching || !fetchGroup.data)
      return true

    return qbConnectionManagement === fetchGroup.data?.qbConnectionManagement
  }

  return (
    <>
      <Box height={500}>
        <List role="list">
          <ListItem
            role="listitem"
            button
            tabIndex={-1}
            onClick={() => setQbConnectionManagement(!qbConnectionManagement)}
          >
            <ListItemIcon>
              <Checkbox
                checked={qbConnectionManagement}
                disableRipple
                color="primary"
              />
            </ListItemIcon>
            <ListItemText primary={<Typography>Connection Management</Typography>} />
          </ListItem>
        </List>
      </Box>

      <Box
        sx={{
          mt: 2,
          '& button': {
            mr: 2
          }
        }}
      >
        <Button
          onClick={() =>
            setQbConnectionManagement(
              fetchGroup.data?.qbConnectionManagement ?? false
            )
          }
          disabled={isDisabled()}
          color="inherit"
        >
          Cancel
        </Button>

        <Button
          onClick={() => updateGroupMut.mutate({ qbConnectionManagement })}
          disabled={isDisabled()}
          color="secondary"
        >
          Save
        </Button>
      </Box>
    </>
  )
}

export default GroupQuickBooksRights
