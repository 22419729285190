import { useUpdateFulfillment } from '@api/mutations'
import { useFulfillments } from '@api/queries'
import { DateRangeType, Fulfillment, FulfillmentStatus } from '@models'
import { Box, Checkbox, FormControlLabel } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { FC, useCallback, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import EditFulfillmentModal from 'routes/fulfillments/components/EditFulfillmentModal'
import FulfillmentsTable from 'routes/fulfillments/components/FulfillmentsTable'

type SelectedToMoveState = {
  fulfillment: Fulfillment
  newStatus: FulfillmentStatus
}

interface Props {
  dates: DateRangeType
}

const UnfulfilledTab: FC<Props> = ({ dates }) => {
  const [showIgnored, setShowIgnored] = useState(false)
  const [selectedToMove, setSelectedToMove] = useState<SelectedToMoveState>()

  const updateFulfillmentMut = useUpdateFulfillment()
  const fetchUnfulfilled = useFulfillments(dates, data =>
    data.filter(d =>
      showIgnored
        ? d.status === FulfillmentStatus.REMOVE_OR_NO_CHECKS_PRINTED
        : d.status === FulfillmentStatus.UNFULFILLED
    )
  )

  const tableColumns = useMemo<GridColumns>(
    () => [
      { field: 'companyName', headerName: 'Company', flex: 1 },
      { field: 'companyPayrollId', headerName: 'Payroll', flex: 0.5 },
      {
        field: 'checkDate',
        headerName: 'Check Date',
        flex: 0.5,
        type: 'date',
        valueGetter: ({ value }) => new Date(value)
      },
      {
        field: 'liveChecks',
        headerName: 'LC',
        flex: 0.25,
        type: 'number'
      },
      {
        field: 'directDeposits',
        headerName: 'DD',
        flex: 0.25,
        type: 'number'
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => {
          const fulfillment = row as Fulfillment
          return [
            <GridActionsCellItem
              key={`delivery-action-${id}`}
              label="Add to Deliveries"
              onClick={() =>
                setSelectedToMove({
                  fulfillment,
                  newStatus: FulfillmentStatus.DELIVERIES
                })
              }
              showInMenu
            />,
            <GridActionsCellItem
              key={`mail-action-${id}`}
              label="Add to Mailed"
              onClick={() =>
                setSelectedToMove({
                  fulfillment,
                  newStatus: FulfillmentStatus.MAILED
                })
              }
              showInMenu
            />,
            <GridActionsCellItem
              key={`pickup-action-${id}`}
              label="Add to Pickups"
              onClick={() =>
                setSelectedToMove({
                  fulfillment,
                  newStatus: FulfillmentStatus.PICKUPS
                })
              }
              showInMenu
            />,
            <GridActionsCellItem
              key={`delete-action-${id}`}
              label="Remove/No Checks Printed"
              onClick={() => removeFulfillment(fulfillment)}
              showInMenu
            />
          ]
        }
      }
    ],
    [fetchUnfulfilled]
  )

  const removeFulfillment = useCallback(
    (fulfillment: Fulfillment) => {
      updateFulfillmentMut.mutate({
        id: fulfillment.id,
        data: {
          status: FulfillmentStatus.REMOVE_OR_NO_CHECKS_PRINTED
        }
      })
    },
    [fetchUnfulfilled]
  )

  const onEditFulfillmentSubmit: SubmitHandler<{ note: string }> = ({ note }) => {
    if (selectedToMove)
      updateFulfillmentMut.mutate(
        {
          id: selectedToMove.fulfillment.id,
          data: {
            status: selectedToMove.newStatus,
            note
          }
        },
        {
          onSuccess: () => {
            setSelectedToMove(undefined)
          }
        }
      )
  }

  return (
    <Box>
      <EditFulfillmentModal
        fulfillment={selectedToMove?.fulfillment}
        title={`Add to ${selectedToMove?.newStatus.toLowerCase() ?? ''}`}
        onSubmit={onEditFulfillmentSubmit}
        onClose={() => setSelectedToMove(undefined)}
      />

      <FulfillmentsTable
        columns={tableColumns}
        query={fetchUnfulfilled}
        customButton={
          <Box>
            <FormControlLabel
              label={`${showIgnored ? 'Hide' : 'Show'} Ignored`}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={showIgnored}
                  onChange={() => setShowIgnored(prev => !prev)}
                />
              }
            />
          </Box>
        }
      />
    </Box>
  )
}

export default UnfulfilledTab
