import {
  useCompanyTaxPayerEntities,
  useConnectionClasses,
  useConnectionItems,
  useConnectionMapping
} from '@api/queries'
import { FormDialog } from '@common'
import { Autocomplete, Input } from '@common/inputs/form'
import { ConnectionItemLine } from '@models'
import { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import MappingLineSelectorFields from '../MappingLineSelectorFields'

export type MappingLineFormData = Pick<
  ConnectionItemLine,
  'itemId' | 'description' | 'rate' | 'classId' | 'taxPayerEntityId' | 'selector'
>

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  open: boolean
  form: UseFormReturn<MappingLineFormData>
  title: string
  onClose(): void
  onSubmit(data: MappingLineFormData): void
}

const MappingLineFormModal: FC<Props> = ({
  open,
  form,
  title,
  onClose,
  onSubmit
}) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchItems = useConnectionItems(connectionId, open)
  const fetchClasses = useConnectionClasses(connectionId, open)
  const fetchTaxPayerEntities = useCompanyTaxPayerEntities(
    fetchMapping.data?.companyId,
    open && fetchMapping.isSuccess
  )

  const resetParameters = (params: string[]) => {
    form.setValue('selector.params', params)
  }

  return (
    <FormDialog<MappingLineFormData>
      form={form}
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
    >
      <Autocomplete
        label="Item Line"
        name="itemId"
        idKey="itemId"
        optionLabelKey="fullName"
        query={fetchItems}
      />

      <Input label="Description" name="description" required={false} />

      <Input label="Rate" name="rate" type="number" />

      <Autocomplete
        label="Class"
        name="classId"
        idKey="classId"
        optionLabelKey="fullName"
        required={false}
        query={fetchClasses}
      />

      <MappingLineSelectorFields
        name="selector"
        resetParameters={resetParameters}
        tpeQuery={fetchTaxPayerEntities}
      />
    </FormDialog>
  )
}

export default MappingLineFormModal
