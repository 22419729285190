import { AccessCompanyReport, ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload = Pick<AccessCompanyReport, 'name' | 'tags' | 'queries' | 'parameters'>

const useCreateCompanyReport = (): UseMutationResult<
  AccessCompanyReport,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post<AccessCompanyReport>('/access/companies/reports', payload)
        .then(res => res.data),
    {
      mutationKey: 'create access company report',
      onSuccess: res => {
        queryClient.setQueryData<AccessCompanyReport[] | undefined>(
          ['access', 'companies', 'reports'],
          data => data && [...data, res]
        )
        enqueueSnackbar('Report created', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create report', { variant: 'error' })
      }
    }
  )
}
export default useCreateCompanyReport
