import { NavigateNext } from '@mui/icons-material'
import { Breadcrumbs, Typography } from '@mui/material'
import { FC } from 'react'
import Link from './Link'

interface Crumb {
  label: string
  path?: string
}

interface Props {
  crumbs: Crumb[]
}

const Breadcrumb: FC<Props> = ({ crumbs }) => (
  <Breadcrumbs
    sx={{ mb: 1, pt: 1, fontSize: 'h5.fontSize', lineHeight: 1.6 }}
    separator={<NavigateNext fontSize="small" />}
    aria-label="breadcrumb"
  >
    {crumbs.map(({ label, path }) =>
      path ? (
        <Link key={label} label={label} path={path} underline="hover" />
      ) : (
        <Typography variant="h5" key={label}>
          {label}
        </Typography>
      )
    )}
  </Breadcrumbs>
)

export default Breadcrumb
