import { useAccessCompanyReports } from '@api/queries'
import { AccessCompanyReport } from '@models'
import { Box, Typography } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import CompanyReportsTable from './components/CompanyReportsTable'
import CompanyReportDownloadsModal from './components/modals/CompanyReportDownloadsModal'
import CreateCompanyReportModal from './components/modals/CreateCompanyReportModal'
import EditCompanyReportModal from './components/modals/EditCompanyReportModal'

export type Selected =
  | { report: undefined; type: 'none' }
  | { report: undefined; type: 'create' }
  | { report: AccessCompanyReport; type: 'edit' }
  | { report: AccessCompanyReport; type: 'log' }

const CompanyAccessReportsTab: FC = () => {
  const fetchReports = useAccessCompanyReports()

  const [selected, setSelected] = useState<Selected>({
    report: undefined,
    type: 'none'
  })

  const tags = useMemo(() => {
    if (!fetchReports.isSuccess) return []

    const tagsList: string[] = []

    fetchReports.data.forEach(rep => {
      rep.tags.forEach(t => {
        if (!tagsList.includes(t)) tagsList.push(t)
      })
    })

    return tagsList
  }, [fetchReports.data])

  const closeModal = () => setSelected({ report: undefined, type: 'none' })

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        Reports
      </Typography>

      <CompanyReportsTable setSelected={setSelected} />

      <CreateCompanyReportModal
        open={selected.type === 'create'}
        tags={tags}
        onClose={closeModal}
      />

      <EditCompanyReportModal
        open={selected.type === 'edit'}
        report={selected.report}
        tags={tags}
        onClose={closeModal}
      />

      <CompanyReportDownloadsModal
        open={selected.type === 'log'}
        report={selected.report}
        onClose={closeModal}
      />
    </Box>
  )
}

export default CompanyAccessReportsTab
