import { Container } from '@mui/material'
import { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ConnectionMappingItemsPage from './paths/connections/paths/connectionId/paths/mappingId/ConnectionMappingItemsPage'
import QBConnectionPage from './paths/connections/paths/connectionId/paths/root/QBConnectionPage'
import QBConnections from './paths/connections/paths/root/QBConnections'
import QBExports from './paths/exports/QBExports'

const QuickBooks: FC = () => {
  const { path } = useRouteMatch()

  return (
    <Container maxWidth="xl">
      <Switch>
        {/* '/quickbooks/instructions' in root */}

        <Route path="/quickbooks/exports/:connectionId?">
          <QBExports />
        </Route>

        <Route exact path="/quickbooks/connections">
          <QBConnections />
        </Route>

        <Route exact path="/quickbooks/connections/:connectionId">
          <QBConnectionPage />
        </Route>

        <Route
          exact
          path="/quickbooks/connections/:connectionId/mappings/:mappingId"
        >
          <ConnectionMappingItemsPage />
        </Route>

        <Route path={path}>
          <Redirect to="/quickbooks/connections" />
        </Route>
      </Switch>
    </Container>
  )
}

export default QuickBooks
