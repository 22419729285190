import { Select } from '@common/inputs/basic'
import { ConnectionEntityType } from '@models'
import { Refresh } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import { FC } from 'react'
import { useIsMutating } from 'react-query'

export type AutofyType = 'accounts' | 'classes' | 'items' | 'customers'

interface Props {
  entityType?: ConnectionEntityType
  type: AutofyType
  setType(type: AutofyType): void
  refreshData(): void
}

const typeOptions = [
  { label: 'Accounts', value: 'accounts', resellerOnly: false },
  { label: 'Classes', value: 'classes', resellerOnly: false },
  { label: 'Items', value: 'items', resellerOnly: true },
  { label: 'Customers', value: 'customers', resellerOnly: true }
]

const ConnectionPreferencesHeader: FC<Props> = ({
  entityType,
  type,
  setType,
  refreshData
}) => {
  const isMutating = useIsMutating({ mutationKey: 'refresh autofy data' })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="h3" flexGrow={1} gutterBottom>
        Preferences
      </Typography>

      <Select
        label="Types"
        value={type}
        onChange={newType => setType(newType as AutofyType)}
        sx={{ minWidth: 120, mr: 4 }}
        options={typeOptions.filter(o =>
          entityType === ConnectionEntityType.Company ? !o.resellerOnly : true
        )}
      />

      <IconButton
        size="small"
        color="secondary"
        aria-label="refresh"
        title={`Refresh ${type}`}
        onClick={refreshData}
        disabled={isMutating > 0}
      >
        {isMutating > 0 ? (
          <CircularProgress size={28} />
        ) : (
          <Refresh sx={{ fontSize: 28 }} />
        )}
      </IconButton>
    </Box>
  )
}

export default ConnectionPreferencesHeader
