import { Container } from '@mui/material'
import { FC } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import AccessCompany from './paths/companies/paths/companyId/AccessCompany'
import AccessCompanies from './paths/companies/paths/root/AccessCompanies'
import GroupAccess from './paths/groups/paths/groupId/GroupAccess'
import GroupsAccess from './paths/groups/paths/root/GroupsAccess'
import UsersAccess from './paths/users/UsersAccess'

const Access: FC = () => {
  const { path } = useRouteMatch()

  return (
    <Container maxWidth="xl">
      <Switch>
        <Route path="/access/users">
          <UsersAccess />
        </Route>

        <Route exact path="/access/groups">
          <GroupsAccess />
        </Route>

        <Route exact path="/access/groups/:groupId">
          <GroupAccess />
        </Route>

        <Route exact path="/access/companies">
          <AccessCompanies />
        </Route>

        <Route exact path="/access/companies/:companyId">
          <AccessCompany />
        </Route>

        <Route path={path}>
          <Redirect to="/access/users" />
        </Route>
      </Switch>
    </Container>
  )
}

export default Access
