import XLSX from 'xlsx'
import { isValidDate } from './isValidDate'

export const toCsvSheet = (data: Array<any>): XLSX.WorkSheet => {
  const results = data.map(d => {
    const item = { ...d }

    for (const key in d) {
      if (isValidDate(d[key])) item[key] = new Date(d[key])
    }

    return item
  })

  return XLSX.utils.json_to_sheet(results)
}
