import { Checkbox } from '@common/inputs/form'
import { InputProps, Report } from '@models'
import { Divider, FormControl, FormGroup, FormLabel, Stack } from '@mui/material'
import { FC, useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { ReportFormData } from '../modals/ReportFormModal'

interface OptionsProps extends InputProps {
  setValue: UseFormSetValue<ReportFormData>
}

const OptionsChecklist: FC<OptionsProps> = ({
  control,
  getValues,
  setValue,
  watch
}) => {
  if (!watch || !getValues) return null

  const [watchCompanyIdSetting, watchResellerId] = watch([
    'formSettings.companyId',
    'options.select.resellerId'
  ])

  const formValues: Report['options'] = getValues('options')

  useEffect(() => {
    if (!watchCompanyIdSetting) {
      setValue('options.inherit.companyId', false)
      setValue('options.select.companyId', false)
      setValue('options.select.payrollId', false)
    }
  }, [watchCompanyIdSetting])

  useEffect(() => {
    if (!watchResellerId) {
      setValue('options.select.taxPayerEntityId', false)
    }
  }, [watchResellerId])

  return (
    <FormGroup>
      <FormLabel>Options</FormLabel>
      <Stack mx={7} mt={1} divider={<Divider light />}>
        <Checkbox
          label="Date Range"
          name="options.select.dateRange"
          control={control}
        />

        <Checkbox
          label="Quarterly"
          name="options.select.quarterly"
          control={control}
        />

        <Checkbox
          label="Reseller ID"
          name="options.select.resellerId"
          control={control}
        />

        <Checkbox
          label="TPE ID"
          name="options.select.taxPayerEntityId"
          control={control}
          isDisabled={!formValues.select.resellerId}
        />

        {/* Company ID */}
        <FormGroup
          row
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Checkbox
            label="Company ID"
            name="formSettings.companyId"
            control={control}
          />

          <FormControl component="fieldset">
            <FormGroup row>
              <Checkbox
                label="Select"
                name="options.select.companyId"
                small
                control={control}
                isDisabled={!watchCompanyIdSetting}
              />

              <Checkbox
                label="Inherit"
                name="options.inherit.companyId"
                small
                control={control}
                isDisabled={!watchCompanyIdSetting}
              />
            </FormGroup>
          </FormControl>
        </FormGroup>

        <Checkbox
          label="Payroll ID"
          name="options.select.payrollId"
          control={control}
          isDisabled={!watchCompanyIdSetting}
        />
      </Stack>
    </FormGroup>
  )
}

export default OptionsChecklist
