import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult } from 'react-query'

type Payload = { endpointId: string } | void

const useSendAutofyConnection = (
  token: string,
  type: 'qbo' | 'qbd'
): UseMutationResult<void, ApiError, Payload> => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios
        .post(`/quickbooks/connections/invites/${type}/${token}`, payload)
        .then(res => res.data),
    {
      mutationKey: 'resolve connection invite',
      onSuccess: () => {
        enqueueSnackbar('Successfully connected', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to connect', {
          variant: 'error',
          preventDuplicate: true
        })
      }
    }
  )
}

export default useSendAutofyConnection
