import { InputProps } from '@models'
import { Check, FileUpload } from '@mui/icons-material'
import { Button } from '@mui/material'
import { ChangeEventHandler, FC } from 'react'
import { useController } from 'react-hook-form'

interface Props extends InputProps {
  fullWidth?: boolean
}

const FileUploader: FC<Props> = ({
  control,
  name,
  defaultValue = '',
  shouldUnregister = false
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    shouldUnregister,
    rules: { required: 'Field required' }
  })

  const id = `${name}-uploader`

  const readQuery: ChangeEventHandler<HTMLInputElement> = async event => {
    const file = event.target?.files && event.target.files[0]
    if (file) {
      const text = await new Response(file).text()
      field.onChange(text)
    }
  }

  return (
    <label htmlFor={id}>
      <input hidden type="file" id={id} accept=".sql" onChange={readQuery} />
      <Button
        component="span"
        variant="outlined"
        color={fieldState.error ? 'error' : field.value ? 'success' : 'primary'}
        endIcon={field.value ? <Check /> : <FileUpload />}
      >
        Upload
      </Button>
    </label>
  )
}

export default FileUploader
