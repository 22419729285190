import { useSnackbar } from 'notistack'
import { FC, ReactNode } from 'react'
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider
} from 'react-query'

const QueryClientProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60,
        refetchOnWindowFocus: false,
        retry: 2,
        onError: () =>
          enqueueSnackbar('Failed to fetch data', {
            variant: 'error',
            preventDuplicate: true
          })
      }
    }
  })

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  )
}
export default QueryClientProvider
