import { ApiError } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

interface ConnectionSelector {
  type: string
  label: string
  params: Array<{
    label: string
    value: string
  }>
}

const useConnectionMappingSelectorParams = (
  connectionId: string | undefined,
  mappingId: string | undefined
): UseQueryResult<ConnectionSelector[], ApiError> =>
  useQuery(
    ['quickbooks', 'connections', connectionId, 'mappings', mappingId, 'selectors'],
    () =>
      axios
        .get<ConnectionSelector[]>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}/selectors`
        )
        .then(res => res.data),
    {
      enabled: Boolean(connectionId && mappingId),
      select: data => data?.filter(d => d.params.length)
    }
  )

export default useConnectionMappingSelectorParams
