import { Modal } from '@common'
import { AccessCompanyReport } from '@models'
import { Box, Typography } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { FC } from 'react'

interface Props {
  open: boolean
  report?: AccessCompanyReport
  onClose(): void
}

const columns: GridColumns = [
  { field: 'userName', headerName: 'User', flex: 1 },
  { field: 'companyName', headerName: 'Company', flex: 1.5 },
  {
    field: 'download',
    headerName: 'Date',
    flex: 1,
    type: 'dateTime',
    valueGetter: ({ value }) => new Date(value)
  }
]

const CompanyReportDownloadsModal: FC<Props> = ({ open, report, onClose }) => (
  <Modal
    title={
      <Box display="flex" alignItems="center">
        <Typography variant="h2" flexGrow={1}>
          Downloads
        </Typography>
        <Typography variant="subtitle1" fontStyle="oblique">
          {report?.name}
        </Typography>
      </Box>
    }
    open={open}
    onClose={onClose}
    width={800}
  >
    <Box sx={{ height: 550, width: '100%' }}>
      <DataGrid
        columns={columns}
        density="compact"
        disableColumnSelector
        disableSelectionOnClick
        rows={report?.downloads.map((d, id) => ({ ...d, id })) || []}
        pagination
      />
    </Box>
  </Modal>
)

export default CompanyReportDownloadsModal
