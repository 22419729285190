import {
  useCreateConnectionMappingLine,
  useDeleteConnectionMappingLine
} from '@api/mutations'
import {
  useConnectionItems,
  useConnectionMapping,
  useConnectionMappingSelectorParams
} from '@api/queries'
import { DataTable } from '@common/table'
import { ConnectionItemLine } from '@models'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'
import { Button } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { confirmFirst } from '@utils'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props {
  selectLine(line: ConnectionItemLine): void
  openCreateModal(): void
  openTestModal(): void
}

const MappingLinesTable: FC<Props> = ({
  selectLine,
  openCreateModal,
  openTestModal
}) => {
  const { connectionId, mappingId } = useParams<UrlProps>()

  const fetchMapping = useConnectionMapping(connectionId, mappingId)
  const fetchItems = useConnectionItems(connectionId)
  const fetchSelectors = useConnectionMappingSelectorParams(connectionId, mappingId)

  const createLineMut = useCreateConnectionMappingLine(connectionId, mappingId)
  const deleteLineMut = useDeleteConnectionMappingLine(connectionId, mappingId)

  const onDelete = (lineId: string) =>
    confirmFirst('Would you like to delete this line?', () =>
      deleteLineMut.mutate({ lineId: String(lineId) })
    )

  const onDuplicate = (line: ConnectionItemLine) =>
    confirmFirst(`Would you like to duplicate line ${line.itemName}?`, () => {
      createLineMut.mutate({
        itemId: line.itemId,
        itemName: `${line.itemName} (Copy)`,
        description: line.description,
        rate: line.rate,
        classId: line.classId,
        className: line.className,
        taxPayerEntityId: line.taxPayerEntityId,
        taxPayerEntityName: line.taxPayerEntityName,
        selector: line.selector
      })
    })

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'itemName',
        headerName: 'Item',
        flex: 1.5
      },
      {
        field: 'selectorType',
        headerName: 'Selector',
        flex: 1,
        type: 'singleSelect',
        valueOptions: fetchSelectors.data?.map(s => s.label),
        valueGetter: ({ row }) =>
          fetchSelectors.isSuccess
            ? fetchSelectors.data?.find(s => s.type === row.selector.type)?.label ??
              'N/A'
            : ''
      },
      { field: 'className', headerName: 'Class', flex: 1 },
      { field: 'taxPayerEntityName', headerName: 'TPE', flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={`edit-${row._id}`}
            icon={<Edit />}
            label="Edit"
            onClick={() => selectLine(row as ConnectionItemLine)}
            showInMenu
          />,
          <GridActionsCellItem
            key={`duplicate-${row._id}`}
            icon={<ContentCopy />}
            label="Duplicate"
            onClick={() => onDuplicate(row as ConnectionItemLine)}
            showInMenu
          />,
          <GridActionsCellItem
            key={`delete-${row._id}`}
            icon={<Delete />}
            label="Delete"
            onClick={() => onDelete(row._id ?? '')}
            showInMenu
          />
        ]
      }
    ],
    [fetchItems.data, fetchSelectors.data, fetchMapping.data]
  )

  return (
    <DataTable
      columns={columns}
      errorMessage="Failed to fetch mapping"
      height={625}
      idKey="_id"
      query={fetchMapping}
      addNewRow={openCreateModal}
      getRows={data => data.lines}
      customButton={
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ ml: 2 }}
          onClick={() => openTestModal()}
        >
          Test
        </Button>
      }
    />
  )
}

export default MappingLinesTable
