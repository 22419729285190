import { useCurrentUser } from '@api/queries'
import {
  AccountBalance,
  Assignment,
  BarChart,
  Cable,
  ExpandLess,
  ExpandMore,
  Group,
  Lock,
  Outbox,
  PermMedia,
  Person,
  Store
} from '@mui/icons-material'
import {
  Collapse,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { FC, ReactElement, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SidebarProps } from '../Sidebar'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`
})

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ['& .MuiListItemIcon-root']: {
      color: 'inherit'
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

interface SidebarItemProps {
  divider?: boolean
  hide?: boolean
  inset?: boolean
  name: string
  path: string
  icon: ReactElement
}
const SidebarItem: FC<SidebarItemProps> = ({
  hide,
  inset,
  name,
  path,
  icon: Icon
}) => {
  const { pathname } = useLocation()
  if (hide) return null

  const active = pathname.includes(path)

  return (
    <ListItem
      button
      title={name}
      selected={active}
      component={Link}
      to={path}
      sx={{ pl: inset ? 4 : 2, color: active ? 'common.white' : 'block' }}
    >
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  )
}

const DesktopSidebar: FC<SidebarProps> = ({ open, setOpen }) => {
  const { pathname } = useLocation()

  const { isSuccess, data: user } = useCurrentUser()

  const [accessOpen, setAccessOpen] = useState(false)
  const [quickbooksOpen, setQuickbooksOpen] = useState(false)

  if (!isSuccess || !user) return null

  const selectParentItem = (item: 'access' | 'quickbooks') => {
    if (!open) {
      setOpen(true)
      item === 'access' ? setAccessOpen(true) : setQuickbooksOpen(true)
    } else {
      item === 'access'
        ? setAccessOpen(!accessOpen)
        : setQuickbooksOpen(!quickbooksOpen)
    }
  }

  return (
    <Drawer variant={'permanent'} open={open}>
      <DrawerHeader />

      <Divider />

      <List
        component="nav"
        sx={{
          ['& .MuiListSubheader-root']: {
            bgcolor: 'inherit'
          }
        }}
      >
        <SidebarItem
          name="Reports"
          path={user.isCompany ? '/company/reports' : '/reports'}
          icon={<PermMedia />}
        />

        <SidebarItem
          name="Dashboards"
          path="/dashboards"
          icon={<BarChart />}
          hide={!user.isAdmin}
        />

        <SidebarItem
          name="Fulfillments"
          path="/fulfillments"
          icon={<Assignment />}
          hide={!user.rights.fulfillment && !user.isAdmin}
        />

        {/* QuickBooks */}
        {(user.isAdmin || user.rights.qbConnectionManagement) && (
          <>
            <ListItem
              button
              selected={
                (quickbooksOpen && !pathname.includes('/quickbooks')) ||
                (!quickbooksOpen && pathname.includes('/quickbooks'))
              }
              onClick={() => selectParentItem('quickbooks')}
            >
              <ListItemIcon>
                <AccountBalance />
              </ListItemIcon>
              <ListItemText>QuickBooks</ListItemText>

              {quickbooksOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={quickbooksOpen && open}>
              <List disablePadding>
                <SidebarItem
                  name="Connections"
                  path="/quickbooks/connections"
                  icon={<Cable />}
                  inset
                />

                <SidebarItem
                  name="Exports"
                  path="/quickbooks/exports"
                  icon={<Outbox />}
                  inset
                />
              </List>
            </Collapse>
          </>
        )}

        {/* Access */}
        {user.isAdmin && (
          <>
            <ListItem
              button
              selected={
                (accessOpen && !pathname.includes('/access')) ||
                (!accessOpen && pathname.includes('/access'))
              }
              onClick={() => selectParentItem('access')}
              // onClick={onAccessClick}
            >
              <ListItemIcon>
                <Lock />
              </ListItemIcon>
              <ListItemText>Access</ListItemText>

              {accessOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={accessOpen && open}>
              <List disablePadding>
                <SidebarItem
                  name="Users"
                  path="/access/users"
                  icon={<Person />}
                  inset
                />

                <SidebarItem
                  name="Groups"
                  path="/access/groups"
                  icon={<Group />}
                  inset
                />

                <SidebarItem
                  name="Companies"
                  path="/access/companies"
                  icon={<Store />}
                  inset
                />
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Drawer>
  )
}

export default DesktopSidebar
