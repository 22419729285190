import { Typography } from '@mui/material'
import { FC } from 'react'

interface Props {
  message?: string | false
  center?: boolean
  gutter?: boolean
}

const ErrorMessage: FC<Props> = ({
  message = '',
  center = false,
  gutter = false
}) => (
  <Typography
    color="error"
    gutterBottom={gutter}
    align={center ? 'center' : 'inherit'}
    height={24}
  >
    {message}
  </Typography>
)

export default ErrorMessage
