import { useCreateCompanyUser } from '@api/mutations'
import { FormDialog } from '@common'
import { Input } from '@common/inputs/form'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

interface CompanyUserForm {
  name: string
  email: string
}

interface Props {
  open: boolean
  onClose(): void
}

const CreateCompanyUserModal: FC<Props> = ({ open, onClose }) => {
  const { companyId } = useParams<{ companyId: string }>()

  const createCompanyUserForm = useForm<CompanyUserForm>({
    defaultValues: { name: '', email: '' }
  })

  const createCompanyUserMut = useCreateCompanyUser(companyId)

  return (
    <FormDialog
      form={createCompanyUserForm}
      open={open}
      title="Create User"
      onClose={onClose}
      onSubmit={data => createCompanyUserMut.mutate(data, { onSuccess: onClose })}
    >
      <Input name="name" />
      <Input name="email" />
    </FormDialog>
  )
}

export default CreateCompanyUserModal
