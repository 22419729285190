import { Breadcrumb, TabsPanel } from '@common'
import { FC } from 'react'
import CompaniesAccessCompaniesTab from './tabs/companies/CompaniesAccessCompaniesTab'
import CompaniesAccessReportsTab from './tabs/reports/CompaniesAccessReportsTab'

const breadcrumbs = [{ label: 'Access', path: '/access' }, { label: 'Companies' }]

const AccessCompanies: FC = () => (
  <>
    <Breadcrumb crumbs={breadcrumbs} />

    <TabsPanel
      tabs={[
        { name: 'companies', component: CompaniesAccessCompaniesTab },
        { name: 'reports', component: CompaniesAccessReportsTab }
      ]}
    />
  </>
)

export default AccessCompanies
