import { useUpdateFulfillment } from '@api/mutations'
import { useFulfillments } from '@api/queries'
import { DateRangeType, Fulfillment, FulfillmentStatus } from '@models'
import { Box } from '@mui/material'
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { FC, useCallback, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import EditFulfillmentModal from 'routes/fulfillments/components/EditFulfillmentModal'
import FulfillmentsTable from 'routes/fulfillments/components/FulfillmentsTable'

type SelectedFulfillment = {
  fulfillment: Fulfillment
  type: 'edit-note'
}

interface Props {
  dates: DateRangeType
}

const MailedTab: FC<Props> = ({ dates }) => {
  const fetchMailed = useFulfillments(dates, data =>
    data.filter(d => d.status === FulfillmentStatus.MAILED)
  )
  const updateFulfillmentMut = useUpdateFulfillment()

  const [selected, setSelected] = useState<SelectedFulfillment>()

  const tableColumns = useMemo<GridColumns>(
    () => [
      { field: 'companyName', headerName: 'Company', flex: 1 },
      { field: 'companyPayrollId', headerName: 'Payroll', flex: 0.5 },
      {
        field: 'checkDate',
        headerName: 'Check Date',
        flex: 0.5,
        type: 'date',
        valueGetter: ({ value }) => new Date(value)
      },
      {
        field: 'note',
        headerName: 'Note',
        flex: 0.5
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: ({ id, row }) => {
          const fulfillment = row as Fulfillment
          return [
            <GridActionsCellItem
              key={`edit-note-action-${id}`}
              label="Edit note"
              onClick={() => setSelected({ fulfillment, type: 'edit-note' })}
              showInMenu
            />,
            <GridActionsCellItem
              key={`unfulfilled-action-${id}`}
              label="Add to Unfulfilled"
              onClick={() => addToUnfulfilled(fulfillment)}
              showInMenu
            />
          ]
        }
      }
    ],
    [fetchMailed]
  )

  const addToUnfulfilled = useCallback(
    (fulfillment: Fulfillment) => {
      updateFulfillmentMut.mutate({
        id: fulfillment.id,
        data: {
          status: FulfillmentStatus.UNFULFILLED,
          note: ''
        }
      })
    },
    [fetchMailed]
  )

  const onEditFulfillmentSubmit: SubmitHandler<{ note: string }> = ({ note }) => {
    if (selected?.type === 'edit-note')
      updateFulfillmentMut.mutate(
        {
          id: selected.fulfillment.id,
          data: {
            status: selected.fulfillment.status,
            note
          }
        },
        {
          onSuccess: () => {
            setSelected(undefined)
          }
        }
      )
  }
  return (
    <Box>
      <EditFulfillmentModal
        fulfillment={selected?.fulfillment}
        title="Edit Note"
        onSubmit={onEditFulfillmentSubmit}
        onClose={() => setSelected(undefined)}
      />

      <FulfillmentsTable columns={tableColumns} query={fetchMailed} />
    </Box>
  )
}

export default MailedTab
