import { useConnectionMappingSelectorParams } from '@api/queries'
import { Autocomplete, Select } from '@common/inputs/form'
import { ApiError, InputProps } from '@models'
import { Box, Collapse, FormGroup, FormLabel } from '@mui/material'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import MappingLineSelectorParams from './MappingLineSelectorParams'
import { UseQueryResult } from 'react-query'

interface UrlProps {
  connectionId: string
  mappingId: string
}

interface Props extends InputProps {
  resetParameters(params: string[]): void
  tpeQuery: UseQueryResult<any[], ApiError>
}

const MappingLineSelectorFields: FC<Props> = ({
  tpeQuery,
  control,
  watch,
  resetParameters,
  getValues
}) => {
  const { connectionId, mappingId } = useParams<UrlProps>()
  const watchSelectorType = watch && watch('selector.type')

  const fetchSelectors = useConnectionMappingSelectorParams(connectionId, mappingId)

  const selector = fetchSelectors.data?.find(s => s.type === watchSelectorType)

  useEffect(() => {
    if (getValues && fetchSelectors.isSuccess) {
      const params: string[] = getValues('selector.params') ?? []
      const allowed = selector?.params.map(p => p.value.toString()) ?? []
      resetParameters(params.filter(p => allowed.includes(p)))
    }
  }, [watchSelectorType])

  return (
    <FormGroup>
      <FormLabel sx={{ my: 2 }} component="h5">
        Selectors
      </FormLabel>

      <Box px={4}>
        <Autocomplete
          label="Tax Payer Entity"
          name="taxPayerEntityId"
          idKey="taxPayerEntityId"
          optionLabelKey="name"
          required={false}
          query={tpeQuery}
          control={control}
        />
        <Select
          label="Selector Type"
          name="selector.type"
          options={
            fetchSelectors.data?.map(s => ({ label: s.label, value: s.type })) ?? []
          }
          control={control}
        />

        <Collapse
          in={!!watchSelectorType && !!fetchSelectors.isSuccess}
          timeout="auto"
        >
          <MappingLineSelectorParams
            name="selector.params"
            parameters={selector?.params ?? []}
            control={control}
          />
        </Collapse>
      </Box>
    </FormGroup>
  )
}

export default MappingLineSelectorFields
