import { ApiError, DateRangeType, Fulfillment } from '@models'
import { formatDate } from '@utils'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useFulfillments = (
  dates: DateRangeType,
  select?: (data: Fulfillment[]) => Fulfillment[]
): UseQueryResult<Fulfillment[], ApiError> => {
  const startDate = formatDate(dates.startDate)
  const endDate = formatDate(dates.endDate)

  return useQuery(
    ['fulfillments', startDate, endDate],
    () =>
      axios
        .get<Fulfillment[]>(
          `/fulfillments?startDate=${startDate}&endDate=${endDate}`
        )
        .then(res => res.data),
    {
      select //: data => (status ? data.filter(d => d.status === status) : data)
    }
  )
}
export default useFulfillments
