import { ApiError, ConnectionLine } from '@models'
import axios from 'axios'
import { useQuery, UseQueryResult } from 'react-query'

const useConnectionLines = (
  connectionId: string | undefined
): UseQueryResult<ConnectionLine[], ApiError> =>
  useQuery(
    ['quickbooks', 'connections', connectionId, 'lines'],
    () =>
      axios
        .get<ConnectionLine[]>(`/quickbooks/connections/${connectionId}/lines`)
        .then(res => res.data),
    {
      enabled: !!connectionId,
      select: lines => lines.map(line => new ConnectionLine(line))
    }
  )

export default useConnectionLines
