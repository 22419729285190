import { ApiError, ConnectionMapping, QBConnection } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type Payload =
  | {
      classId: string
      className: string
    }
  | {
      customerId: string
      customerName: string
    }

const useUpdateConnectionMappingIds = (
  connectionId: string,
  mappingId: string
): UseMutationResult<ConnectionMapping, ApiError, Payload> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async data =>
      axios
        .patch<ConnectionMapping>(
          `/quickbooks/connections/${connectionId}/mappings/${mappingId}`,
          data
        )
        .then(res => res.data),
    {
      mutationKey: 'update connection mapping ids',
      onSuccess: () => {
        queryClient.invalidateQueries([
          'quickbooks',
          'connections',
          connectionId,
          'mappings',
          mappingId
        ])
        enqueueSnackbar('Connection updated', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to update connection', { variant: 'error' })
      }
    }
  )
}

export default useUpdateConnectionMappingIds
