import { InputProps } from '@models'
import { Autocomplete as MatAutocomplete, Chip, TextField } from '@mui/material'
import { FC } from 'react'
import { useController } from 'react-hook-form'

interface MultiselectProps extends InputProps {
  label: string
  options?: string[]
  freeSolo?: boolean
}

const Multiselect: FC<MultiselectProps> = ({
  control,
  defaultValue = [],
  label,
  name,
  options = [],
  freeSolo = false,
  variant
}) => {
  const {
    field: { ref, ...field },
    fieldState
  } = useController<any>({
    control: control,
    name,
    defaultValue,
    rules: { required: 'Field required' }
  })

  return (
    <MatAutocomplete
      data-testid={`${name}-multiselect`}
      multiple
      freeSolo={freeSolo}
      limitTags={4}
      autoComplete
      fullWidth
      options={options}
      noOptionsText={null}
      value={field.value || []}
      onChange={(e, newValue: string[]) => {
        const value = newValue.map(v => v.trim())
        field.onChange(value.filter((v, i) => value.indexOf(v) === i))
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...props } = getTagProps({ index })
          return (
            <Chip
              key={key}
              variant="outlined"
              color="primary"
              label={option}
              size="small"
              {...props}
            />
          )
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message || ' '}
          inputRef={ref}
          variant={variant}
        />
      )}
    />
  )
}

export default Multiselect
