import { AccessCompany, ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

interface Payload {
  resellerId: string
  resellerName: string
  companyId: string
  companyName: string
}

const useCreateAccessCompany = (): UseMutationResult<
  AccessCompany,
  ApiError,
  Payload
> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async payload =>
      axios.post<AccessCompany>('/access/companies', payload).then(res => res.data),
    {
      mutationKey: 'create access company',
      onSuccess: res => {
        queryClient.setQueryData<AccessCompany[] | undefined>(
          ['access', 'companies'],
          data => data && [...data, res]
        )
        queryClient.setQueryData<AccessCompany>(['access', 'companies', res.id], res)
        enqueueSnackbar(`Company created`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Failed to create company', { variant: 'error' })
      }
    }
  )
}
export default useCreateAccessCompany
