import { ApiError } from '@models'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'

type AutofyDataTypes = 'accounts' | 'classes' | 'items' | 'customers'

const useRefreshAutofyData = (
  connectionId: string,
  dataType?: AutofyDataTypes
): UseMutationResult<any[], ApiError, void> => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async () =>
      axios
        .get(`/quickbooks/connections/${connectionId}/${dataType}?refresh=true`)
        .then(res => res.data),
    {
      mutationKey: 'refresh autofy data',
      onSuccess: res => {
        queryClient.setQueryData(
          ['quickbooks', 'connections', connectionId, dataType],
          res
        )
        enqueueSnackbar(`Updated ${dataType}`, { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar(`Failed to refresh ${dataType}`, { variant: 'error' })
      }
    }
  )
}

export default useRefreshAutofyData
