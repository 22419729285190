import { InputProps } from '@models'
import {
  Autocomplete as MatAutocomplete,
  AutocompleteProps as MatAutocompleteProps,
  TextField
} from '@mui/material'
import { FC } from 'react'
import { UseQueryResult } from 'react-query'

interface Data {
  id?: string
  [key: string]: any
}

interface AutocompleteProps extends InputProps {
  value: string
  query: UseQueryResult<Data[]>
  label: string
  idKey?: string
  optionLabelKey?: string
  sort?: { key: string; direction: 'asc' | 'desc' }
  fullWidth?: boolean
  noMargin?: boolean
  disableClearable?: boolean
  error?: boolean
  getOptionLabel?: MatAutocompleteProps<Data, false, false, false>['getOptionLabel']
  renderOption?: MatAutocompleteProps<Data, false, false, false>['renderOption']
  onChange(val: string): void
}

const Autocomplete: FC<AutocompleteProps> = ({
  value,
  label,
  name,
  idKey = 'id',
  optionLabelKey = 'id',
  query,
  sort = { key: '', direction: 'asc' },
  fullWidth = true,
  noMargin = false,
  disableClearable,
  size,
  variant,
  sx,
  autoFocus,
  error,
  getOptionLabel,
  renderOption,
  onChange
}) => {
  const errorMessage = query.isError && 'Failed to fetch options'

  const sortOptions = (a: Data, b: Data) => {
    const key = sort.key || optionLabelKey

    return sort.direction === 'asc'
      ? a[key] > b[key]
        ? 1
        : -1
      : a[key] > b[key]
      ? -1
      : 1
  }

  return (
    <MatAutocomplete
      sx={sx}
      id={name}
      openOnFocus
      autoComplete
      autoHighlight
      fullWidth={fullWidth}
      loading={query.isLoading}
      disabled={!query.isSuccess || query.isLoading}
      options={query.data?.sort(sortOptions) || []}
      getOptionLabel={getOptionLabel || (option => option[optionLabelKey])}
      isOptionEqualToValue={(option, value) =>
        option[idKey].toString() === value[idKey].toString()
      }
      onChange={(e, option) => onChange(option ? option[idKey] : '')}
      size={size}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          label={label}
          error={!!errorMessage || error}
          helperText={errorMessage}
          style={{ marginBottom: errorMessage || noMargin ? 0 : 22 }}
          variant={variant}
        />
      )}
      renderOption={renderOption}
      disableClearable={disableClearable}
      value={query.data?.find(opt => opt[idKey] === value) || null}
    />
  )
}

export default Autocomplete
