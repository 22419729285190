interface UserSettings {
  favoriteReportIds: string[]
}

interface UserRights {
  reportIds: string[]
  payrollSignOut: boolean
  qbConnectionManagement: boolean
  fulfillment: boolean
  delivery: boolean
  googleCalender: boolean
}

enum UserType {
  Admin,
  Base,
  Company
}

enum UserStatus {
  Inactive,
  Active
}

export default class User {
  public id: string
  public companyId: string
  public email: string
  public name: string
  public settings: UserSettings
  public status: UserStatus
  public type: UserType

  public rights: UserRights

  constructor(user: Partial<User>) {
    this.id = user.id || ''
    this.companyId = user.companyId || ''
    this.email = user.email || ''
    this.name = user.name || ''
    this.settings = user.settings || {
      favoriteReportIds: []
    }
    this.status = user.status || UserStatus.Active
    this.type = typeof user.type === 'number' ? user.type : UserType.Base
    this.rights = user.rights || {
      reportIds: [],
      payrollSignOut: false,
      qbConnectionManagement: false,
      fulfillment: false,
      delivery: false,
      googleCalender: false
    }
  }

  get isAdmin(): boolean {
    return this.type === UserType.Admin
  }

  get isBase(): boolean {
    return this.type === UserType.Base
  }

  get isCompany(): boolean {
    return this.type === UserType.Company
  }
}
