import { useCreateCompanyReport } from '@api/mutations'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import CompanyReportModal, { CompanyReportFormData } from './CompanyReportModal'

interface Props {
  open: boolean
  tags: string[]
  onClose(): void
}

const CreateCompanyReportModal: FC<Props> = ({ open, tags, onClose }) => {
  const createCompanyReportForm = useForm<CompanyReportFormData>({
    defaultValues: new CompanyReportFormData()
  })

  const createCompanyMut = useCreateCompanyReport()

  const onSubmit: SubmitHandler<CompanyReportFormData> = data => {
    createCompanyMut.mutate(data, {
      onSuccess: onClose
    })
  }
  return (
    <CompanyReportModal
      open={open}
      form={createCompanyReportForm}
      tags={tags}
      title="Create Report"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  )
}

export default CreateCompanyReportModal
